import './App.css';
import {HashRouter} from "react-router-dom";
import ErrorBoundary from "@views/Common/ErrorPage/ErrorBoundary";
import Header from "@components/Header/Header";
import React from "react";
import {Container} from "react-bootstrap";
import Footer from "@components/Footer/Footer";
import RouterSwitch from "@views/Common/RouterSwitch/RouterSwitch";

function App() {
    return (
        <Container>
            <ErrorBoundary>
                <HashRouter>
                    <MainSkeleton/>
                </HashRouter>
            </ErrorBoundary>
        </Container>
    );
}

const MainSkeleton = () => (
    <>
        <Header/>
        <RouterSwitch/>
        <Footer/>
    </>
);

export default App;
