import {Card} from "react-bootstrap";
import {formatDateTimeWithSeconds} from "@utils/date-time.utils";
import {useTranslation} from "react-i18next";
import {DeleteButton} from "@components/Button";
import {noop} from "lodash";
import React from "react";
import Markdown from "@components/Markdown/Markdown";

const avatarStyle = {
    width: 45,
    height: 45,
    marginRight: 10
};

function Comment({comment, onDeleteClicked = noop}) {
    const {t} = useTranslation();
    const {authorUser, developer, content, timestamp, canBeDeleted} = comment;
    const {avatar, name: fullName = ''} = authorUser;

    return (
        <Card className="rounded-0 mb-2">
            <Card.Body className="p-2">
                <div className="d-flex">
                    <div className="flex-shrink-1">
                        <img alt="avatar" src={avatar} style={avatarStyle}/>
                    </div>
                    <div className="flex-grow-1">
                        <h5 className="mb-0">{fullName}</h5>
                        <small className="text-secondary">{formatDateTimeWithSeconds(timestamp)}</small>
                    </div>
                </div>

                <div className="d-flex">
                    <div className="flex-grow-1">
                        <div className="pt-2">
                            <Markdown>{content}</Markdown>
                        </div>
                        {!!developer && (
                            <div className="mt-1">
                                <b className="text-danger">{t('label.developer')}:</b>
                                {developer.name}
                            </div>
                        )}
                    </div>
                    <div className="flex-shrink-1 align-self-end">
                        {!!canBeDeleted && (
                            <DeleteButton title="tooltip.deleteComment"
                                          onClick={() => onDeleteClicked(comment)}/>
                        )}
                    </div>
                </div>
            </Card.Body>
        </Card>
    );
}

export default Comment;
