import useUniqueId from "@/hooks/useUniqueId";
import {get} from "lodash";
import {FormInvalidFeedback} from "@components/Form";

function InputTextFormGroup({
                                register,
                                label,
                                errors,
                                fieldName,
                                placeholder = '',
                                description = '',
                                type = 'text',
                                readOnly = false
                            }) {
    const componentId = useUniqueId();
    const error = get(errors, fieldName, null);

    return (
        <div className="form-group mb-3">
            <label htmlFor={componentId}>{label}</label>
            <input
                type={type}
                id={componentId}
                readOnly={readOnly}
                {...register(fieldName)}
                placeholder={placeholder}
                className={`form-control ${error ? 'is-invalid' : ''}`}
            />
            {!!description && (
                <small className="text-muted">{description}</small>
            )}
            <FormInvalidFeedback errors={error?.message}/>
        </div>
    );
}

export default InputTextFormGroup;
