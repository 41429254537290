import secureRoute from "@components/HOC/secureRoute";
import Breadcrumb from "@components/Breadcrumb/Breadcrumb";
import {Alert, Card, ListGroup, ListGroupItem} from "react-bootstrap";
import resolveData from "@components/HOC/resolveData";
import developerService from "@services/developer.service";
import {DeleteButton} from "@components/Button";
import React, {useState} from "react";
import AddDeveloperAliasForm from "@views/DeveloperAliases/AddDeveloperAliasForm/AddDeveloperAliasForm";
import {useTranslation} from "react-i18next";

function DeveloperAliases(props) {
    const {t} = useTranslation();
    const {allAliases: resolvedAliases} = props;
    const [aliasesWithDevelopers, setAliasesWithDevelopers] = useState(resolvedAliases);

    const deleteDeveloperAlias = (aliasId) => {
        developerService.deleteDeveloperAlias(aliasId)
            .then(() => reload());
    };

    const reload = () => {
        return developerService.getAllDeveloperAliases()
            .then(allAliases => setAliasesWithDevelopers(allAliases));
    };

    return (
        <>
            <Breadcrumb title="title.developerAliases"/>

            <Card className="mb-3">
                <ListGroup variant="flush">
                    {aliasesWithDevelopers.developers.map((aliasWithDeveloper, index) => (
                        <ListGroupItem key={index}>
                            <h3>{aliasWithDeveloper.developer.fullName}</h3>
                            <ListGroup>
                                {aliasWithDeveloper.aliases.map((alias, index) => (
                                    <ListGroup.Item key={index}>
                                        <DeleteButton size="sm" title="tooltip.deleteDeveloperAlias"
                                                      onClick={() => deleteDeveloperAlias(alias.id)}/>
                                        <span className="ms-2">{alias.alias}</span>
                                    </ListGroup.Item>
                                ))}
                            </ListGroup>
                        </ListGroupItem>
                    ))}

                    {!aliasesWithDevelopers.developers.length && (
                        <ListGroupItem>
                            <Alert variant="warning" className="mb-0">{t('msg.noDeveloperAliases')}</Alert>
                        </ListGroupItem>
                    )}
                </ListGroup>
            </Card>

            <AddDeveloperAliasForm onDeveloperAliasAdded={() => reload()}/>
        </>
    );
}

export default secureRoute(resolveData(DeveloperAliases, () => {
    return {
        allAliases: developerService.getAllDeveloperAliases()
    };
}));
