import secureRoute from "@components/HOC/secureRoute";
import React, {useState} from "react";
import Breadcrumb from "@components/Breadcrumb/Breadcrumb";
import BonusCalculatorForm from "@views/Bonus/CalculateBonus/BonusCalculatorForm";
import BonusResult from "@views/Bonus/CalculateBonus/BonusResult";

function CalculateBonus() {
    const [results, setResults] = useState(null);

    return (
        <>
            <Breadcrumb title="title.bonusCalculationHelper"/>

            {results && (<BonusResult bonusResult={results} onClear={() => setResults(null)}/>)}

            {!results && (
                <BonusCalculatorForm setBonusResult={(results) => setResults(results)}/>
            )}
        </>
    );
}

export default secureRoute(CalculateBonus);
