import {Pagination as BsPagination} from "react-bootstrap";
import {useMemo} from "react";
import {noop} from "lodash";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

// Adopted from this blog post:
// https://jasonwatmore.com/post/2018/08/07/javascript-pure-pagination-logic-in-vanilla-js-typescript
function usePager(currentPage, pageSize, totalItems, maxPages) {
    return useMemo(() => {
        // calculate total pages
        const totalPages = Math.ceil(totalItems / pageSize);
        let currentPageCorrected = currentPage;

        // ensure current page isn't out of range
        if (currentPageCorrected < 0) {
            currentPageCorrected = 0;
        } else if (currentPageCorrected >= totalPages) {
            currentPageCorrected = totalPages - 1;
        }

        let startPage, endPage;
        if (totalPages <= maxPages) {
            // total pages less than max so show all pages
            startPage = 0;
            endPage = totalPages - 1;
        } else {
            // total pages more than max so calculate start and end pages
            let maxPagesBeforeCurrentPage = Math.floor(maxPages / 2);
            let maxPagesAfterCurrentPage = Math.ceil(maxPages / 2) - 1;
            if (currentPageCorrected < maxPagesBeforeCurrentPage) {
                // current page near the start
                startPage = 0;
                endPage = maxPages - 1;
            } else if (currentPageCorrected + maxPagesAfterCurrentPage >= totalPages - 1) {
                // current page near the end
                startPage = totalPages - maxPages;
                endPage = totalPages - 1;
            } else {
                // current page somewhere in the middle
                startPage = currentPageCorrected - maxPagesBeforeCurrentPage;
                endPage = currentPageCorrected + maxPagesAfterCurrentPage;
            }
        }

        // calculate start and end item indexes
        let startIndex = currentPageCorrected * pageSize + 1;
        let endIndex = Math.min(startIndex + pageSize - 1, totalItems);

        // return object with all pager properties required by the view
        return {
            totalItems: totalItems,
            currentPage: currentPageCorrected,
            pageSize: pageSize,
            totalPages: totalPages,
            startPage: startPage,
            endPage: endPage,
            startIndex: startIndex,
            endIndex: endIndex,
        };
    }, [currentPage, maxPages, totalItems, pageSize]);
}

function First({onPageClick, page}) {
    return (
        <BsPagination.First onClick={() => onPageClick(0)} disabled={page === 0}>
            <FontAwesomeIcon icon="fa-solid fa-backward-fast"/>
        </BsPagination.First>
    );
}

function Prev({onPageClick, page}) {
    return (
        <BsPagination.Prev onClick={() => onPageClick(page - 1)} disabled={page === 0}>
            <FontAwesomeIcon icon="fa-solid fa-backward-step"/>
        </BsPagination.Prev>
    );
}

function Next({onPageClick, page, totalPages}) {
    return (
        <BsPagination.Next onClick={() => onPageClick(page + 1)} disabled={page >= totalPages - 1}>
            <FontAwesomeIcon icon="fa-solid fa-forward-step"/>
        </BsPagination.Next>
    );
}

function Last({onPageClick, page, totalPages}) {
    return (
        <BsPagination.Last onClick={() => onPageClick(totalPages - 1)} disabled={page >= totalPages - 1}>
            <FontAwesomeIcon icon="fa-solid fa-forward-fast"/>
        </BsPagination.Last>
    );
}

function Page({onPageClick, page, index}) {
    return (
        <BsPagination.Item
            onClick={() => onPageClick(index)}
            active={page === index}>
            {index + 1}
        </BsPagination.Item>
    );
}

function Pagination({page = 0, max = 10, total = 0, maxPages = 5, onPageClick = noop}) {
    const pager = usePager(page, max, total, maxPages);
    const pages = [];
    const itemProps = {page, onPageClick, totalPages: pager.totalPages};

    pages.push(<First key="first" {...itemProps}/>);
    pages.push(<Prev key="prev" {...itemProps} />);

    for (let i = pager.startPage; i <= pager.endPage; i++) {
        pages.push(<Page {...itemProps} index={i} key={i}/>);
    }

    pages.push(<Next key="next" {...itemProps}/>);
    pages.push(<Last key="last" {...itemProps}/>);

    return (
        <BsPagination size="sm">
            {pages}
        </BsPagination>
    );
}

export default Pagination;
