import React from "react";
import {Alert, Button, Table} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import teamService from "@services/team.service";
import resolveData from "@components/HOC/resolveData";
import secureRoute from "@components/HOC/secureRoute";
import Breadcrumb from "@components/Breadcrumb/Breadcrumb";
import TableHolder from "@components/TableHolder/TableHolder";
import {DetailButton} from "@components/Button";
import ButtonBar from "@components/ButtonBar/ButtonBar";
import SimpleCard from "@components/SimpleCard/SimpleCard";

function TeamList(props) {
    const {t} = useTranslation();
    const {teams = [], currentUser} = props;
    const canEdit = currentUser.hasPrivilege('PRIV_EDIT_TEAMS');
    return (
        <>
            <Breadcrumb title="menu.teams"/>

            <SimpleCard>
                {!teams.length && (
                    <Alert variant="info" className="mb-0">{t('msg.noTeam')}</Alert>
                )}

                {!!teams.length && (
                    <TableHolder>
                        <Table striped bordered>
                            <thead>
                            <tr>
                                {canEdit && (
                                    <th/>
                                )}
                                <th className="nowrap">{t('label.teamId')}</th>
                                <th className="nowrap">{t('label.teamName')}</th>
                                <th className="nowrap">{t('label.teamDescription')}</th>
                            </tr>
                            </thead>
                            <tbody>
                            {teams.map(team =>
                                <tr key={team.id}>
                                    {canEdit && (
                                        <td>
                                            <DetailButton url={'/secure/teams/' + team.id}
                                                          title="tooltip.teamDetail"/>
                                        </td>
                                    )}
                                    <td>{team.id}</td>
                                    <td>{team.name}</td>
                                    <td>{team.description}</td>
                                </tr>
                            )}
                            </tbody>
                        </Table>
                    </TableHolder>
                )}
            </SimpleCard>

            {canEdit && (
                <ButtonBar>
                    <Button variant="primary" as={Link}
                            to="/secure/teams/create">{t('button.createTeam')}</Button>
                </ButtonBar>
            )}
        </>
    );
}

export default secureRoute(resolveData(TeamList, () => {
    return {
        teams: teamService.getAllTeams()
    };
}));
