import {Alert, Col, Row} from "react-bootstrap";
import KudosItem from "@views/Kudos/KudosItem";
import React, {useMemo} from "react";
import {noop} from "lodash";
import {useTranslation} from "react-i18next";
import Loading from "@components/Loading/Loading";
import {splitArrayIntoColumns} from "@utils/array-utils";

function KudosSceneEmpty() {
    const {t} = useTranslation();
    return <Alert>{t('msg.noKudosExist')}</Alert>
}

function KudosScene({kudos = [], loading = false, onDeleteClick = noop}) {
    const chunks = useMemo(() => splitArrayIntoColumns(kudos, 3), [kudos]);

    if (loading) {
        return <Loading/>
    }

    if (!kudos.length) {
        return <KudosSceneEmpty/>
    }

    return (
        <Row>
            {chunks.map((chunk, column) => (
                <Col sm={4} key={column}>
                    {chunk.map((kudosItem, index) => (
                        <div key={index} className="mb-3">
                            <KudosItem kudosItem={kudosItem}
                                       onDeleteClick={onDeleteClick}/>
                        </div>
                    ))}
                </Col>
            ))}
        </Row>
    );
}

export default KudosScene;
