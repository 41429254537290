import {withRouter} from "react-router-dom";
import React from "react";

const elementKeyTracker = {
    id: 0,
    next: () => (++elementKeyTracker.id)
};

function remountOnLocationChange(WrappedComponent, {onPathname = true, onSearch = true} = {}) {
    class ReMounter extends React.Component {
        constructor(props) {
            super(props);
            this.mounted = false;
            this.state = {
                key: elementKeyTracker.next()
            };
        }

        componentDidMount() {
            this.mounted = true;
        }

        componentWillUnmount() {
            this.mounted = false;
        }

        componentDidUpdate(prevProps, prevState, snapshot) {
            if (!this.shouldRemount(prevProps)) {
                return;
            }
            this.setState({key: elementKeyTracker.next()});
        }

        shouldRemount(prevProps) {
            if (onPathname) {
                const currentPath = this.props.location.pathname;
                const prevPath = prevProps.location.pathname;
                if (currentPath !== prevPath) {
                    return true;
                }
            }

            if (onSearch) {
                const currentSearch = this.props.location.search;
                const prevSearch = prevProps.location.search;
                if (currentSearch !== prevSearch) {
                    return true;
                }
            }

            return false;
        }

        render() {
            return <WrappedComponent key={this.state.key} {...this.props} />
        }
    }

    return withRouter(ReMounter);
}

export default remountOnLocationChange;
