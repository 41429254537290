import useCurrentUser from "@/hooks/useCurrentUser";
import {useMemo} from "react";

const usePrivilege = (privilege) => {
    const currentUser = useCurrentUser();
    return useMemo(() => {
        if (!currentUser) {
            return false;
        }
        return !!currentUser.hasPrivilege(privilege);
    }, [currentUser, privilege]);
};

export default usePrivilege;
