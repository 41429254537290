import {useTranslation} from "react-i18next";
import secureRoute from "@components/HOC/secureRoute";
import resolveData from "@components/HOC/resolveData";
import Breadcrumb from "@components/Breadcrumb/Breadcrumb";
import * as Yup from "yup";
import {arrayMinElements, checkAssessmentFormTemplateNameExistence, maxLength, required} from "@utils/validations";
import React, {useMemo} from "react";
import BackendErrors from "@components/BackendErrors/BackendErrors";
import {Col, Form, Row} from "react-bootstrap";
import ButtonBar from "@components/ButtonBar/ButtonBar";
import SimpleCard from "@components/SimpleCard/SimpleCard";
import {DeleteButton} from "@components/Button";
import {useFieldArray, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import InputTextFormGroup from "@components/Form/InputTextFromGroup/InputTextFormGroup";
import InputTextareaFormGroup from "@components/Form/InputTextareaFormGroup/InputTextareaFormGroup";
import SelectFormGroup from "@components/Form/SelectFormGroup/SelectFormGroup";
import toastService from "@services/toast.service";
import CategorySelector from "@views/Assessments/AssessmentCategories/CategorySelector/CategorySelector";
import SubmitButton from "@components/Button/SubmitButton/SubmitButton";
import assessmentFormTemplateService from "@services/assessment-form-template.service";

function createValidationSchema(assessmentTemplateId) {
    return Yup.object().shape({
        name: Yup.string()
            .required(required)
            .max(255, maxLength)
            .test('name', {code: 'error.nameUsed'},
                checkAssessmentFormTemplateNameExistence(assessmentTemplateId, 500)),
        teamId: Yup.number()
            .typeError(required)
            .required(required),
        categories: Yup.array(
            Yup.object().shape({
                categoryId: Yup.number()
                    .required(required),
                description: Yup.string()
                    .required(required),
            })
        ).min(1, arrayMinElements)
    });
}

function AssessmentFormTemplateEdit(props) {
    const {t} = useTranslation();
    const {
        isNew,
        title,
        categories,
        teams,
        assessmentTemplateId,
        assessmentFormTemplate = {},
    } = props;

    const validationSchema = useMemo(() => createValidationSchema(assessmentTemplateId), [assessmentTemplateId]);

    const defaultValues = {
        name: '',
        teamId: '',
        categories: [],
        showCategoryNames: true,
        ...assessmentFormTemplate
    };

    const {
        register,
        control,
        handleSubmit,
        getValues,
        setError,
        clearErrors,
        formState: {errors, isValid, isValidating}
    } = useForm({
        mode: 'onChange',
        resolver: yupResolver(validationSchema),
        defaultValues: defaultValues
    });
    const {
        fields: categoryFields,
        append: appendCategoryFromToFormArray,
        remove: removeCategoryFromFormArray
    } = useFieldArray({
        control,
        name: "categories",
        keyName: 'htmlFieldId'
    });

    const submitText = isNew ? 'button.createAssessmentFormTemplate' : 'button.updateAssessmentFormTemplate';

    const values = getValues();
    const selectedCategoryIds = values.categories.map(category => category.categoryId);

    const addCategory = (category) => {
        appendCategoryFromToFormArray({
            categoryId: category.id,
            categoryName: category.name,
            description: ''
        });
    };

    const deleteCategory = (index) => {
        removeCategoryFromFormArray(index);
    };

    const onSubmit = (data) => {
        const createOrUpdatePromise = assessmentTemplateId ?
            assessmentFormTemplateService.updateAssessmentFormTemplate(assessmentTemplateId, data) :
            assessmentFormTemplateService.createAssessmentFormTemplate(data);
        const successMsg = assessmentTemplateId ? 'msg.assessmentFormTemplateUpdated' : 'msg.assessmentFormTemplateCreated';

        clearErrors();
        return createOrUpdatePromise
            .then(() => toastService.success(successMsg))
            .then(() => props.history.push('/secure/assessment-form-templates'))
            .catch(errors => {
                setError('backendErrors', errors);
                return Promise.reject(errors);
            });
    };

    return (
        <>
            <Breadcrumb title={title}
                        items={[{title: 'menu.assessmentFormTemplates', path: '/secure/assessment-form-templates'}]}/>

            <BackendErrors error={errors.backendErrors}/>

            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                <Row>
                    <Col md={12}>
                        <SimpleCard>
                            <InputTextFormGroup register={register}
                                                fieldName="name"
                                                label={t('label.templateName')}
                                                errors={errors}/>

                            <SelectFormGroup register={register}
                                             fieldName="teamId"
                                             label={t('label.team')}
                                             errors={errors}
                                             description={t('msg.selectTeamForAssessmentFormTemplate')}
                                             elements={teams}
                                             bindLabel="name"
                                             bindValue="id">
                            </SelectFormGroup>

                            <Form.Group className="mb-2">
                                <Form.Check>
                                    <Form.Check.Input {...register('showCategoryNames')}
                                                      id="showCategoryNamesCheckbox"
                                                      className="me-1"
                                                      value={true}
                                                      isInvalid={errors.showCategoryNames}/>
                                    <Form.Check.Label htmlFor="showCategoryNamesCheckbox">
                                        {t('label.showCategoryNames')}
                                    </Form.Check.Label>
                                </Form.Check>
                                <Form.Text>{t('msg.showCategoryNamesDescription')}</Form.Text>
                            </Form.Group>

                            {categoryFields.map((field, index) => (
                                <SimpleCard title={field.categoryName} key={field.htmlFieldId}>
                                    <InputTextareaFormGroup register={register}
                                                            fieldName={`categories[${index}].description`}
                                                            label={t('label.assessmentFormCategoryDescription')}
                                                            rows={10}
                                                            description={t('msg.useMarkdownInThisArea')}
                                                            errors={errors}/>
                                    <DeleteButton title={t('msg.deleteAssessmentFormTemplateCategory')}
                                                  onClick={() => deleteCategory(index)}/>
                                </SimpleCard>
                            ))}
                        </SimpleCard>
                    </Col>

                    <Col md={12}>
                        <CategorySelector categories={categories}
                                          onCategorySelected={(category) => addCategory(category)}
                                          selectedCategoryIds={selectedCategoryIds}/>
                    </Col>
                </Row>

                <ButtonBar>
                    <SubmitButton text={submitText} isValid={isValid} isValidating={isValidating}/>
                </ButtonBar>
            </form>
        </>
    );
}

export default secureRoute(resolveData(AssessmentFormTemplateEdit, (props) => {
    const {assessmentTemplateId} = props.match.params;
    const isNew = !assessmentTemplateId;
    const title = isNew ? 'title.createAssessmentFormTemplate' : 'title.updateAssessmentFormTemplate';
    const resolveData = {title, isNew, assessmentTemplateId};
    if (assessmentTemplateId) {
        resolveData.assessmentFormTemplate = assessmentFormTemplateService.getAssessmentFormTemplateById(assessmentTemplateId);
    }
    resolveData.categories = assessmentFormTemplateService.getCategories();
    resolveData.teams = assessmentFormTemplateService.getTeamIdAndNames();
    return resolveData;
}));
