import React from "react";
import {useTranslation} from "react-i18next";
import {withFormik} from "formik";
import {Button, Card, Col, Form, ListGroup, ListGroupItem, Row} from "react-bootstrap";
import * as Yup from 'yup';
import {atLeastOneCheckbox, checkEmailExistence, maxLength, minLength, required} from "@utils/validations";
import {FormInvalidFeedback} from "@components/Form";
import toastService from "@services/toast.service";
import secureRoute from "@components/HOC/secureRoute";
import Breadcrumb from "@components/Breadcrumb/Breadcrumb";
import resolveData from "@components/HOC/resolveData";
import userService from "@services/user.service";
import EnumMsg from "@components/EnumMsg/EnumMsg";
import ButtonBar from "@components/ButtonBar/ButtonBar";
import BackendErrors from "@components/BackendErrors/BackendErrors";
import remountOnLocationChange from "@components/HOC/remountOnLocationChange";
import developerService from "@services/developer.service";

function UserEdit(props) {
    const {t} = useTranslation();
    const {
        userId,
        values,
        touched,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        isValidating,
        roles,
        developers,
    } = props;

    const isNew = !userId;
    const userStatusList = ['ACTIVE', 'INACTIVE'];
    const pageTitle = isNew ? 'title.createUser' : 'title.editUser';
    const submitText = isNew ? 'button.createUser' : 'button.updateUser';
    const submitDisabled = isSubmitting || isValidating;

    return (
        <>
            <Breadcrumb title={pageTitle} items={[{title: 'title.users', path: '/secure/users'}]}/>

            <BackendErrors error={errors.backendErrors}/>

            <form onSubmit={handleSubmit} autoComplete="off">
                <Row>
                    <Col md={6}>
                        <Form.Group className="mb-3" controlId="firstName">
                            <Form.Label>{t('label.firstName')}</Form.Label>
                            <Form.Control name="firstName" value={values.firstName} maxLength={255}
                                          onChange={handleChange} onBlur={handleBlur}
                                          isInvalid={errors.firstName && touched.firstName}/>
                            <FormInvalidFeedback errors={errors.firstName}/>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="lastName">
                            <Form.Label>{t('label.lastName')}</Form.Label>
                            <Form.Control name="lastName" value={values.lastName} maxLength={255}
                                          onChange={handleChange} onBlur={handleBlur}
                                          isInvalid={errors.lastName && touched.lastName}/>
                            <FormInvalidFeedback errors={errors.lastName}/>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="email">
                            <Form.Label>{t('label.email')}</Form.Label>
                            <Form.Control name="email" value={values.email} maxLength={255}
                                          autoComplete="new-user-email"
                                          onChange={handleChange} onBlur={handleBlur}
                                          isInvalid={errors.email && touched.email}/>
                            <FormInvalidFeedback errors={errors.email}/>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="developerId">
                            <Form.Label>{t('label.developer')}</Form.Label>
                            <Form.Select name="developerId" value={values.developerId}
                                         onChange={handleChange} onBlur={handleBlur}
                                         isInvalid={errors.email && touched.email}>
                                <option/>
                                {developers.map((developer, index) => (
                                    <option key={index} value={developer.id}>{developer.fullName}</option>
                                ))}
                            </Form.Select>
                            <FormInvalidFeedback errors={errors.developerId}/>
                        </Form.Group>

                        {isNew && (
                            <>
                                <Form.Group className="mb-3" controlId="password">
                                    <Form.Label>{t('label.password')}</Form.Label>
                                    <Form.Control name="password" value={values.password} maxLength={255}
                                                  type="password" autoComplete="new-password"
                                                  onChange={handleChange} onBlur={handleBlur}
                                                  isInvalid={errors.password && touched.password}/>
                                    <FormInvalidFeedback errors={errors.password}/>
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="passwordAgain">
                                    <Form.Label>{t('label.passwordAgain')}</Form.Label>
                                    <Form.Control name="passwordAgain" value={values.passwordAgain} maxLength={255}
                                                  type="password" autoComplete="new-password-2"
                                                  onChange={handleChange} onBlur={handleBlur}
                                                  isInvalid={errors.passwordAgain && touched.passwordAgain}/>
                                    <FormInvalidFeedback errors={errors.passwordAgain}/>
                                    <FormInvalidFeedback errors={errors.passwordEquality}
                                                         touched={touched.password || touched.passwordAgain}/>
                                </Form.Group>
                            </>
                        )}
                    </Col>

                    <Col md={6}>
                        <Form.Group className="mb-3" controlId="status">
                            <Form.Label>{t('label.status')}</Form.Label>

                            {userStatusList.map(status => (
                                <Form.Check key={status}>
                                    <Form.Check.Input type="radio" name="status" id={`user-status-radio-${status}`}
                                                      className="me-1"
                                                      checked={status === values.status}
                                                      value={status}
                                                      onChange={handleChange}
                                                      onBlur={handleBlur}
                                                      isInvalid={errors.status && touched.status}/>
                                    <Form.Check.Label htmlFor={`user-status-radio-${status}`}>
                                        <EnumMsg enumName='UserStatus' value={status}/>
                                    </Form.Check.Label>
                                </Form.Check>
                            ))}
                            <FormInvalidFeedback errors={errors.status}/>
                        </Form.Group>

                        <Card className="mb-3">
                            <Card.Header>{t('label.roles')}</Card.Header>

                            <ListGroup variant="flush">
                                {roles.map((role, index) => (
                                    <ListGroupItem key={role.id}>
                                        <Form.Check>
                                            <Form.Check.Input type="checkbox" name={`roles[${role.id}]`}
                                                              id={`user-roles-check-${index}`}
                                                              className="me-1"
                                                              checked={values.roles[role.id]}
                                                              value={true}
                                                              onChange={handleChange}
                                                              onBlur={handleBlur}
                                                              isInvalid={errors.roles && touched.roles}/>
                                            <Form.Check.Label htmlFor={`user-roles-check-${index}`}>
                                                {role.name}
                                            </Form.Check.Label>
                                        </Form.Check>
                                    </ListGroupItem>
                                ))}
                                {errors.roles && touched.roles && (
                                    <ListGroupItem>
                                        <FormInvalidFeedback touched={touched.roles} errors={errors.roles}/>
                                    </ListGroupItem>
                                )}
                            </ListGroup>
                        </Card>
                    </Col>
                </Row>

                <ButtonBar>
                    <Button variant="primary" type="submit" disabled={submitDisabled}>{t(submitText)}</Button>
                </ButtonBar>
            </form>
        </>
    );
}

const UserEditWithFormik = withFormik({
    mapPropsToValues: (props) => {
        const {user = {}, userId, roles} = props;

        const checkedRoles = {};
        roles.forEach(role => checkedRoles[role.id] = false);
        (user.roles || []).forEach(role => checkedRoles[role.id] = true);

        const commonFields = {
            firstName: user.firstName || '',
            lastName: user.lastName || '',
            email: user.email || '',
            status: user.status || 'ACTIVE',
            roles: checkedRoles,
            developerId: user.developerId || '',
        };

        if (userId) {
            return commonFields;
        }

        const createFields = {
            password: '',
            passwordAgain: ''
        };

        return {...commonFields, ...createFields};
    },

    validationSchema: props => props.validationSchema,
    validateOnBlur: false,

    handleSubmit: (values, {props, setErrors}) => {
        const {userId} = props;
        const createOrUpdatePromise = userId ?
            userService.updateUser(userId, values) :
            userService.createUser(values);
        const successMsg = userId ? 'msg.userUpdated' : 'msg.userCreated';

        return createOrUpdatePromise
            .then(() => toastService.success(successMsg))
            .then(() => props.history.push('/secure/users'))
            .catch(errors => {
                setErrors({backendErrors: errors});
                return Promise.reject(errors);
            });
    },

    displayName: 'CreateUserForm',
})(UserEdit);

function WithValidationSchema(props) {
    const passwordSchema = {};
    if (!props.userId) {
        passwordSchema.password = Yup.string()
            .required(required)
            .min(8, minLength)
            .max(255, maxLength);
        passwordSchema.passwordAgain = Yup.string()
            .required(required)
            .min(8, minLength)
            .max(255, maxLength)
            .oneOf([Yup.ref('password'), null], {code: 'error.bothPasswordsMustBeSame'});
    }
    const validationSchema = Yup.object().shape({
        firstName: Yup.string()
            .required(required)
            .max(255, maxLength),
        lastName: Yup.string()
            .required(required)
            .max(255, maxLength),
        email: Yup.string()
            .required(required)
            .max(255, maxLength)
            .test('email', {code: 'error.emailUsed'}, checkEmailExistence(props.userId, 500)),
        status: Yup.string()
            .required(required)
            .max(255, maxLength),
        roles: Yup.object()
            .required(required)
            .test('roles', null, (obj) => {
                const keys = Object.keys(obj);
                const indexOfFirstTrue = keys.findIndex(key => obj[key]);
                if (indexOfFirstTrue >= 0) {
                    return true;
                }

                return new Yup.ValidationError(
                    atLeastOneCheckbox(),
                    null,
                    'roles'
                );
            }),
        ...passwordSchema
    });

    return <UserEditWithFormik {...props} validationSchema={validationSchema}/>
}

export default secureRoute(remountOnLocationChange(resolveData(WithValidationSchema, (props) => {
    const {userId} = props.match.params;
    const data = {
        userId: Promise.resolve(userId),
        roles: userService.getRoles(),
        developers: developerService.getAllDevelopers().then(developers => {
            return developers.sort(developerService.COMPARE_BY_NAME);
        }),
    };
    if (userId) {
        const userPromise = userService.getUser(userId);
        return {user: userPromise, ...data};
    }
    return data;
})));
