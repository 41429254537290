import {useState} from "react";

let uniqueId = 0;

const useUniqueId = () => {
    const [value] = useState(() => uniqueId++);
    return value;
};

export default useUniqueId;
