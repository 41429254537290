import React, {useState} from "react";
import {Alert, Button, Card, Col, Form, Row} from "react-bootstrap";
import userService from "../../services/user.service";
import {Link, useHistory, useLocation} from "react-router-dom";
import {useTranslation} from 'react-i18next';

function Login() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [rememberMe, setRememberMe] = useState(true);
    const [loginError, setLoginError] = useState('');
    const {t} = useTranslation();
    const history = useHistory();
    const location = useLocation();
    const {from} = location.state || {from: {pathname: "/secure/home"}};

    const handleError = (error) => {
        if (error?.code === 'error.credentialsExpired') {
            history.push('/credentials-expired?username=' + encodeURIComponent(username));
            return;
        }

        setLoginError(error.code);
    };

    const login = (e) => {
        e.preventDefault();
        userService.login(username, password, rememberMe)
            .then(() => history.replace(from))
            .catch(error => handleError(error));
    };

    return (
        <Row>
            <Col md={{span: 6, offset: 3}}>
                <Card>
                    <Card.Header>
                        <h3>{t('title.login')}</h3>
                    </Card.Header>
                    <Card.Body>
                        <Form onSubmit={e => login(e)}>
                            {!!loginError && (
                                <Alert variant="danger">{t(loginError)}</Alert>
                            )}

                            <Form.Group className="mb-3" size="lg" controlId="username">
                                <Form.Label>{t('label.username')}</Form.Label>
                                <Form.Control
                                    autoFocus
                                    type="text"
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                />
                            </Form.Group>

                            <Form.Group className="mb-3" size="lg" controlId="password">
                                <Form.Label>{t('label.password')}</Form.Label>
                                <Form.Control
                                    type="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                            </Form.Group>

                            <Form.Group className="mb-3" size="lg" controlId="rememberMe">
                                <Form.Check
                                    type="checkbox"
                                    label={t('label.rememberMe')}
                                    checked={rememberMe}
                                    id="rememberMe"
                                    value={'true'}
                                    onChange={(e) => setRememberMe(e.target.checked)}/>
                            </Form.Group>

                            <div className="d-flex">
                                <div>
                                    <Button variant="primary" type="submit">{t('button.login')}</Button>
                                </div>
                                <div className="text-end flex-fill">
                                    <Link to="/forgot-password">{t('label.forgotPassword')}</Link>
                                </div>
                            </div>
                        </Form>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );
}

export default Login;
