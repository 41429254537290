import secureRoute from "@components/HOC/secureRoute";
import {useTranslation} from "react-i18next";
import resolveData from "@components/HOC/resolveData";
import Breadcrumb from "@components/Breadcrumb/Breadcrumb";
import React from "react";
import userService from "@services/user.service";
import {Alert, Button, Card, Table} from "react-bootstrap";
import {DetailButton, EditButton} from "@components/Button";
import TableHolder from "@components/TableHolder/TableHolder";
import EnumMsg from "@components/EnumMsg/EnumMsg";
import {formatDateTime} from "@utils/date-time.utils";
import ButtonBar from "@components/ButtonBar/ButtonBar";
import {Link} from "react-router-dom";

function UserList(props) {
    const {t} = useTranslation();
    const {users} = props;

    const renderUserListTable = () => {
        return (
            <TableHolder>
                <Table striped bordered>
                    <thead>
                    <tr>
                        <th className="table-actions"/>
                        <th className="table-actions">{t('label.userId')}</th>
                        <th className="nowrap">{t('label.username')}</th>
                        <th className="nowrap">{t('label.firstName')}</th>
                        <th className="nowrap">{t('label.lastName')}</th>
                        <th className="nowrap">{t('label.status')}</th>
                        <th className="nowrap">{t('label.lastLoginDate')}</th>
                        <th className="nowrap">{t('label.roles')}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {users.map(user =>
                        <tr key={user.id}>
                            <td className="table-actions">
                                <DetailButton url={`/secure/users/${user.id}`}
                                              title="tooltip.userDetail"/>
                                &nbsp;
                                <EditButton url={`/secure/users/${user.id}/edit`}
                                            title="tooltip.editUser"/>
                            </td>
                            <td className="table-actions">{user.id}</td>
                            <td>{user.username}</td>
                            <td>{user.firstName}</td>
                            <td>{user.lastName}</td>
                            <td><EnumMsg enumName='UserStatus' value={user.status}/></td>
                            <td>{formatDateTime(user.lastLoginDate)}</td>
                            <td>{user.roles.map(role => role.name).join(', ')}</td>
                        </tr>
                    )}
                    </tbody>
                </Table>
            </TableHolder>
        );
    };

    const renderUserListEmpty = () => {
        return <Alert className="mb-0" variant="info">{t('msg.noUser')}</Alert>;
    };

    return (
        <>
            <Breadcrumb title="menu.users"/>

            <Card className="mb-3">
                <Card.Body>
                    {users.length ? renderUserListTable() : renderUserListEmpty()}
                </Card.Body>
            </Card>

            <ButtonBar>
                <Button variant="primary" as={Link} to="/secure/users/create">{t('button.createUser')}</Button>
            </ButtonBar>
        </>
    );
}

export default secureRoute(resolveData(UserList, () => {
    return {
        users: userService.getUsers()
    }
}));
