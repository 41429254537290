import secureRoute from "@components/HOC/secureRoute";
import {useTranslation} from "react-i18next";
import SimpleCard from "@components/SimpleCard/SimpleCard";
import {IconButton} from "@components/Button";
import React, {useState} from "react";
import importService from "@services/import.service";
import TableHolder from "@components/TableHolder/TableHolder";
import {Table} from "react-bootstrap";

function YoutrackUsers() {
    const {t} = useTranslation();
    const [developers, setDevelopers] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleOnClick = () => {
        setLoading(true);
        setDevelopers([]);
        importService.importYoutrackUsers()
            .then(importedDevelopers => setDevelopers(importedDevelopers))
            .finally(() => setLoading(false));
    };

    return (
        <>
            <SimpleCard>
                {t('msg.pressButtonToImportYoutrackDevelopers')}
                <div className="mt-2 mb-2">
                    <IconButton onClick={() => handleOnClick()}
                                disabled={loading}
                                variant="primary"
                                icon="sync"
                                text="İçe Aktar"/>
                </div>
            </SimpleCard>

            {!!developers.length && (
                <SimpleCard title="title.importedYoutrackUsers">
                    <TableHolder>
                        <Table striped bordered>
                            <thead>
                            <tr>
                                <th>{t('label.developerId')}</th>
                                <th>{t('label.fullName')}</th>
                                <th>{t('label.youtrackLogin')}</th>
                                <th>{t('label.email')}</th>
                            </tr>
                            </thead>
                            <tbody>
                            {developers.map((developer, index) => (
                                <tr key={index}>
                                    <td>{developer.id}</td>
                                    <td>{developer.fullName}</td>
                                    <td>{developer.youtrackLogin}</td>
                                    <td>{developer.email}</td>
                                </tr>
                            ))}
                            </tbody>
                        </Table>
                    </TableHolder>
                </SimpleCard>
            )}
        </>
    );
}

export default secureRoute(YoutrackUsers);
