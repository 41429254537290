import AssessmentIcon from "@components/AssessmentIcon/AssessmentIcon";

function AssessmentIconList({voteTypes = [], voteTypeCountMap = {}, newLineForEachType = false}) {
    const images = [];

    for (let i = 0; i < voteTypes.length; i++) {
        const voteType = voteTypes[i];
        const voteCount = voteTypeCountMap[voteType.id];
        if (voteCount) {
            for (let j = 0; j < voteCount; j++) {
                images.push(<AssessmentIcon voteType={voteType} key={i + '_' + j}/>);
                images.push(' ');
            }
        }
        if (newLineForEachType && i !== voteTypes.length - 1) {
            images.push(<br/>);
        }
    }

    return images;
}

export default AssessmentIconList;
