import secureRoute from "@components/HOC/secureRoute";
import resolveData from "@components/HOC/resolveData";
import sprintService from "@services/sprint.service";
import sprintAssessmentService from "@services/sprint-assessment.service";
import Breadcrumb from "@components/Breadcrumb/Breadcrumb";
import React from "react";
import {useTranslation} from "react-i18next";
import developerService from "@services/developer.service";
import {useFieldArray, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {arrayMinElements, required} from "@utils/validations";
import SimpleCard from "@components/SimpleCard/SimpleCard";
import SelectFormGroup from "@components/Form/SelectFormGroup/SelectFormGroup";
import {Card, ListGroup, ListGroupItem} from "react-bootstrap";
import {DeleteButton, TooltipIconButton} from "@components/Button";
import SubmitButton from "@components/Button/SubmitButton/SubmitButton";
import ButtonBar from "@components/ButtonBar/ButtonBar";
import BackendErrors from "@components/BackendErrors/BackendErrors";
import toastService from "@services/toast.service";
import remountOnLocationChange from "@components/HOC/remountOnLocationChange";
import SprintSummaryView from "@views/Sprints/SprintSummaryView/SprintSummaryView";
import assessmentFormTemplateService from "@services/assessment-form-template.service";

const validationSchema = Yup.object().shape({
    templateId: Yup.number()
        .typeError(required)
        .required(required),
    sprintId: Yup.number()
        .typeError(required)
        .required(required),
    developers: Yup.array(
        Yup.object().shape({
            id: Yup.number()
        })
    ).min(2, arrayMinElements)
});

function AssessmentFormCreate(props) {
    const {t} = useTranslation();
    const {sprint, formTemplates, developers} = props;

    const defaultValues = {
        templateId: formTemplates.length ? formTemplates[0].id : '',
        sprintId: sprint.id,
        developers: developers || [],
    };

    const {
        register,
        control,
        handleSubmit,
        getValues,
        setError,
        clearErrors,
        formState: {errors, isValid, isValidating}
    } = useForm({
        mode: 'onChange',
        resolver: yupResolver(validationSchema),
        defaultValues: defaultValues
    });
    const {
        fields: developerFields,
        append: appendDeveloperIdToFormArray,
        remove: removeDeveloperIdFromFormArray
    } = useFieldArray({
        control,
        name: "developers",
        keyName: 'htmlFieldId'
    });

    const values = getValues();
    const selectedDeveloperIds = (values.developers || []).map(developer => developer.id);
    const excludedDevelopers = developers.filter(developer => !selectedDeveloperIds.includes(developer.id));

    const onSubmit = (data) => {
        clearErrors();
        sprintAssessmentService.sendAssessmentFormToDevelopers(data)
            .then(() => toastService.success('msg.assessmentFormSentToDevelopers'))
            .then(() => props.history.push('/secure/home'))
            .catch(errors => {
                setError('backendErrors', errors);
                return Promise.reject(errors);
            });
    };

    return (
        <>
            <Breadcrumb title="title.sendAssessmentForm"/>

            <BackendErrors error={errors.backendErrors}/>

            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                <SprintSummaryView sprint={sprint}/>

                <SimpleCard>
                    <SelectFormGroup register={register}
                                     fieldName="templateId"
                                     label={t('label.assessmentFormTemplate')}
                                     errors={errors}
                                     description={t('msg.selectAssessmentFormTemplate')}
                                     elements={formTemplates}
                                     bindLabel="name"
                                     bindValue="id">
                    </SelectFormGroup>

                    <Card className="rounded-0">
                        <Card.Header>{t('title.developersToBeEvaluated')}</Card.Header>
                        <ListGroup variant="flush">
                            {developerFields.map((developerField, index) => (
                                <ListGroupItem key={index}>
                                    <DeleteButton onClick={() => removeDeveloperIdFromFormArray(index)}
                                                  title="tooltip.deleteDeveloperFromAssessmentForm"/>
                                    &nbsp;
                                    {developerField.fullName}
                                </ListGroupItem>
                            ))}

                            {!developerFields.length && (
                                <ListGroupItem className="bg-danger text-white">
                                    {t('msg.noDeveloperAddedToAssessmentForm')}
                                </ListGroupItem>
                            )}
                        </ListGroup>
                    </Card>

                    <Card className="rounded-0 mt-3">
                        <Card.Header>{t('title.developersNotToBeEvaluated')}</Card.Header>
                        <ListGroup variant="flush">
                            {excludedDevelopers.map((developer, index) => (
                                <ListGroupItem key={index}>
                                    <TooltipIconButton icon="plus"
                                                       onClick={() => appendDeveloperIdToFormArray(developer)}
                                                       title="tooltip.addDeveloperToAssessmentForm"/>
                                    &nbsp;
                                    {developer.fullName}
                                </ListGroupItem>
                            ))}

                            {!excludedDevelopers.length && (
                                <ListGroupItem>{t('msg.allDevelopersInTeamAddedToAssessmentForm')}</ListGroupItem>
                            )}
                        </ListGroup>
                    </Card>
                </SimpleCard>

                <ButtonBar>
                    <SubmitButton text={'button.sendAssessmentForm'} isValid={isValid} isValidating={isValidating}/>
                </ButtonBar>
            </form>
        </>
    );
}

export default secureRoute(remountOnLocationChange(resolveData(AssessmentFormCreate, (props) => {
    const queryStringParams = new URLSearchParams(props.location.search);
    const sprintId = queryStringParams.get('sprintId') || '';
    const boardId = queryStringParams.get('boardId') || '';

    return {
        sprint: sprintService.getSprintSummaryById(sprintId),
        formTemplates: assessmentFormTemplateService.getAssessmentFormTemplatesByBoardId(boardId),
        developers: developerService.getTeamDevelopersByBoardId(boardId)
    };
})));
