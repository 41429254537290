import axios from "axios";
import {AdminUser} from "@models/AdminUser.model";

const userService = {};

// TODO promise caching is necessary
userService.currentUser = null;

userService.getCurrentUser = () => {
    if (userService.currentUser) {
        return Promise.resolve(userService.currentUser);
    }

    return axios.get('/api/users/me')
        .then(user => {
            userService.currentUser = new AdminUser(user);
            return userService.currentUser;
        });
};

userService.login = (username, password, rememberMe) => {
    return axios.post('/api/login', {username, password, rememberMe})
        .then(() => userService.getCurrentUser());
};

userService.logout = () => {
    return axios.get('/api/logout')
        .then((response) => {
            userService.currentUser = null
            return response;
        });
};

userService.getUsers = () => {
    return axios.get('/api/users');
};

userService.getUser = (userId) => {
    return axios.get(`/api/users/${userId}`);
};

userService.createUser = (data) => {
    return axios.post(`/api/users`, convertRoles(data));
};

userService.updateUser = (userId, data) => {
    return axios.put(`/api/users/${userId}`, convertRoles(data));
};

const convertRoles = (data) => {
    const roles = Object.keys(data.roles).filter(roleId => data.roles[roleId]);
    return {...data, roles};
};

userService.getRoles = () => {
    return axios.get('/api/roles');
};

userService.isEmailAvailable = (email, userId) => {
    const url = `/api/users/emails/available?email=${encodeURIComponent(email)}&userId=${userId || ''}`;
    return axios.get(url);
};

userService.changePassword = (form) => {
    return axios.post(`/api/users/change-password`, form);
};

userService.changePasswordWithoutLogin = (form) => {
    return axios.post(`/api/users/anonymous/change-password`, form);
};

userService.sendForgotPasswordMail = (data) => {
    return axios.post(`/api/users/forgot-password`, data);
};

userService.resetPassword = (form) => {
    return axios.post(`/api/users/reset-password`, form);
};

userService.validateResetPassword = (form) => {
    const {uid, token} = form;
    return axios.post(`/api/users/reset-password/validate`, {uid, token});
};

export default userService;
