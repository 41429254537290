import React, {useMemo} from "react";
import Breadcrumb from "@components/Breadcrumb/Breadcrumb";
import secureRoute from "@components/HOC/secureRoute";
import resolveData from "@components/HOC/resolveData";
import sprintService from "@services/sprint.service";
import SprintOverview from "@views/Sprints/SprintOverview/SprintOverview";
import SprintSnapshotDeveloperStats from "@views/Sprints/SprintSnapshotDeveloperStats/SprintSnapshotDeveloperStats";
import SprintAssessmentResults from "@views/Sprints/SprintAssessmentResults/SprintAssessmentResults";
import remountOnLocationChange from "@components/HOC/remountOnLocationChange";
import SprintComments from "@views/Sprints/SprintComments/SprintComments";

function SprintDetail(props) {
    const {board, sprint, overview, team, currentUser} = props;

    const showAssessments = useMemo(() => {
        if (currentUser.hasPrivilege('PRIV_VIEW_ALL_ASSESSMENT_RESULTS')) {
            return true;
        }
        if (currentUser.hasPrivilege('PRIV_VIEW_TEAM_ASSESSMENT_RESULTS')) {
            if (currentUser.isTeamMember(team.id) || currentUser.isTeamLeader(team.id)) {
                return true;
            }
        }
        return false;
    }, [currentUser, team]);

    return (
        <>
            <Breadcrumb title="title.sprintDetail" titleParams={sprint}
                        items={[
                            {title: 'menu.boards', path: '/secure/boards'},
                            {title: 'title.boardDetail', titleParams: board, path: `/secure/boards/${board.id}`}
                        ]}/>

            <SprintOverview sprint={sprint} overview={overview} currentUser={currentUser}/>

            <SprintSnapshotDeveloperStats stats={overview.developerStats} sprint={sprint} team={team}/>

            {showAssessments && (
                <SprintAssessmentResults sprint={sprint} board={board} team={team}/>
            )}

            <SprintComments sprintId={sprint.id} teamId={team?.id} />
        </>
    );
}

const SprintDetailWithResolvedData = resolveData(SprintDetail, (props) => {
    const {sprintId} = props.match.params;
    const sprintOverviewPromise = sprintService.getDetailedSprintOverview(sprintId);
    return {
        board: sprintOverviewPromise.then(sprintOverview => sprintOverview.board),
        sprint: sprintOverviewPromise.then(sprintOverview => sprintOverview.sprint),
        team: sprintOverviewPromise.then(sprintOverview => sprintOverview.team),
        overview: sprintOverviewPromise.then(sprintOverview => sprintOverview),
    }
});

export default secureRoute(remountOnLocationChange(SprintDetailWithResolvedData));
