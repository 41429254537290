import {useMemo} from "react";
import {formatDateTimeWithSeconds, formatRelative} from "@utils/date-time.utils";

function useRelativeFormat(date) {
    return useMemo(() => formatRelative(date), [date]);
}

function useFormatWithSeconds(date) {
    return useMemo(() => formatDateTimeWithSeconds(date), [date]);
}

function TimeAgo({date}) {
    const relativeTime = useRelativeFormat(date);
    const formattedWithSeconds = useFormatWithSeconds(date);
    return <span title={formattedWithSeconds}>{relativeTime}</span>;
}

export default TimeAgo;
