import axios from "axios";

const teamTargetService = {};

teamTargetService.getTeamQuartersByTeamId = (teamId) => {
    return axios.get(`/api/teams/targets/quarters/${teamId}`);
};

teamTargetService.addTargetToTeam = (teamId, target, quarter) => {
    return axios.post(`/api/teams/targets/quarters`, {teamId, target, quarter});
};

teamTargetService.searchTeamTargets = (selectedTeam, selectedQuarter, targetValue) => {
    return axios.get(`/api/teams/targets/search`,{
        params: {
            selectedTeam: selectedTeam,
            selectedQuarter: selectedQuarter,
            targetValue : targetValue
        }
    });
};

export default teamTargetService;
