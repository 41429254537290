import moment from "moment";
import {range} from "lodash";

const format = (timestampOrDate, format = 'DD/MM/YYYY HH:mm') => {
    if (timestampOrDate || timestampOrDate === 0) {
        return moment(timestampOrDate).format(format);
    }
    return '';
}

const formatDateTime = (timestampOrDate) => {
    return format(timestampOrDate, 'DD/MM/YYYY HH:mm');
};

const formatDateTimeWithSeconds = (timestampOrDate) => {
    return format(timestampOrDate, 'DD/MM/YYYY HH:mm:ss');
};

const formatDate = (timestampOrDate) => {
    return format(timestampOrDate, 'DD/MM/YYYY');
};

const formatRelative = (timestampOrDate) => {
    return timestampOrDate ? moment(timestampOrDate).fromNow() : '';
};

const getMonthList = (start = 0, end = 11) => {
    start = start < 0 ? 0 : (start > 11 ? 11 : start);
    end = end < start ? start : (end > 11 ? 11 : end);
    return range(start, end + 1).map(month => ({
        value: month,
        name: `monthNames.${month}`
    }));
};

const getPastMonthsWithYear = (count = 12) => {
    let date = moment();
    const result = [];

    do {
        result.push({
            month: date.month(),
            year: date.year(),
            value: date.format('YYYY-MM')
        });

        date = date.subtract(1, 'month');
    } while (result.length < count);

    return result;
};

export {
    formatDateTime,
    formatDateTimeWithSeconds,
    formatDate,
    formatRelative,
    getMonthList,
    getPastMonthsWithYear,
}
