import {TooltipWrapper} from "@components/Button";

function AssessmentIcon(props) {
    const {voteType} = props;

    return (
        <TooltipWrapper title={voteType.name}>
            <div className={`vote-badge ${voteType.badgeIconClass} me-1`}/>
        </TooltipWrapper>
    );
}

export default AssessmentIcon;
