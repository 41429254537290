import axios from "axios";
import {buildQueryString} from "@utils/url-utils";

export default (function () {
    const baseUrl = '/api/kudos';

    /**
     * @param searchQuery
     * @returns {Promise<any>}
     */
    function getKudosBySearch(searchQuery = {}) {
        const queryString = buildQueryString(searchQuery);
        return axios.get(`${baseUrl}${queryString}`);
    }

    function createKudos(data) {
        return axios.post(`${baseUrl}`, data);
    }

    function deleteKudos(kudosId) {
        return axios.delete(`${baseUrl}/${kudosId}`);
    }

    function getPeople() {
        return axios.get(`${baseUrl}/people`);
    }

    function isAuthorizedToDelete(currentUser, kudos) {
        if (!currentUser) {
            return false;
        }

        if (currentUser.hasPrivilege('PRIV_DELETE_ALL_KUDOS')) {
            return true;
        }

        if (currentUser.hasPrivilege('PRIV_DELETE_OWN_KUDOS')) {
            const {authorUser: {id: authorUserId}} = kudos;
            return authorUserId === currentUser.id;
        }

        return false;
    }

    return {
        getKudosBySearch,
        createKudos,
        deleteKudos,
        getPeople,
        isAuthorizedToDelete,
    };
}());
