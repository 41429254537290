import {useTranslation} from "react-i18next";
import * as Yup from "yup";
import {maxLength, minLength, required} from "@utils/validations";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import userService from "@services/user.service";
import toastService from "@services/toast.service";
import {useHistory} from "react-router-dom";
import {Card, Col, Row} from "react-bootstrap";
import BackendErrors from "@components/BackendErrors/BackendErrors";
import InputTextFormGroup from "@components/Form/InputTextFromGroup/InputTextFormGroup";
import SubmitButton from "@components/Button/SubmitButton/SubmitButton";
import React from "react";
import resolveData from "@components/HOC/resolveData";

const validationSchema = Yup.object().shape({
    uid: Yup.string().required(required),
    token: Yup.string().required(required),
    newPassword: Yup.string()
        .required(required)
        .min(8, minLength)
        .max(255, maxLength),
    newPasswordAgain: Yup.string()
        .required(required)
        .min(8, minLength)
        .max(255, maxLength)
        .oneOf([Yup.ref('newPassword'), null], {code: 'error.bothPasswordsMustBeSame'}),
});

function ResetPassword({uid, token}) {
    const {t} = useTranslation();
    const history = useHistory();
    const defaultValues = {uid, token, newPassword: '', newPasswordAgain: ''};

    const {
        register,
        handleSubmit,
        setError,
        clearErrors,
        formState: {errors, isValid, isValidating, isSubmitting}
    } = useForm({
        mode: 'onChange',
        resolver: yupResolver(validationSchema),
        defaultValues: defaultValues
    });

    const onSubmit = (data) => {
        clearErrors();
        return userService.resetPassword(data)
            .then(() => {
                toastService.success('msg.passwordChanged');
                history.replace('/secure/home');
            })
            .catch(errors => {
                setError('backendErrors', errors);
                return errors;
            });
    };

    return (
        <Row>
            <Col md={{span: 6, offset: 3}}>
                <Card>
                    <Card.Header>
                        <h3>{t('title.resetPassword')}</h3>
                    </Card.Header>
                    <Card.Body>
                        <BackendErrors error={errors.backendErrors}/>

                        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                            <InputTextFormGroup register={register}
                                                fieldName="newPassword"
                                                label={t('label.newPassword')}
                                                type="password"
                                                errors={errors}/>

                            <InputTextFormGroup register={register}
                                                fieldName="newPasswordAgain"
                                                label={t('label.newPasswordAgain')}
                                                type="password"
                                                errors={errors}/>

                            <SubmitButton text="button.changePassword" isValid={isValid}
                                          isValidating={isValidating}
                                          isSubmitting={isSubmitting}/>
                        </form>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );
}

export default resolveData(ResetPassword, ({location = {}}) => {
    const queryStringParams = new URLSearchParams(location.search);
    const uid = queryStringParams.get('uid') || '';
    const token = queryStringParams.get('token') || '';
    return {
        uid,
        token,
        uidAndTokenValid: userService.validateResetPassword({uid, token})
    };
});
