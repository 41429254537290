import {createSlice} from '@reduxjs/toolkit'

let toastId = 0;

const toastSlice = createSlice({
    name: 'toasts',
    initialState: {
        toasts: []
    },
    reducers: {
        addToast: (state, action) => {
            const id = ++toastId;
            const {title, message, type, messageArgs} = action.payload;
            state.toasts = state.toasts || [];
            state.toasts.push({id, title, message, messageArgs, type, visible: true});
        },
        hideToast: (state, action) => {
            const id = action.payload.id;
            state.toasts = state.toasts || [];
            const toast = state.toasts.find(toast => toast.id === id);
            if (toast) {
                toast.visible = false;
            }
        },
        removeToast: (state, action) => {
            const id = action.payload.id;
            state.toasts = (state.toasts || []).filter(toast => toast.id !== id);
        }
    },
});

const {actions, reducer} = toastSlice;

export const {addToast, removeToast, hideToast} = actions;

export default reducer;
