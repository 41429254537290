import axios from "axios";

const developerService = {};

developerService.COMPARE_BY_NAME = function (a, b) {
    return (a.fullName || '').toLowerCase().localeCompare((b.fullName || '').toLowerCase());
};

developerService.getAllDevelopers = () => {
    return axios.get('/api/developers');
};

developerService.getTeamDevelopersByBoardId = (boardId) => {
    return axios.get(`/api/developers/boards/${boardId}`);
};

developerService.getTeamDevelopersByTeamId = (teamId) => {
    return axios.get(`/api/developers/teams/${teamId}`);
};

developerService.getAllDeveloperAliases = () => {
    return axios.get('/api/developers/aliases');
};

developerService.addDeveloperAlias = (developerId, alias) => {
    return axios.post('/api/developers/aliases', {developerId, alias});
};

developerService.deleteDeveloperAlias = (aliasId) => {
    return axios.delete(`/api/developers/aliases/${aliasId}`);
};

export default developerService;
