import {Container, Nav, Navbar, NavbarBrand, NavDropdown} from "react-bootstrap";
import {Link, NavLink, useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import userService from "@services/user.service";
import {resetCurrentUser} from "@store";
import {useDispatch} from "react-redux";
import useCurrentUser from "@/hooks/useCurrentUser";
import menuService from "@services/menu.service";

function Header() {
    const {t} = useTranslation();
    const favicon = "/img/favicon.png";

    return (
        <Navbar expand="lg" bg="primary" variant="dark" className="mb-3">
            <Container fluid>
                <NavbarBrand as={Link} to="/secure/home">
                    <img alt="" src={favicon} className="menu-favicon d-inline-block align-top"/>
                    {' '}
                    <span title={t('project.title')}>{t('project.titleShort')}</span>
                </NavbarBrand>

                <Navbar.Toggle aria-controls="main-navbar-collapse"/>
                <HeaderAuthenticatedSection/>
            </Container>
        </Navbar>
    );
}

function HeaderAuthenticatedSection() {
    const currentUser = useCurrentUser();
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const history = useHistory();

    const logoutClicked = (event) => {
        event.preventDefault();
        userService.logout()
            .then(() => {
                dispatch(resetCurrentUser());
                history.push('/');
            });
    }

    if (!currentUser) {
        return null;
    }

    const menu = menuService.getMenu();

    const renderDropdownItem = (menuElement) => {
        return (
            <NavDropdown.Item key={menuElement.id} as={Link} to={menuElement.url}>
                {t(menuElement.title)}
            </NavDropdown.Item>
        );
    };

    const renderDropdown = (menuElement) => {
        return (
            <NavDropdown title={t(menuElement.title)} key={menuElement.id}>
                {menuElement.subMenu
                    .filter(subMenuElement => subMenuElement.isVisible(currentUser))
                    .map(subMenuElement => renderDropdownItem(subMenuElement))}
            </NavDropdown>
        );
    };

    const renderTopLevelMenuElement = (menuElement) => {
        if (menuElement.isDropdown) {
            return renderDropdown(menuElement);
        }
        return (
            <Nav.Link key={menuElement.id} as={NavLink} to={menuElement.url}>{t(menuElement.title)}</Nav.Link>
        );
    }

    return (
        <Navbar.Collapse id="main-navbar-collapse">
            <Nav className="me-auto">
                {menu.filter(menuElement => menuElement.isVisible(currentUser))
                    .map(menuElement => renderTopLevelMenuElement(menuElement))}
            </Nav>

            <Nav>
                <NavDropdown title={<UsernameWithAvatar user={currentUser}/>}>
                    <NavDropdown.Item as={Link} to="/secure/change-password">
                        {t('menu.changePassword')}
                    </NavDropdown.Item>

                    <NavDropdown.Item onClick={(event) => logoutClicked(event)}>
                        {t('menu.logout')}
                    </NavDropdown.Item>
                </NavDropdown>
            </Nav>
        </Navbar.Collapse>
    );
}

function UsernameWithAvatar({user}) {
    const url = user.avatar || 'https://www.gravatar.com/avatar/00000000000000000000000000000000';
    const style = {
        width: '30px',
        height: '30px'
    };
    return <div className="d-flex float-start">
        <img className="border border-1 border-white me-2" alt="avatar" src={url} style={style}/>
        <div>{user.fullName}</div>
    </div>;
}

export default Header;
