import i18next from 'i18next';
import Backend from "i18next-http-backend";
import {initReactI18next} from "react-i18next";

const translationLoader = i18next
    .use(Backend)
    .use(initReactI18next)
    .init({
        lng: 'tr',
        fallbackLng: 'tr',
        debug: false,
        interpolation: {
            escapeValue: false // react already safes from xss
        },
        backend: {
            loadPath: '/i18n/{{lng}}.json'
        },
        react: {
            useSuspense: false
        }
    });

export default translationLoader;
