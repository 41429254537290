import useCurrentUser from "@/hooks/useCurrentUser";
import {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {Card, Form} from "react-bootstrap";
import sprintCommentService from "@services/sprint-comment.service";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {maxLength, required} from "@utils/validations";
import SubmitButton from "@components/Button/SubmitButton/SubmitButton";
import {noop} from "lodash";

// Custom hook for this page
function useAddCommentPrivilege(teamId) {
    const currentUser = useCurrentUser();
    return useMemo(() => {
        if (!currentUser) {
            return false;
        }

        if (currentUser.hasPrivilege('PRIV_ADD_COMMENT_ALL_SPRINTS')) {
            return true;
        }

        if (currentUser.hasPrivilege('PRIV_ADD_COMMENT_TEAM_SPRINTS')) {
            if (currentUser.isInvolvedInTeam(teamId)) {
                return true;
            }
        }

        return false;
    }, [currentUser, teamId]);
}

const validationSchema = Yup.object().shape({
    content: Yup.string()
        .required(required)
        .max(2000, maxLength)
});

function CommentForm({onSendComment, developers}) {
    const {t} = useTranslation();

    const {
        register,
        handleSubmit,
        formState: {isValid, isValidating, isSubmitting}
    } = useForm({
        mode: 'all',
        resolver: yupResolver(validationSchema),
        defaultValues: {
            content: '',
            developerId: null
        }
    });

    return (
        <form onSubmit={handleSubmit(onSendComment)} autoComplete="off">
            <Form.Group>
                <textarea {...register('content')} rows={5} className="form-control"/>
            </Form.Group>

            <Form.Group>
                <Form.Label>{t('label.relatedDeveloper')}</Form.Label>
                <select {...register('developerId')} className="form-select">
                    <option value=""/>
                    {developers.map((developer, index) => (
                        <option key={index} value={developer.id}>{developer.fullName}</option>
                    ))}
                </select>
            </Form.Group>

            <Form.Group className="mt-2">
                <SubmitButton text="button.send"
                              isValid={isValid}
                              isValidating={isValidating}
                              isSubmitting={isSubmitting}/>
            </Form.Group>
        </form>
    );
}

function AddSprintComment({sprintId, onCommentAdded = noop}) {
    const {t} = useTranslation();
    const [formKey, setFormKey] = useState(1);
    const [developers, setDevelopers] = useState([]);

    useEffect(() => {
        sprintCommentService.getReferableDevelopers(sprintId)
            .then(developers => setDevelopers(developers));
    }, [sprintId]);

    const clearForm = () => {
        setFormKey(formKey + 1);
    }

    const sendComment = (comment) => {
        return sprintCommentService.addSprintComment(sprintId, comment)
            .then(() => {
                clearForm();
                onCommentAdded();
            });
    };

    return (
        <Card className="rounded-0">
            <Card.Header>
                <h6>{t('title.makeComment')}</h6>
            </Card.Header>

            <Card.Body>
                <CommentForm key={formKey}
                             developers={developers}
                             onSendComment={(comment) => sendComment(comment)}/>
            </Card.Body>
        </Card>
    );
}

function AddSprintCommentWithPrivilege({teamId, ...props}) {
    const canAddComment = useAddCommentPrivilege(teamId);
    if (!canAddComment) {
        return null;
    }
    return (
        <AddSprintComment teamId={teamId} {...props} />
    );
}

export default AddSprintCommentWithPrivilege;
