import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {DeleteButton} from "@components/Button";
import {formatDateTime} from "@utils/date-time.utils";
import React from "react";
import {noop} from "lodash";
import EllipsisTextWithContinueReading
    from "@components/EllipsisTextWithContinueReading/EllipsisTextWithContinueReading";

const postItStyle = {
    backgroundColor: '#f9f16d',
    borderColor: '#dcd677',
    fontFamily: 'Tahoma, Georgia, serif'
};

function KudosItem({kudosItem, onDeleteClick = noop}) {
    const {id, authorUser, developer, text, timestamp, canBeDeleted = false} = kudosItem;
    const {name: authorName} = authorUser;
    const {name: developerName} = developer;

    return (
        <div className="card border-1" style={postItStyle} data-id={id}>
            <div className="card-header d-flex">
                <div className="flex-grow-1">
                    <FontAwesomeIcon className="pe-2" color="#0d81fd" icon="fa-solid fa-thumbtack"/>
                    <b>{authorName}</b>
                    <FontAwesomeIcon className="ps-2 pe-2" color="#0d81fd" icon="fa-solid fa-angles-right"/>
                    <b>{developerName}</b>
                </div>

                {canBeDeleted && (
                    <div>
                        <DeleteButton title="title.delete" onClick={() => onDeleteClick(kudosItem)}/>
                    </div>
                )}
            </div>
            <div className="card-body">
                <EllipsisTextWithContinueReading text={text} maxLength={150}/>
            </div>
            <div className="card-footer">
                <div className="text-end small">{formatDateTime(timestamp)}</div>
            </div>
        </div>
    );
}

export default KudosItem;
