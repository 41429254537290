import useUniqueId from "@/hooks/useUniqueId";
import {get} from "lodash";
import {FormInvalidFeedback} from "@components/Form";

function InputTextareaFormGroup({
                                    register,
                                    label,
                                    errors,
                                    fieldName,
                                    rows = 3,
                                    placeholder = '',
                                    description = '',
                                    maxLength = 524288
                                }) {
    const componentId = useUniqueId();
    const error = get(errors, fieldName, null);

    return (
        <div className="form-group mb-3">
            <label htmlFor={componentId}>{label}</label>
            <textarea
                rows={rows}
                id={componentId}
                {...register(fieldName)}
                maxLength={maxLength}
                placeholder={placeholder}
                className={`form-control ${error ? 'is-invalid' : ''}`}
            />
            {!!description && (
                <small className="text-muted">{description}</small>
            )}
            <FormInvalidFeedback errors={error?.message}/>
        </div>
    );
}

export default InputTextareaFormGroup;
