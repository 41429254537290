import axios from "axios";

const sprintSnapshotService = (function () {
    function getSprintSnapshotsBySprintId(sprintId) {
        return axios.get(`/api/sprints/${sprintId}/sprint-snapshots`);
    }

    function deleteSprintSnapshotsById(sprintSnapshotId) {
        return axios.delete(`/api/sprint-snapshots/${sprintSnapshotId}`);
    }

    function deleteDeveloperFromSprint(sprintId, developerId) {
        return axios.delete(`/api/sprints/${sprintId}/developers/${developerId}`);
    }

    function forceCreateNewSnapshot(sprintId) {
        return axios.post(`/api/sprints/${sprintId}/snapshots/force-new`);
    }

    function forceFinishSprint(sprintId) {
        return axios.post(`/api/sprints/${sprintId}/snapshots/force-finish`);
    }

    return {
        getSprintSnapshotsBySprintId,
        deleteSprintSnapshotsById,
        deleteDeveloperFromSprint,
        forceCreateNewSnapshot,
        forceFinishSprint
    }
}());

export default sprintSnapshotService;
