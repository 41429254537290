import {Link} from "react-router-dom";
import React, {forwardRef} from "react";
import {Button} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function getButtonProps({onClick, url, type = 'button'}) {
    if (onClick) {
        return {type: type, onClick: onClick};
    }
    if (url) {
        return {as: Link, to: url};
    }
    return {type};
}

const IconButton = forwardRef(({icon, variant, url, onClick, size, text, disabled, type, ...props}, ref) => {
    icon = icon || "question-circle";
    variant = variant || "primary";
    size = size || 'sm';
    const buttonProps = {...getButtonProps({url, onClick, type}), variant, size};

    return (
        <Button {...props} {...buttonProps} ref={ref} disabled={disabled}>
            <FontAwesomeIcon icon={icon}/>{text && (<>&nbsp;{text}</>)}
        </Button>
    );
});

export default IconButton;
