import {useTranslation} from "react-i18next";
import React, {useState} from "react";
import SimpleCard from "@components/SimpleCard/SimpleCard";
import {Form} from "react-bootstrap";
import {IconButton} from "@components/Button";
import {noop} from "lodash";

function CategorySelector({categories = [], onCategorySelected = noop, selectedCategoryIds = []}) {
    const {t} = useTranslation();
    const [selectedCategoryId, setSelectedCategoryId] = useState('');
    const selectableCategories = categories.filter(category => !selectedCategoryIds.includes(category.id));

    const handleChange = (e) => {
        const {value = ''} = e.target;
        setSelectedCategoryId(value ? parseInt(value) : '');
    };

    const handleClick = () => {
        if (!selectedCategoryId) {
            return;
        }
        const selectedCategory = categories.find(category => category.id === selectedCategoryId);
        if (!selectedCategory) {
            return;
        }
        onCategorySelected(selectedCategory);
        setSelectedCategoryId('');
    };

    return (
        <SimpleCard title={t('title.addAssessmentFormTemplateCategory')}>
            <Form.Group className="mb-3">
                <Form.Label>{t('label.category')}</Form.Label>
                <Form.Select onChange={handleChange} value={selectedCategoryId}>
                    <option value="">{t('msg.pleaseSelect')}</option>
                    {selectableCategories.map((category, index) => (
                        <option key={index} value={category.id}>{category.name}</option>
                    ))}
                </Form.Select>
            </Form.Group>

            <IconButton icon="fas fa-plus" text={t('button.add')} onClick={handleClick}/>
        </SimpleCard>
    );
}

export default CategorySelector;
