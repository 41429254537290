import secureRoute from "@components/HOC/secureRoute";
import Breadcrumb from "@components/Breadcrumb/Breadcrumb";
import React from "react";
import {Button, Col, Form, Row} from "react-bootstrap";
import {FormInvalidFeedback} from "@components/Form";
import ButtonBar from "@components/ButtonBar/ButtonBar";
import {useTranslation} from "react-i18next";
import {withFormik} from "formik";
import userService from "@services/user.service";
import toastService from "@services/toast.service";
import * as Yup from "yup";
import {maxLength, minLength, required} from "@utils/validations";
import BackendErrors from "@components/BackendErrors/BackendErrors";

function ChangePasswordForm(props) {
    const {t} = useTranslation();
    const {
        values,
        touched,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        isValidating,
    } = props;

    const submitDisabled = isSubmitting || isValidating;

    return <form onSubmit={handleSubmit} autoComplete="off">
        <BackendErrors error={errors.backendErrors}/>

        <Row>
            <Col md={6}>
                <Form.Group className="mb-3" controlId="currentPassword">
                    <Form.Label>{t('label.currentPassword')}</Form.Label>
                    <Form.Control type="password" name="currentPassword"
                                  value={values.currentPassword} maxLength={255}
                                  onChange={handleChange} onBlur={handleBlur}
                                  isInvalid={errors.currentPassword && touched.currentPassword}/>
                    <FormInvalidFeedback errors={errors.currentPassword}/>
                </Form.Group>

                <Form.Group className="mb-3" controlId="newPassword">
                    <Form.Label>{t('label.newPassword')}</Form.Label>
                    <Form.Control type="password" name="newPassword"
                                  value={values.newPassword} maxLength={255}
                                  onChange={handleChange} onBlur={handleBlur}
                                  isInvalid={errors.newPassword && touched.newPassword}/>
                    <FormInvalidFeedback errors={errors.newPassword}/>
                </Form.Group>

                <Form.Group className="mb-3" controlId="newPasswordAgain">
                    <Form.Label>{t('label.newPasswordAgain')}</Form.Label>
                    <Form.Control type="password" name="newPasswordAgain"
                                  value={values.email} maxLength={255}
                                  onChange={handleChange} onBlur={handleBlur}
                                  isInvalid={errors.newPasswordAgain && touched.newPasswordAgain}/>
                    <FormInvalidFeedback errors={errors.newPasswordAgain}/>
                </Form.Group>
            </Col>
        </Row>

        <ButtonBar>
            <Button variant="primary" type="submit" disabled={submitDisabled}>{t('button.changePassword')}</Button>
        </ButtonBar>
    </form>;
}

const ChangePasswordFormWithFormik = withFormik({
    mapPropsToValues: () => {
        return {
            currentPassword: '',
            newPassword: '',
            newPasswordAgain: '',
        };
    },

    validationSchema: props => props.validationSchema,
    validateOnBlur: false,

    handleSubmit: (values, {props, setErrors}) => {
        return userService.changePassword(values)
            .then(() => toastService.success('msg.passwordChanged'))
            .then(() => props.history.push('/secure/home'))
            .catch(errors => {
                setErrors({backendErrors: errors});
                return Promise.reject(errors);
            });
    },

    displayName: 'ChangePasswordForm',
})(ChangePasswordForm);

function WithValidationSchema(props) {
    const validationSchema = Yup.object().shape({
        currentPassword: Yup.string()
            .required(required),
        newPassword: Yup.string()
            .required(required)
            .min(8, minLength)
            .max(255, maxLength),
        newPasswordAgain: Yup.string()
            .required(required)
            .min(8, minLength)
            .max(255, maxLength)
            .oneOf([Yup.ref('newPassword'), null], {code: 'error.bothPasswordsMustBeSame'}),
    });

    return <ChangePasswordFormWithFormik {...props} validationSchema={validationSchema}/>
}

function ChangePasswordPage(props) {
    return <>
        <Breadcrumb title="title.changePassword"/>

        <WithValidationSchema {...props} />
    </>;
}

export default secureRoute(ChangePasswordPage);
