import {useTranslation} from "react-i18next";
import resolveData from "@components/HOC/resolveData";
import sprintService from "@services/sprint.service";
import secureRoute from "@components/HOC/secureRoute";
import SimpleCard from "@components/SimpleCard/SimpleCard";
import Breadcrumb from "@components/Breadcrumb/Breadcrumb";
import React, {useMemo} from "react";
import TableHolder from "@components/TableHolder/TableHolder";
import {Table} from "react-bootstrap";
import {formatDateTimeWithSeconds} from "@utils/date-time.utils";
import SprintDeveloperAssessmentResults
    from "@views/Sprints/SprintDeveloperAssessmentResults/SprintDeveloperAssessmentResults";
import remountOnLocationChange from "@components/HOC/remountOnLocationChange";

function SprintDeveloperSnapshotTable({snapshots, developer}) {
    const {t} = useTranslation();

    return (
        <SimpleCard title="title.developerSprintSnapshots">
            <TableHolder>
                <Table striped bordered>
                    <thead>
                    <tr>
                        <th>{t('label.developerSnapshotId')}</th>
                        <th>{t('label.developer')}</th>
                        <th>{t('label.snapshotTimestamp')}</th>
                        <th>{t('label.totalIssues')}</th>
                        <th>{t('label.completedIssues')}</th>
                        <th>{t('label.targetStoryPoints')}</th>
                        <th>{t('label.completedStoryPoints')}</th>
                        <th>{t('label.completedStories')}</th>
                        <th>{t('label.completedTasks')}</th>
                        <th>{t('label.completedImprovements')}</th>
                        <th>{t('label.completedBugs')}</th>
                        <th>{t('label.completedDocuments')}</th>
                        <th>{t('label.completedFeatures')}</th>
                        <th>{t('label.blamedTotalBugs')}</th>
                        <th>{t('label.blamedTestBugs')}</th>
                        <th>{t('label.blamedProdBugs')}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {snapshots.map(snapshot => (
                        <tr key={snapshot.id}>
                            <td>{snapshot.id}</td>
                            <td className="nowrap">{developer.fullName}</td>
                            <td className="nowrap">{formatDateTimeWithSeconds(snapshot.snapshotTimestamp)}</td>
                            <td>{snapshot.totalIssues}</td>
                            <td>{snapshot.completedIssues}</td>
                            <td>{snapshot.totalStoryPoints}</td>
                            <td>{snapshot.completedStoryPoints}</td>
                            <td>{snapshot.completedStories}</td>
                            <td>{snapshot.completedTasks}</td>
                            <td>{snapshot.completedImprovements}</td>
                            <td>{snapshot.completedBugs}</td>
                            <td>{snapshot.completedDocuments}</td>
                            <td>{snapshot.completedFeatures}</td>
                            <td>{snapshot.blamedTotalBugs}</td>
                            <td>{snapshot.blamedTestBugs}</td>
                            <td>{snapshot.blamedProdBugs}</td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            </TableHolder>
        </SimpleCard>
    );
}

function SprintDeveloperDetail(props) {
    const {board, sprint, developer, team, snapshots, currentUser} = props;

    const showAssessments = useMemo(() => {
        if (currentUser.hasPrivilege('PRIV_VIEW_ALL_ASSESSMENT_RESULTS')) {
            return true;
        }
        if (currentUser.hasPrivilege('PRIV_VIEW_TEAM_ASSESSMENT_RESULTS')) {
            if (currentUser.isTeamMember(team.id) || currentUser.isTeamLeader(team.id)) {
                return true;
            }
        }
        return false;
    }, [currentUser, team]);

    return (
        <>
            <Breadcrumb title="title.developer" titleParams={developer}
                        items={[
                            {title: 'menu.boards', path: '/secure/boards'},
                            {title: 'title.boardDetail', titleParams: board, path: `/secure/boards/${board.id}`},
                            {title: 'title.sprintDetail', titleParams: sprint, path: `/secure/sprints/${sprint.id}`}
                        ]}/>

            <SprintDeveloperSnapshotTable developer={developer} snapshots={snapshots}/>

            {showAssessments && (
                <SprintDeveloperAssessmentResults sprint={sprint} board={board} developer={developer}/>
            )}
        </>
    );
}

const SprintDeveloperDetailWithResolvedData = resolveData(SprintDeveloperDetail, (props) => {
    const {sprintId, developerId} = props.match.params;
    const sprintDeveloperDetailPromise = sprintService.getSprintDeveloperDetail(sprintId, developerId);
    return {
        board: sprintDeveloperDetailPromise.then(data => data.board),
        sprint: sprintDeveloperDetailPromise.then(data => data.sprint),
        developer: sprintDeveloperDetailPromise.then(data => data.developer),
        snapshots: sprintDeveloperDetailPromise.then(data => data.snapshots),
        team: sprintDeveloperDetailPromise.then(data => data.team || {}),
    }
});

export default secureRoute(remountOnLocationChange(SprintDeveloperDetailWithResolvedData));
