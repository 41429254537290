import React from "react";
import Loading from "@components/Loading/Loading";
import BackendErrors from "@components/BackendErrors/BackendErrors";

export default function resolveData(WrappedComponent, promiseBagCreator) {
    return class DataResolver extends React.Component {

        constructor(props) {
            super(props);
            this.mounted = false;
            this.state = {
                values: {},
                allResolved: false,
                failed: false,
                backendErrors: null
            };
        }

        componentDidMount() {
            this.mounted = true;
            // promiseBag: {[key: string]: Promise}
            const promiseBag = promiseBagCreator(this.props);
            const promiseKeys = Object.keys(promiseBag);
            const values = {};
            const promises = promiseKeys
                .map(key => Promise.resolve(promiseBag[key])
                    .then(data => values[key] = data));
            Promise.all(promises)
                .then(() => {
                    if (this.mounted) {
                        this.setState({values, allResolved: true});
                    }
                })
                .catch((e) => {
                    if (this.mounted) {
                        this.setState({failed: true, backendErrors: e});
                    }
                });
        }

        componentWillUnmount() {
            this.mounted = false;
        }

        render() {
            if (this.state.failed) {
                // TODO: A better component ;)
                return (
                    <>
                        <h1>Cannot load data!</h1>

                        <BackendErrors error={this.state.backendErrors}/>
                    </>
                );
            }

            if (!this.state.allResolved) {
                return <Loading/>;
            }

            const props = {...this.props, ...this.state.values};
            return <WrappedComponent {...props} />
        }
    }
}
