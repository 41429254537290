import React from "react";
import {Button, Card, Form} from "react-bootstrap";
import {FormInvalidFeedback} from "@components/Form";
import {withFormik} from "formik";
import * as Yup from "yup";
import {required} from "@utils/validations";
import teamService from "@services/team.service";
import toastService from "@services/toast.service";
import developerService from "@services/developer.service";
import {withTranslation} from "react-i18next";
import resolveData from "@components/HOC/resolveData";

class AddDeveloperToTeamForm extends React.Component {
    render() {
        const allDevelopers = this.props.developers;
        const existingDevelopers = this.props.team.developers;
        const selectableDevelopers = allDevelopers.filter(developer => {
            return 0 > existingDevelopers.findIndex(existingDeveloper => existingDeveloper.id === developer.id);
        });

        const {
            t,
            values,
            touched,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
        } = this.props;

        return (
            <Card className="mb-3">
                <Card.Header>
                    <h4>{t('title.addDeveloperToTeam')}</h4>
                </Card.Header>
                <Card.Body>
                    <form onSubmit={handleSubmit}>
                        <Form.Group className="mb-3" controlId="developerId">
                            <Form.Label>{t('label.developer')}</Form.Label>
                            <Form.Select name="developerId" value={values.developerId}
                                         onChange={handleChange} onBlur={handleBlur}
                                         isInvalid={errors.developerId && touched.developerId}>
                                <option value="">{t('msg.pleaseSelect')}</option>
                                {selectableDevelopers.map((developer, index) => (
                                    <option key={index} value={developer.id}>{developer.fullName}</option>
                                ))}
                            </Form.Select>
                            <FormInvalidFeedback errors={errors.developerId}/>
                            <Form.Text>{t('msg.selectDeveloperToAdd')}</Form.Text>
                        </Form.Group>

                        <Button variant="primary" type="submit" disabled={isSubmitting}>{t('button.add')}</Button>
                    </form>
                </Card.Body>
            </Card>
        );
    }
}

const AddDeveloperToTeamFormWithTranslate = withTranslation()(AddDeveloperToTeamForm);

const AddDeveloperToTeamFormWithFormik = withFormik({
    mapPropsToValues: () => ({
        developerId: ''
    }),

    validationSchema: Yup.object().shape({
        developerId: Yup.number().required(required)
    }),

    handleSubmit: (values, {setSubmitting, props}) => {
        teamService.addDeveloperToTeam(props.team.id, values.developerId)
            .then((developer) => {
                toastService.success('msg.developerAddedToTeam', developer);
                props.onDeveloperAdded(developer);
                values.developerId = '';
                return developer;
            })
            .finally(() => setSubmitting(false));
    },

    displayName: 'AddDeveloperToTeamForm',
})(AddDeveloperToTeamFormWithTranslate);

export default resolveData(AddDeveloperToTeamFormWithFormik, () => {
    return {
        developers: developerService.getAllDevelopers()
    }
});
