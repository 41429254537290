import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button} from "react-bootstrap";
import React from "react";
import {useTranslation} from "react-i18next";

function SubmitButton({isValid, isValidating, isSubmitting = false, text, variant = 'primary'}) {
    const {t} = useTranslation();
    const disabled = !isValid || isValidating || isSubmitting;
    const spinner = isValidating || isSubmitting;
    return (
        <Button variant={variant} type="submit" disabled={disabled}>
            {!!spinner && (
                <FontAwesomeIcon className="me-2" icon="fas fa-spinner" spin/>
            )}
            {t(text)}
        </Button>
    );
}

export default SubmitButton;
