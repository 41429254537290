import {useLocation} from "react-router-dom";
import {useMemo} from "react";

/**
 * @returns {URLSearchParams}
 */
function useQuery() {
    const {search} = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
}

export default useQuery;
