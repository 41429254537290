import useUniqueId from "@/hooks/useUniqueId";
import {useTranslation} from "react-i18next";
import React from "react";
import {get} from "lodash";
import {FormInvalidFeedback} from "@components/Form";

function SelectFormGroup({
                             register,
                             label,
                             errors,
                             fieldName,
                             description = '',
                             elements = [],
                             bindLabel = '',
                             bindValue = ''
                         }) {
    const componentId = useUniqueId();
    const {t} = useTranslation();
    const error = get(errors, fieldName, null);

    return (
        <div className="form-group mb-3">
            <label htmlFor={componentId}>{label}</label>

            <select id={componentId}
                    {...register(fieldName)}
                    className={`form-select ${error ? 'is-invalid' : ''}`}>
                <option value="">{t('msg.pleaseSelect')}</option>

                {elements.map((element, index) => (
                    <option key={index} value={bindValue ? element[bindValue] : element}>
                        {bindLabel ? element[bindLabel] : element}
                    </option>
                ))}
            </select>

            <FormInvalidFeedback errors={error?.message}/>

            {!!description && (
                <small className="text-muted">{description}</small>
            )}
        </div>
    );
}

export default SelectFormGroup;
