export class AdminUser {
    constructor(json) {
        Object.assign(this, json);
        this.privileges = this.privileges || [];
        this.roles = this.roles || [];
        this.teamIds = this.teamIds || [];
        this.leadedTeamIds = this.leadedTeamIds || [];
    }

    hasPrivilege(privilege) {
        return this.privileges.includes(privilege);
    }

    hasAnyPrivilege(privileges) {
        for (const privilege of privileges) {
            if (this.hasPrivilege(privilege)) {
                return true;
            }
        }
        return false;
    }

    isTeamMember(teamId) {
        return teamId && this.teamIds.includes(teamId);
    }

    isTeamLeader(teamId) {
        return teamId && this.leadedTeamIds.includes(teamId);
    }

    isInvolvedInTeam(teamId) {
        return this.isTeamMember(teamId) || this.isTeamLeader(teamId);
    }
}
