import React from "react";
import Breadcrumb from "@components/Breadcrumb/Breadcrumb";
import secureRoute from "@components/HOC/secureRoute";
import {withTranslation} from "react-i18next";
import resolveData from "@components/HOC/resolveData";
import boardService from "@services/board.service";
import sprintService from "@services/sprint.service";
import {Card, Table} from "react-bootstrap";
import TableHolder from "@components/TableHolder/TableHolder";
import {DetailButton} from "@components/Button";
import {formatDate} from "@utils/date-time.utils";
import remountOnLocationChange from "@components/HOC/remountOnLocationChange";

function BoardDetail(props) {
    const {board, sprints, t} = props;
    return (
        <>
            <Breadcrumb title="title.boardDetail" titleParams={board}
                        items={[{title: 'menu.boards', path: '/secure/boards'}]}/>

            <Card className="mb-3">
                <Card.Header>
                    <h2>{t('title.sprints')}</h2>
                </Card.Header>

                <Card.Body>
                    <TableHolder>
                        <Table striped bordered>
                            <thead>
                            <tr>
                                <th/>
                                <th>{t('label.sprintId')}</th>
                                <th>{t('label.sprintName')}</th>
                                <th>{t('label.isArchived')}</th>
                                <th>{t('label.startDate')}</th>
                                <th>{t('label.endDate')}</th>
                            </tr>
                            </thead>
                            <tbody>
                            {sprints.map(sprint => (
                                <tr key={sprint.id}>
                                    <td>
                                        <DetailButton title="tooltip.sprintDetail"
                                                      url={`/secure/sprints/${sprint.id}`}/>
                                    </td>
                                    <td>{sprint.id}</td>
                                    <td>{sprint.name}</td>
                                    <td>{sprint.archived ? t('label.yes') : t('label.no')}</td>
                                    <td>{formatDate(sprint.startDate)}</td>
                                    <td>{formatDate(sprint.endDate)}</td>
                                </tr>
                            ))}
                            </tbody>
                        </Table>
                    </TableHolder>
                </Card.Body>
            </Card>
        </>
    );
}

const BoardDetailWithTranslation = withTranslation()(BoardDetail);

const BoardDetailWithResolvedData = resolveData(BoardDetailWithTranslation, (props) => {
    const boardId = props.match.params.boardId;
    return {
        board: boardService.getBoard(boardId),
        sprints: sprintService.getSprintsByBoardId(boardId)
    }
});

export default secureRoute(remountOnLocationChange(BoardDetailWithResolvedData));
