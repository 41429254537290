const id = {value: 0};

function navLink(title, url, privileges = null) {
    return {
        id: (++id.value),
        title,
        url,
        privileges,
        isDropdown: false,
        isVisible: (currentUser) => {
            if (privileges && privileges.length) {
                if (currentUser) {
                    return currentUser.hasAnyPrivilege(privileges);
                }
                return false;
            }
            return true;
        }
    };
}

function navDropdown(title, subMenu = []) {
    return {
        id: (++id.value),
        title,
        subMenu,
        isDropdown: true,
        isVisible: (currentUser) => {
            for (let i = 0; i < subMenu.length; i++) {
                const subMenuItem = subMenu[i];
                if (subMenuItem.isVisible(currentUser)) {
                    return true;
                }
            }
            return false;
        }
    };
}

const menu = [
    navLink('menu.home', '/secure/home'),
    navDropdown('menu.metrics', [
        navLink('menu.developerMetrics', '/secure/developer/metrics', ['PRIV_VIEW_DEV_METRICS']),
        navLink('menu.developerTargets', '/secure/developer/targets', ['PRIV_EDIT_DEV_TARGETS']),
        navLink('menu.teamMetrics', '/secure/team/metrics', ['PRIV_VIEW_TEAM_METRICS']),
        navLink('menu.teamTargets', '/secure/team/targets', ['PRIV_EDIT_TEAM_TARGETS'])
    ]),
    navLink('menu.teams', '/secure/teams', ['PRIV_VIEW_TEAMS', 'PRIV_EDIT_TEAMS']),
    navLink('menu.boards', '/secure/boards', ['PRIV_VIEW_METRICS']),
    navDropdown('menu.import', [
        navLink('menu.importYoutrackUsers', '/secure/imports/youtrack-users', ['PRIV_IMPORTS']),
        navLink('menu.developerAssessments', '/secure/imports/sprint-assessments', ['PRIV_IMPORTS']),
    ]),
    navDropdown('menu.settings', [
        navLink('menu.users', '/secure/users', ['PRIV_USER_MANAGEMENT']),
        navLink('menu.developerAliases', '/secure/developer-aliases', ['PRIV_SETTINGS']),
        navLink('menu.assessmentCategories', '/secure/assessment-categories', ['PRIV_SETTINGS']),
        navLink('menu.assessmentFormTemplates', '/secure/assessment-form-templates', [
            'PRIV_VIEW_ASSESSMENT_FORM_TEMPLATE', 'PRIV_EDIT_ASSESSMENT_FORM_TEMPLATE',
            'PRIV_VIEW_TEAM_ASSESSMENT_FORM_TEMPLATE', 'PRIV_EDIT_TEAM_ASSESSMENT_FORM_TEMPLATE'
        ]),
    ]),
    navLink('menu.kudos', '/secure/kudos', ['PRIV_VIEW_KUDOS']),
    navLink('menu.bonus', '/secure/bonuses', ['PRIV_BONUS']),
];

const menuService = {};

menuService.getMenu = () => {
    return menu;
};

export default menuService;
