import secureRoute from "@components/HOC/secureRoute";
import {useTranslation} from "react-i18next";
import SimpleCard from "@components/SimpleCard/SimpleCard";
import Breadcrumb from "@components/Breadcrumb/Breadcrumb";
import {withFormik} from "formik";
import * as Yup from "yup";
import {required, validJson} from "@utils/validations";
import toastService from "@services/toast.service";
import {Button, Form, InputGroup} from "react-bootstrap";
import {FormInvalidFeedback} from "@components/Form";
import React, {useEffect, useState} from "react";
import resolveData from "@components/HOC/resolveData";
import boardService from "@services/board.service";
import sprintService from "@services/sprint.service";
import importService from "@services/import.service";
import remountOnLocationChange from "@components/HOC/remountOnLocationChange";

function SprintAssessmentImport(props) {
    const {t} = useTranslation();
    const {
        values,
        touched,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        boards,
        sprints: initialSprints = [],
    } = props;
    const [sprints, setSprints] = useState(initialSprints);
    const {boardId} = values;
    const fileInput = React.createRef();

    useEffect(() => {
        setSprints([]);
        if (!boardId) {
            return;
        }
        sprintService.getSprintsByBoardId(boardId)
            .then(sprints => setSprints(sprints || []));
    }, [boardId]);

    return (
        <>
            <Breadcrumb title="title.importSprintAssessment"/>

            <SimpleCard>
                <form onSubmit={handleSubmit}>
                    <Form.Group className="mb-3" controlId="boardId">
                        <Form.Label>{t('label.board')}</Form.Label>
                        <Form.Select name="boardId" value={boardId}
                                     onChange={handleChange} onBlur={handleBlur}
                                     isInvalid={errors.boardId && touched.boardId}>
                            <option value="">{t('msg.pleaseSelect')}</option>
                            {boards.map((board, index) => (
                                <option key={index} value={board.id}>{board.name}</option>
                            ))}
                        </Form.Select>
                        <FormInvalidFeedback errors={errors.boardId}/>
                    </Form.Group>

                    {!!sprints.length && (
                        <>
                            <Form.Group className="mb-3" controlId="sprintId">
                                <Form.Label>{t('label.sprint')}</Form.Label>
                                <Form.Select name="sprintId" value={values.sprintId}
                                             onChange={handleChange} onBlur={handleBlur}
                                             isInvalid={errors.sprintId && touched.sprintId}>
                                    <option value="">{t('msg.pleaseSelect')}</option>
                                    {sprints.map((sprint, index) => (
                                        <option key={index} value={sprint.id}>{sprint.name}</option>
                                    ))}
                                </Form.Select>
                                <FormInvalidFeedback errors={errors.sprintId}/>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="excelFile">
                                <Form.Label>{t('label.excelFile')}</Form.Label>
                                <Form.Control type="file"
                                              style={{display: 'none'}}
                                              onChange={event => setFieldValue('excelFile', event.currentTarget.files[0])}
                                              ref={fileInput}/>

                                <InputGroup className="mb-3">
                                    <Form.Control
                                        readOnly
                                        value={values.excelFile ? values.excelFile.name || "Error" : t('msg.noFileChosen')}/>
                                    <Button type="button" variant="primary"
                                            onClick={() => fileInput.current.click()}>
                                        {t('button.chooseFile')}
                                    </Button>
                                </InputGroup>

                                <FormInvalidFeedback errors={errors.excelFile}/>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="sheetName">
                                <Form.Label>{t('label.sheetName')}</Form.Label>
                                <Form.Control name="sheetName" value={values.sheetName} maxLength={255}
                                              onChange={handleChange} onBlur={handleBlur}
                                              isInvalid={errors.sheetName && touched.sheetName}/>
                                <FormInvalidFeedback errors={errors.sheetName}/>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="categoryMappingJson">
                                <Form.Label>{t('label.categoryMappingJson')}</Form.Label>
                                <Form.Control as="textarea" name="categoryMappingJson" rows={10}
                                              style={{fontFamily: 'monospace'}}
                                              value={values.categoryMappingJson}
                                              onChange={handleChange} onBlur={handleBlur}
                                              isInvalid={errors.categoryMappingJson && touched.categoryMappingJson}/>
                                <FormInvalidFeedback errors={errors.categoryMappingJson}/>
                            </Form.Group>
                        </>
                    )}

                    <Button variant="primary" type="submit" disabled={isSubmitting}>{t('button.import')}</Button>
                </form>
            </SimpleCard>
        </>
    );
}

const SprintAssessmentImportWithFormik = withFormik({
    mapPropsToValues: (props) => ({
        boardId: props.boardId || '',
        sprintId: props.sprintId || '',
        excelFile: null,
        sheetName: localStorage.getItem('assessmentImportDefaultSheetName') || '',
        categoryMappingJson: localStorage.getItem('assessmentImportCustomCategoryMapping') || ''
    }),

    validationSchema: Yup.object().shape({
        boardId: Yup.number()
            .required(required),
        sprintId: Yup.number()
            .required(required),
        excelFile: Yup.mixed()
            .required(required),
        sheetName: Yup.string()
            .required(required),
        categoryMappingJson: Yup.string()
            .test('categoryMappingJson', null, validJson('categoryMappingJson'))
    }),

    handleSubmit: (values, {setSubmitting, props}) => {
        importService.importSprintAssessmentForm(values)
            .then(() => {
                localStorage.setItem('assessmentImportCustomCategoryMapping', values.categoryMappingJson || '');
                localStorage.setItem('assessmentImportDefaultSheetName', values.sheetName || '');
                toastService.success('msg.assessmentImported');
                props.history.push(`/secure/sprints/${values.sprintId}`);
            })
            .finally(() => setSubmitting(false));
    },

    displayName: 'SprintAssessmentImportForm',
})(SprintAssessmentImport);

export default secureRoute(remountOnLocationChange(resolveData(SprintAssessmentImportWithFormik, (props) => {
    const queryStringParams = new URLSearchParams(props.location.search);
    const sprintId = queryStringParams.get('sprintId') || '';
    const boardId = queryStringParams.get('boardId') || '';
    return {
        boards: boardService.getAllBoards(),
        boardId: Promise.resolve(boardId),
        sprints: boardId ? sprintService.getSprintsByBoardId(boardId) : Promise.resolve([]),
        sprintId: Promise.resolve(sprintId)
    };
})));
