import {useForm} from "react-hook-form";
import {getPastMonthsWithYear} from "@utils/date-time.utils";
import {useTranslation} from "react-i18next";
import {noop} from "lodash";
import SimpleCard from "@components/SimpleCard/SimpleCard";
import {Form} from "react-bootstrap";
import {IconButton} from "@components/Button";

const monthsWithYear = getPastMonthsWithYear();

function KudosSearchForm({users, developers, onSubmit = noop, defaultValues, currentValues}) {
    const {t} = useTranslation();

    const {
        register,
        handleSubmit,
        reset,
    } = useForm({
        mode: 'all',
        currentValues,
    });

    const clearForm = () => {
        const newValues = {...defaultValues};
        reset(newValues);
        onSubmit(newValues);
    };

    return (
        <SimpleCard>
            <form onSubmit={handleSubmit(onSubmit)} className="row row-cols-lg-auto g-3 align-items-end">
                <div className="col-12">
                    <Form.Label>{t('label.date')}:</Form.Label>
                    <select {...register('period')} className="form-select">
                        {monthsWithYear.map((monthWithYear => (
                            <option value={monthWithYear.value} key={monthWithYear.value}>
                                {t(`monthNames.${monthWithYear.month}`)}
                                &nbsp;-&nbsp;
                                {monthWithYear.year}
                            </option>
                        )))}
                    </select>
                </div>

                <div className="col-12">
                    <Form.Label>{t('label.kudoTaker')}:</Form.Label>
                    <select {...register('relatedDeveloperId')} className="form-select">
                        <option value="">{t('label.all')}</option>
                        {developers.map((developer => (
                            <option value={developer.id} key={developer.id}>
                                {developer.name}
                            </option>
                        )))}
                    </select>
                </div>

                <div className="col-12">
                    <Form.Label>{t('label.kudoGiver')}:</Form.Label>
                    <select {...register('authorUserId')} className="form-select">
                        <option value="">{t('label.all')}</option>
                        {users.map((user => (
                            <option value={user.id} key={user.id}>
                                {user.name}
                            </option>
                        )))}
                    </select>
                </div>

                <div className="col-12">
                    <IconButton icon="fa-solid fa-magnifying-glass"
                                type="submit"
                                size="md"
                                text={t('button.search')}/>
                    &nbsp;
                    <IconButton icon="fa-solid fa-broom"
                                size="md"
                                onClick={() => clearForm()}
                                text={t('button.clear')}/>
                </div>
            </form>
        </SimpleCard>
    );
}

export default KudosSearchForm;
