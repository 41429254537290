import {useSelector} from "react-redux";
import {AdminUser} from "@models/AdminUser.model";
import {useMemo} from "react";

const useCurrentUser = () => {
    const currentUser = useSelector(state => state.currentUser.user);
    return useMemo(() => {
        if (currentUser) {
            return new AdminUser(currentUser);
        }
        return null;
    }, [currentUser])
};

export default useCurrentUser;
