import resolveData from "@components/HOC/resolveData";
import secureRoute from "@components/HOC/secureRoute";
import userService from "@services/user.service";
import {useTranslation} from "react-i18next";
import Breadcrumb from "@components/Breadcrumb/Breadcrumb";
import React from "react";
import {Card, Col, Row} from "react-bootstrap";
import EnumMsg from "@components/EnumMsg/EnumMsg";
import {formatDateTimeWithSeconds} from "@utils/date-time.utils";
import remountOnLocationChange from "@components/HOC/remountOnLocationChange";

function UserDetail(props) {
    const {t} = useTranslation();
    const {user} = props;

    return (
        <>
            <Breadcrumb title="title.userDetail" items={[{title: 'title.users', path: '/secure/users'}]}/>

            <Card className="mb-3">
                <Card.Body>
                    <Row>
                        <Col md={6}>
                            <Row as="dl">
                                <Col sm={5} as="dt">{t('label.userId')}</Col>
                                <Col sm={7} as="dd">{user.id}</Col>

                                <Col sm={5} as="dt">{t('label.firstName')}</Col>
                                <Col sm={7} as="dd">{user.firstName}</Col>

                                <Col sm={5} as="dt">{t('label.lastName')}</Col>
                                <Col sm={7} as="dd">{user.lastName}</Col>

                                <Col sm={5} as="dt">{t('label.email')}</Col>
                                <Col sm={7} as="dd">{user.email}</Col>

                                <Col sm={5} as="dt">{t('label.status')}</Col>
                                <Col sm={7} as="dd"><EnumMsg enumName="UserStatus" value={user.status}/></Col>

                                <Col sm={5} as="dt">{t('label.roles')}</Col>
                                <Col sm={7} as="dd">{user.roles.map(role => role.name).join(', ')}</Col>
                            </Row>
                        </Col>
                        <Col md={6}>
                            <Row as="dl">
                                <Col sm={5} as="dt">{t('label.wrongPasswordCount')}</Col>
                                <Col sm={7} as="dd">{user.wrongPasswordCount}</Col>

                                <Col sm={5} as="dt">{t('label.wrongPasswordDate')}</Col>
                                <Col sm={7} as="dd">{formatDateTimeWithSeconds(user.wrongPasswordDate) || '-'}</Col>

                                <Col sm={5} as="dt">{t('label.lastLoginDate')}</Col>
                                <Col sm={7} as="dd">{formatDateTimeWithSeconds(user.lastLoginDate) || '-'}</Col>

                                <Col sm={5} as="dt">{t('label.lastPasswordUpdateDate')}</Col>
                                <Col sm={7}
                                     as="dd">{formatDateTimeWithSeconds(user.lastPasswordUpdateDate) || '-'}</Col>

                                <Col sm={5} as="dt">{t('label.createDate')}</Col>
                                <Col sm={7} as="dd">{formatDateTimeWithSeconds(user.createDate) || '-'}</Col>

                                <Col sm={5} as="dt">{t('label.updateDate')}</Col>
                                <Col sm={7} as="dd">{formatDateTimeWithSeconds(user.updateDate) || '-'}</Col>
                            </Row>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </>
    );
}

export default secureRoute(remountOnLocationChange(resolveData(UserDetail, (props) => {
    const {userId} = props.match.params;
    return {
        user: userService.getUser(userId)
    };
})));
