import sprintCommentService from "@services/sprint-comment.service";
import PaginatedCommentList from "@components/Comment/PaginatedCommentList";
import {useEffect, useMemo, useState} from "react";
import AddSprintComment from "@views/Sprints/SprintComments/AddSprintComment";
import {useTranslation} from "react-i18next";
import useCurrentUser from "@/hooks/useCurrentUser";
import toastService from "@services/toast.service";

const maxComments = 5;

const emptySearchResult = {
    content: [],
    total: 0,
    page: 0,
    max: maxComments
};

function isCommentDeletable(comment, currentUser) {
    if (!currentUser) {
        return false;
    }
    if (currentUser.hasPrivilege('PRIV_DELETE_COMMENT_ALL_SPRINTS')) {
        return true;
    }
    if (currentUser.hasPrivilege('PRIV_DELETE_COMMENT_TEAM_SPRINTS')) {
        return currentUser.isInvolvedInTeam(comment.team?.id);
    }
    if (currentUser.hasPrivilege('PRIV_DELETE_OWN_SPRINT_COMMENT')) {
        return currentUser.id === comment.authorUser?.id;
    }
    return false;
}

function SprintComments({sprintId, teamId}) {
    const [commentsSearchResult, setCommentsSearchResult] = useState(emptySearchResult);
    const [page, setPage] = useState(0);
    const [forceReloadId, setForceReloadId] = useState(0);
    const {t} = useTranslation();
    const currentUser = useCurrentUser();

    useEffect(() => {
        sprintCommentService.getSprintComments(sprintId, page, maxComments)
            .then(searchResult => {
                searchResult.content
                    .forEach(comment => comment.canBeDeleted = isCommentDeletable(comment, currentUser));
                setCommentsSearchResult(searchResult);
                setPage(searchResult.page);
            });
    }, [page, sprintId, forceReloadId, currentUser]);

    const refreshComments = () => {
        setPage(0);
        setForceReloadId(forceReloadId + 1);
    };

    const deleteComment = (comment) => {
        return sprintCommentService.deleteSprintComment(sprintId, comment.id)
            .then(() => {
                toastService.success('msg.sprintCommentDeleted');
                refreshComments();
            });
    };

    return (
        <div className="card rounded-0">
            <div className="card-header">
                <h5>{t('title.sprintComments')}</h5>
            </div>
            <div className="card-body">
                <PaginatedCommentList comments={commentsSearchResult}
                                      page={page}
                                      onDeleteClicked={comment => deleteComment(comment)}
                                      onPageChange={newPage => setPage(newPage)}/>

                <AddSprintComment sprintId={sprintId} teamId={teamId}
                                  onCommentAdded={() => refreshComments()}/>
            </div>
        </div>
    );
}

function useSprintCommentListPrivilege(teamId) {
    const currentUser = useCurrentUser();
    return useMemo(() => {
        if (!currentUser) {
            return false;
        }
        if (currentUser.hasPrivilege('PRIV_VIEW_COMMENT_ALL_SPRINTS')) {
            return true;
        }
        if (currentUser.hasPrivilege('PRIV_VIEW_COMMENT_TEAM_SPRINTS')) {
            return currentUser.isInvolvedInTeam(teamId);
        }
        return false;
    }, [currentUser, teamId]);
}

function SprintCommentsWithPrivilege({teamId, ...props}) {
    const canViewComments = useSprintCommentListPrivilege(teamId);
    if (!canViewComments) {
        return null;
    }

    return (
        <SprintComments teamId={teamId} {...props}/>
    );
}

export default SprintCommentsWithPrivilege;
