import {Table} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import TableHolder from "@components/TableHolder/TableHolder";
import {DeleteButton, DetailButton} from "@components/Button";
import SimpleCard from "@components/SimpleCard/SimpleCard";
import useCurrentUser from "@/hooks/useCurrentUser";
import {useMemo} from "react";
import sprintSnapshotService from "@services/sprint-snapshot.service";
import usePrivilege from "@/hooks/usePrivilege";

function SprintSnapshotDeveloperStatsTableHeader({showActionColumn = false}) {
    const {t} = useTranslation();

    return (
        <thead>
        <tr>
            {!!showActionColumn && <th/>}
            <th className="nowrap">{t('label.developer')}</th>
            <th className="nowrap">{t('label.totalIssues')}</th>
            <th className="nowrap">{t('label.completedIssues')}</th>
            <th className="nowrap">{t('label.targetStoryPoints')}</th>
            <th className="nowrap">{t('label.completedStoryPoints')}</th>
            <th className="nowrap">{t('label.successRate')}</th>
            <th className="nowrap">{t('label.teamRate')}</th>
            <th className="nowrap">{t('label.completedStories')}</th>
            <th className="nowrap">{t('label.completedTasks')}</th>
            <th className="nowrap">{t('label.completedImprovements')}</th>
            <th className="nowrap">{t('label.completedBugs')}</th>
            <th className="nowrap">{t('label.completedDocuments')}</th>
            <th className="nowrap">{t('label.completedFeatures')}</th>
            <th className="nowrap">{t('label.blamedTotalBugs')}</th>
            <th className="nowrap">{t('label.blamedTestBugs')}</th>
            <th className="nowrap">{t('label.blamedProdBugs')}</th>
        </tr>
        </thead>
    );
}

function SprintSnapshotDeveloperStatsRow({stat, sprint, detailEnabled, deleteEnabled, showActionColumn = false}) {
    const url = `/secure/sprints/${sprint.id}/developers/${stat.developer.id}`;

    const handleDeleteClick = () => {
        sprintSnapshotService.deleteDeveloperFromSprint(sprint.id, stat.developer.id)
            .then(() => window.location.reload());
    };

    const renderDetailButton = () => {
        if (detailEnabled) {
            return <DetailButton title="tooltip.developerSprintDetail" url={url}/>;
        }
        return <DetailButton disabled={true}/>;
    };

    const renderDeleteButton = () => {
        if (deleteEnabled) {
            return <DeleteButton onClick={() => handleDeleteClick()} title="tooltip.deleteDeveloperFromSprint"/>
        }
        return null;
    };

    return (
        <tr>
            {!!showActionColumn && (
                <td className="nowrap">
                    {renderDetailButton()}
                    &nbsp;
                    {renderDeleteButton()}
                </td>
            )}
            <td className="nowrap">{stat.developer.fullName}</td>
            <td className="nowrap">{stat.totalIssues}</td>
            <td className="nowrap">{stat.completedIssues}</td>
            <td className="nowrap">{stat.totalStoryPoints}</td>
            <td className="nowrap">{stat.completedStoryPoints}</td>
            <td className="nowrap">% {stat.successRate}</td>
            <td className="nowrap">% {stat.teamRate}</td>
            <td className="nowrap">{stat.completedStories}</td>
            <td className="nowrap">{stat.completedTasks}</td>
            <td className="nowrap">{stat.completedImprovements}</td>
            <td className="nowrap">{stat.completedBugs}</td>
            <td className="nowrap">{stat.completedDocuments}</td>
            <td className="nowrap">{stat.completedFeatures}</td>
            <td className="nowrap">{stat.blamedTotalBugs}</td>
            <td className="nowrap">{stat.blamedTestBugs}</td>
            <td className="nowrap">{stat.blamedProdBugs}</td>
        </tr>
    );
}

function getDeveloperIdsAllowedToViewDetail(stats, user, team) {
    const showDetailFor = new Set();
    if (!user) {
        return showDetailFor;
    }
    const addStat = (stat) => showDetailFor.add(stat.developer.id);
    const addAll = () => stats.forEach(addStat);

    if (user.hasPrivilege('PRIV_VIEW_ALL_DEVELOPER_SNAPSHOTS')) {
        addAll();
        return showDetailFor;
    }
    if (user.hasPrivilege('PRIV_VIEW_TEAM_DEVELOPER_SNAPSHOTS')) {
        if (user.isTeamLeader(team.id) || user.isTeamMember(team.id)) {
            addAll();
        }
        return showDetailFor;
    }
    if (user.hasPrivilege('PRIV_VIEW_OWN_DEVELOPER_SNAPSHOTS')) {
        const visibleStat = stats.find(stat => stat.developer.id === user.developerId);
        if (visibleStat) {
            addStat(visibleStat);
        }
    }
    return showDetailFor;
}

function SprintSnapshotDeveloperStats(props) {
    const currentUser = useCurrentUser();
    const {stats = [], sprint, team} = props;
    const canDeleteDeveloperSnapshots = usePrivilege('PRIV_DELETE_SPRINT_SNAPSHOTS');

    const developerIdSetToViewDetail = useMemo(() => {
        return getDeveloperIdsAllowedToViewDetail(stats, currentUser, team);
    }, [stats, team, currentUser]);

    return (
        <SimpleCard title="title.developerStats">
            <TableHolder>
                <Table bordered striped>
                    <SprintSnapshotDeveloperStatsTableHeader
                        showActionColumn={developerIdSetToViewDetail.size || canDeleteDeveloperSnapshots}/>

                    <tbody>
                    {stats.map((stat, index) => (
                        <SprintSnapshotDeveloperStatsRow stat={stat}
                                                         sprint={sprint}
                                                         showActionColumn={developerIdSetToViewDetail.size || canDeleteDeveloperSnapshots}
                                                         detailEnabled={developerIdSetToViewDetail.has(stat.developer.id)}
                                                         deleteEnabled={canDeleteDeveloperSnapshots}
                                                         key={index}/>
                    ))}
                    </tbody>
                </Table>
            </TableHolder>
        </SimpleCard>
    );
}

export default SprintSnapshotDeveloperStats;
