import withPrivilege from "@components/HOC/withPrivilege";
import resolveData from "@components/HOC/resolveData";
import sprintAssessmentService from "@services/sprint-assessment.service";
import {Card, ListGroup, ListGroupItem} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {formatDate} from "@utils/date-time.utils";
import useCurrentUser from "@/hooks/useCurrentUser";
import {useMemo, useState} from "react";
import {TooltipIconButton} from "@components/Button";
import ConfirmDialog from "@components/ConfirmDialog/ConfirmDialog";
import toastService from "@services/toast.service";

function IncompleteAssessmentFormDeveloperList({incompleteAssessmentForm}) {
    const {t} = useTranslation();

    const incompleteDevelopers = incompleteAssessmentForm.incompleteDevelopers || [];
    if (!incompleteDevelopers.length) {
        return null;
    }

    return (
        <div>
            <b>{t('label.developersWeWaitForAnswer')}:</b>
            {incompleteDevelopers.map((developer, index) => (
                <span key={index}>
                    <b className="text-danger">{developer.fullName}</b>
                    {index < incompleteDevelopers.length - 1 && (
                        <span>, </span>
                    )}
                </span>
            ))}
        </div>
    );
}

// Custom hook for this page
function useCloseAssessmentFormPrivilege(assessmentForm) {
    const currentUser = useCurrentUser();
    return useMemo(() => {
        if (!currentUser) {
            return false;
        }

        if (currentUser.hasPrivilege('PRIV_CLOSE_ALL_ASSESSMENT_FORMS')) {
            return true;
        }

        if (currentUser.hasPrivilege('PRIV_CLOSE_TEAM_ASSESSMENT_FORMS')) {
            if (currentUser.isInvolvedInTeam(assessmentForm.teamId)) {
                return true;
            }
        }

        return false;
    }, [currentUser, assessmentForm]);
}

function CloseFormButton({assessmentForm, handleClose}) {
    const [showConfirm, setShowConfirm] = useState(false);

    const handleConfirm = () => {
        setShowConfirm(false);
        sprintAssessmentService.closeAssessmentForm(assessmentForm.id)
            .then(() => {
                toastService.success('msg.assessmentFormClosed');
                return handleClose(assessmentForm.id);
            });
    };

    return (
        <>
            <TooltipIconButton icon="flag-checkered"
                               title="tooltip.closeAssessmentForm"
                               onClick={() => setShowConfirm(true)}/>
            <ConfirmDialog show={showConfirm}
                           message="msg.areYouSureCloseSprintAssessmentForm"
                           messageParams={assessmentForm}
                           handleConfirm={() => handleConfirm()}
                           handleCancel={() => setShowConfirm(false)}/>
        </>
    );
}

function IncompleteAssessmentFormListItem({incompleteAssessmentForm, handleClose}) {
    const {t} = useTranslation();
    const canCloseForm = useCloseAssessmentFormPrivilege(incompleteAssessmentForm);

    return (
        <ListGroupItem>
            <div className="d-flex small">
                {canCloseForm && (
                    <div className="flex-shrink-1 me-2">
                        <CloseFormButton assessmentForm={incompleteAssessmentForm}
                                         handleClose={handleClose}/>
                    </div>
                )}
                <div className="flex-fill">
                    <div>
                        <b>{t('label.sprint')}:</b>
                        <b className="text-primary">{incompleteAssessmentForm.sprintName}</b>
                    </div>
                    <div>
                        <b>{t('label.sprintStartDate')}:</b>
                        {formatDate(incompleteAssessmentForm.sprintStartDate)}
                    </div>
                    <div>
                        <b>{t('label.sprintEndDate')}:</b>
                        {formatDate(incompleteAssessmentForm.sprintEndDate)}
                    </div>
                    <IncompleteAssessmentFormDeveloperList incompleteAssessmentForm={incompleteAssessmentForm}/>
                </div>
            </div>
        </ListGroupItem>
    );
}

function IncompleteAssessmentForms({incompleteAssessmentForms: initialForms}) {
    const {t} = useTranslation();
    const [forms, setForms] = useState(initialForms || []);

    const handleClose = (assessmentFormId) => {
        const newForms = forms.filter(form => form.id !== assessmentFormId);
        setForms(newForms);
    };

    return (
        <Card className="rounded-0">
            <Card.Header>
                <h5>{t('title.incompleteAssessmentForm')}</h5>
            </Card.Header>
            {!forms.length && (
                <Card.Body>{t('msg.noIncompleteAssessmentForms')}</Card.Body>
            )}

            {!!forms.length && (
                <ListGroup variant="flush">
                    {forms.map((incompleteAssessmentForm) => (
                        <IncompleteAssessmentFormListItem incompleteAssessmentForm={incompleteAssessmentForm}
                                                          handleClose={handleClose}
                                                          key={incompleteAssessmentForm.id}/>
                    ))}
                </ListGroup>
            )}
        </Card>
    );
}

export default withPrivilege(resolveData(IncompleteAssessmentForms, () => {
    return {
        incompleteAssessmentForms: sprintAssessmentService.getIncompleteAssessmentForms(),
    };
}), ['PRIV_VIEW_TEAM_INCOMPLETE_ASSESSMENT_FORMS', 'PRIV_VIEW_ALL_INCOMPLETE_ASSESSMENT_FORMS']);
