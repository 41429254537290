import {addToast, store} from "@store";

const toastService = {};

toastService.danger = (message, messageArgs = {}) => {
    store.dispatch(addToast({title: 'title.error', message, messageArgs, type: 'danger'}));
};

toastService.success = (message, messageArgs = {}) => {
    store.dispatch(addToast({title: 'title.success', message, messageArgs, type: 'success'}));
};

export default toastService;
