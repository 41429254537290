import resolveData from "@components/HOC/resolveData";
import {Col, Row, Table} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import sprintAssessmentService from "@services/sprint-assessment.service";
import TableHolder from "@components/TableHolder/TableHolder";
import SimpleCard from "@components/SimpleCard/SimpleCard";
import AssessmentDoesNotExist from "@views/Sprints/AssessmentDoesNotExist/AssessmentDoesNotExist";
import AssessmentIconList from "@components/AssessmentIconList/AssessmentIconList";
import {useMemo} from "react";

function compareDescendingByTeamRate(a, b) {
    return (b.teamRate || 0) - (a.teamRate || 0);
}

function BestsOfCategory({assessment = {}, category}) {
    const {developerEvaluations = []} = assessment;
    const rows = useMemo(() => {
        return developerEvaluations
            .map(developerEvaluation => {
                const {categoryVotes = {}, developer} = developerEvaluation;
                const {teamRate = 0} = categoryVotes[category.id] || {};
                return {developer, teamRate};
            })
            .sort(compareDescendingByTeamRate);
    }, [developerEvaluations, category]);

    return (
        <SimpleCard>
            <h6 className="border-bottom pb-2">{category.name}</h6>

            <Table striped size="sm" className="mb-0">
                <tbody>
                {rows.map((row, index) => (
                    <tr key={index}>
                        <td>{row.developer.fullName}</td>
                        <td>% {row.teamRate}</td>
                    </tr>
                ))}
                </tbody>
            </Table>
        </SimpleCard>
    );
}

function BestsOfCategories({assessment = {}}) {
    const {categories = []} = assessment;
    return (
        <Row>
            {categories.map((category, index) => (
                <Col md={4} key={index}>
                    <BestsOfCategory assessment={assessment} category={category}/>
                </Col>
            ))}
        </Row>
    );
}

function AssessmentNotFound({sprint, board, team}) {
    return (
        <SimpleCard title="title.developerAssessment">
            <AssessmentDoesNotExist sprint={sprint} board={board} team={team}/>
        </SimpleCard>
    );
}

function SprintAssessmentResultsTable({categories = [], developerEvaluations = [], voteTypes = []}) {
    const {t} = useTranslation();
    return (
        <TableHolder>
            <Table striped size="sm">
                <thead>
                <tr>
                    <th/>
                    <th>{t('label.teamRate')}</th>
                    {categories.map(category => (
                        <th key={category.id}>{category.name}</th>
                    ))}
                </tr>
                </thead>
                <tbody>
                {developerEvaluations.map(({developer, categoryVotes, teamRate}) => (
                    <tr key={developer.id}>
                        <td className="nowrap">{developer.fullName}</td>
                        <td>% {teamRate}</td>
                        {categories.map(category => (
                            <td className="nowrap" key={category.id}>
                                <AssessmentIconList voteTypes={voteTypes}
                                                    voteTypeCountMap={categoryVotes[category.id]?.voteTypeCountMap}/>
                            </td>
                        ))}
                    </tr>
                ))}
                </tbody>
            </Table>
        </TableHolder>
    );
}

function SprintAssessmentResults(props) {
    const {t} = useTranslation();
    const {assessment, sprint, board, team} = props;
    const {
        categories,
        developerEvaluations = [],
        voteTypes = []
    } = assessment;

    if (developerEvaluations.length <= 0) {
        return <AssessmentNotFound sprint={sprint} board={board} team={team}/>;
    }

    return (
        <SimpleCard title="title.developerAssessment">
            <h3>{t('title.votesDevelopersGot')}</h3>
            <SprintAssessmentResultsTable categories={categories} voteTypes={voteTypes}
                                          developerEvaluations={developerEvaluations}/>

            <hr/>

            <h3>{t('title.categoryBasedTeamContribution')}</h3>
            <BestsOfCategories assessment={assessment}/>
        </SimpleCard>
    );
}

const SprintDeveloperAssessmentWithResolvedData = resolveData(SprintAssessmentResults, (props) => {
    return {
        assessment: sprintAssessmentService.getSprintAssessmentSummary(props.sprint.id)
    };
});

export default SprintDeveloperAssessmentWithResolvedData;
