import React, {useEffect, useMemo, useState} from "react";
import Breadcrumb from "@components/Breadcrumb/Breadcrumb";
import secureRoute from "@components/HOC/secureRoute";
import resolveData from "@components/HOC/resolveData";
import developerMetricService from "@services/developer-metric.service";
import teamService from "@services/team.service";
import remountOnLocationChange from "@components/HOC/remountOnLocationChange";
import {Alert, Col, Form, Row} from "react-bootstrap";
import FormInvalidFeedback from "@components/Form/FormInvalidFeedback/FormInvalidFeedback";
import {useTranslation} from "react-i18next";
import {withFormik} from "formik";
import * as Yup from "yup";
import {atLeastOneCheckbox, required} from "@utils/validations";
import ButtonBar from "@components/ButtonBar/ButtonBar";
import Select from 'react-select';
import {IconButton} from "@components/Button";
import useCurrentUser from "@/hooks/useCurrentUser";
import DeveloperSprintResults from "@views/Metrics/DeveloperMetrics/DeveloperSprintResults/DeveloperSprintResults";
import developerService from "@services/developer.service";
import {sortBy} from "@utils/array-utils";

function UseShowDevelopers(currentUser, teamId) {
    return useMemo(() => {
        if (currentUser && currentUser.hasPrivilege('PRIV_VIEW_ALL_DEV_METRICS')) {
            return true;
        }
        if (currentUser && currentUser.hasPrivilege('PRIV_VIEW_TEAM_DEV_METRICS')) {
            return teamId && currentUser.isTeamLeader(teamId);
        }
        return false;
    }, [currentUser, teamId]);
}

function getDeveloperId(currentUser, selectedDeveloperId) {
    if (currentUser && currentUser.hasPrivilege('PRIV_VIEW_ALL_DEV_METRICS')) {
        return selectedDeveloperId;
    }
    if (currentUser && currentUser.hasPrivilege('PRIV_VIEW_TEAM_DEV_METRICS')) {
        return selectedDeveloperId;
    }
    return currentUser.developerId;
}

function DeveloperMetricDetail(props) {
    const {t} = useTranslation();
    const [selectedTeam, setSelectedTeam] = useState(null);
    const [developers, setDevelopers] = useState([]);
    const [selectedDeveloper, setSelectedDeveloper] = useState(null);
    const [selectedSprints, setSelectedSprints] = useState([]);
    const [sprints, setSprints] = useState([]);
    const [showResult, setShowResult] = useState(false);
    const [developerMetrics, setDeveloperMetrics] = useState([]);
    const [alertMessage, setAlertMessage] = useState(null);
    const [quarters, setQuarters] = useState([]);
    const [selectedQuarter, setSelectedQuarter] = useState(null);
    const currentUser = useCurrentUser();
    const {
        teams,
        touched,
        errors,
        handleBlur
    } = props;

    let devTeams = teams.map((team) => ({label: team.name, value: team.id}));
    let showDevelopers = UseShowDevelopers(currentUser, selectedTeam);

    useEffect(() => {
        setDevelopers([]);
        if (!selectedTeam || !showDevelopers) {
            return;
        }
        developerService.getTeamDevelopersByTeamId(selectedTeam)
            .then(developers => {
                let devs = developers.map(dev => ({label: dev.fullName, value: dev.id}))
                let sortedDevs = [{label: t('msg.pleaseSelect'), value: ''}, ...devs];
                setDevelopers(sortedDevs);
            });
    }, [selectedTeam]);

    useEffect(() => {
        setQuarters([]);
        let developerId = showDevelopers ? selectedDeveloper : currentUser.developerId;
        if (!developerId) {
            return;
        }
        developerMetricService.getMetricDevelopersQuartersById(developerId)
            .then(quarters => {
                let availableQuarters = quarters
                    .filter(q => q !== null)
                    .map(quarter => {
                    let tokens = quarter.split(':');
                    let quarterLabel = tokens[0] + tokens[1].split('-')[2];
                    return ({label: quarterLabel, value: quarter});
                })

                availableQuarters.sort(sortBy('label', false, (q) => q));
                let devQuarters = [{label: t('msg.pleaseSelect'), value: ''}, ...availableQuarters];
                setQuarters(devQuarters);
            });
    }, [selectedDeveloper]);

    useEffect(() => {
        setSprints([]);
        if (!selectedTeam) {
            return;
        }
        developerMetricService.getSprintDeveloperDetailByTeam(selectedTeam)
            .then(sprints => {
                let devSprints = sprints.map(sprint => ({label: sprint.name, value: sprint.id}))
                setSprints(devSprints);
            });
    }, [selectedTeam]);

    const handleSprintsChange = (sprints) => {
        let sprintIds = sprints.map(sprint => (sprint.value));
        setSelectedSprints(sprintIds);
    };

    const handleSubmit = () => {
        if (!selectedTeam) {
            return;
        }
        if (selectedSprints && selectedSprints.length > 0 && selectedQuarter) {
            setAlertMessage(t('msg.selectASprintOrQuarter'));
            return;
        }
        setAlertMessage("");
        if (selectedQuarter) {
            let developerId = getDeveloperId(currentUser, selectedDeveloper);
            return developerMetricService.calculateSprintsMetricsDetailOfTheDeveloperByQuarter(selectedTeam, selectedQuarter, developerId)
                .then(result => setDeveloperMetrics(result.developerMetrics))
                .then(() => setShowResult(true))
                .then(() => setAlertMessage(""))
                .catch(errors => {
                    return Promise.reject(errors);
                });
        }
        if (selectedSprints) {
            selectedSprints.map(id => id === false);
            let developerId = getDeveloperId(currentUser, selectedDeveloper);
            return developerMetricService.calculateSprintsMetricsDetailOfTheDeveloperBySprints(selectedTeam, selectedSprints, developerId)
                .then(result => setDeveloperMetrics(result.developerMetrics))
                .then(() => setShowResult(true))
                .then(() => setAlertMessage(""))
                .catch(errors => {
                    return Promise.reject(errors);
                });
        }
    }

    return (
        <>
            <Breadcrumb title="title.developerMetricDetail"
                        items={[
                            {title: 'menu.metrics', path: '/secure/developer/metrics'}
                        ]}/>

            <form autoComplete="off">
                {alertMessage && alertMessage !== '' &&
                <Alert variant="warning">
                    {alertMessage}
                </Alert>
                }
                <Row>
                    <Col md={3}>
                        <Form.Group className="mb-4" controlId="teamId">
                            <Form.Label>{t('label.team')}</Form.Label>
                            <Select
                                id="teamId"
                                name="teamId"
                                className="mt-4 col-md-12 col-offset-6"
                                defaultMenuIsOpen={false}
                                defaultValue={{label: t('msg.pleaseSelect'), value: ''}}
                                onBlur={handleBlur}
                                onChange={team => setSelectedTeam(team.value)}
                                options={devTeams}
                                isInvalid={errors.teamId && touched.teamId}
                            />
                            {selectedTeam === '' &&
                            <Form.Text>{t('msg.selectAScrumTeam')}</Form.Text>
                            }
                            <FormInvalidFeedback errors={errors.teamId}/>
                        </Form.Group>
                    </Col>
                    {showDevelopers &&
                    <Col md={3}>
                        <Form.Group className="mb-4" controlId="developerId">
                            <Form.Label>{t('label.developers')}</Form.Label>
                            <Select
                                id="developerId"
                                name="developerId"
                                className="mt-4 col-md-9 col-offset-4"
                                options={developers}
                                onBlur={handleBlur}
                                onChange={(developer) => setSelectedDeveloper(developer.value)}
                                defaultValue={{label: t('msg.pleaseSelect'), value: ''}}
                                closeMenuOnSelect={true}
                                isInvalid={errors.developerId && touched.developerId}
                            />
                            {!selectedDeveloper &&
                            <Form.Text>{t('msg.selectADeveloper')}</Form.Text>
                            }
                            <FormInvalidFeedback errors={errors.developerIds}/>
                        </Form.Group>
                    </Col>
                    }
                    <Col md={2}>
                        <Form.Group className="mb-4" controlId="quarters">
                            <Form.Label>{t('label.quarters')}</Form.Label>
                            <Select
                                id="quarters"
                                name="quarters"
                                className="mt-4 col-md-9 col-offset-6"
                                options={quarters}
                                onBlur={handleBlur}
                                onChange={(quarter) => setSelectedQuarter(quarter.value)}
                                defaultValue={{label: t('msg.pleaseSelect'), value: ''}}
                                closeMenuOnSelect={true}
                            />
                        </Form.Group>
                    </Col>

                    <Col md={showDevelopers ? 4 : 6}>
                        <Form.Group className="mb-6" controlId="sprintIds">
                            <Form.Label>{t('label.sprints')}</Form.Label>
                            <Select
                                id="sprintIds"
                                name="sprintIds"
                                className="mt-4 col-md-12 col-offset-4"
                                isMulti
                                options={sprints}
                                onBlur={handleBlur}
                                onChange={handleSprintsChange}
                                closeMenuOnSelect={false}
                            />
                            {selectedSprints && selectedSprints.length <= 0 &&
                            <Form.Text>{t('msg.selectASprint')}</Form.Text>
                            }
                            <FormInvalidFeedback errors={errors.sprintIds}/>
                        </Form.Group>
                    </Col>
                </Row>
                <ButtonBar>
                    <IconButton icon="fa fa-line-chart" text={t('button.createMyGraph')} onClick={handleSubmit}
                                disabled={(!selectedTeam || !selectedQuarter) && (!selectedTeam || selectedSprints.length <= 0)}/>
                </ButtonBar>
            </form>
            {showResult && showResult === true &&
            <DeveloperSprintResults developerMetrics={developerMetrics} selectedSprints={selectedSprints}/>
            }
        </>
    );
}

const WithFormik = withFormik({
    mapPropsToValues: () => {
        return {
            teamId: '',
            sprintIds: []
        };
    },
    validationSchema: props => props.validationSchema,
    displayName: 'DeveloperMetricDetailForm',
})(DeveloperMetricDetail);

function WithValidationSchema(props) {
    const validationSchema = Yup.object().shape({
        teamId: Yup.number()
            .required(required),
        sprintIds: Yup.object()
            .required(required)
            .test('sprintIds', null, (obj) => {
                const keys = Object.keys(obj);
                const indexOfFirstTrue = keys.findIndex(key => obj[key]);
                if (indexOfFirstTrue >= 0) {
                    return true;
                }

                return new Yup.ValidationError(
                    atLeastOneCheckbox(),
                    null,
                    'sprintIds'
                );
            })
    });
    return <WithFormik validationSchema={validationSchema} {...props} />
}

const MetricDetailWithResolvedData = resolveData(WithValidationSchema, () => {
    return {
        teams: teamService.getAllTeams()
    }
});

export default secureRoute(remountOnLocationChange(MetricDetailWithResolvedData));
