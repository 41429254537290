import React from "react";
import {useTranslation} from "react-i18next";
import {Alert, Table} from "react-bootstrap";
import Breadcrumb from "@components/Breadcrumb/Breadcrumb";
import TableHolder from "@components/TableHolder/TableHolder";
import {DetailButton} from "@components/Button";
import SimpleCard from "@components/SimpleCard/SimpleCard";
import resolveData from "@components/HOC/resolveData";
import boardService from "@services/board.service";
import secureRoute from "@components/HOC/secureRoute";

function BoardList(props) {
    const {t} = useTranslation();
    const {boards = []} = props;

    return (
        <>
            <Breadcrumb title="menu.boards"/>

            <SimpleCard>
                {!boards.length && (
                    <Alert className="mb-0" variant="info">{t('msg.noBoard')}</Alert>
                )}
                {!!boards.length && (
                    <TableHolder>
                        <Table striped bordered>
                            <thead>
                            <tr>
                                <th className="table-actions"/>
                                <th className="nowrap">{t('label.boardId')}</th>
                                <th className="nowrap">{t('label.boardName')}</th>
                                <th className="nowrap">{t('label.youtrackBoardId')}</th>
                            </tr>
                            </thead>
                            <tbody>
                            {boards.map(board =>
                                <tr key={board.id}>
                                    <td className="table-actions">
                                        <DetailButton url={'/secure/boards/' + board.id}
                                                      title="tooltip.boardDetail"/>
                                    </td>
                                    <td>{board.id}</td>
                                    <td>{board.name}</td>
                                    <td>{board.youtrackBoardId}</td>
                                </tr>
                            )}
                            </tbody>
                        </Table>
                    </TableHolder>
                )}
            </SimpleCard>
        </>
    );
}

export default secureRoute(resolveData(BoardList, () => {
    return {
        boards: boardService.getAllBoards()
    };
}));
