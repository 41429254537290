import {Col, Row} from "react-bootstrap";
import {formatDate} from "@utils/date-time.utils";
import React, {useMemo} from "react";
import {useTranslation} from "react-i18next";
import SimpleCard from "@components/SimpleCard/SimpleCard";
import {TooltipIconButton} from "@components/Button";
import usePrivilege from "@/hooks/usePrivilege";
import sprintSnapshotService from "@services/sprint-snapshot.service";

function canUserViewSnapshots(currentUser, team) {
    const hasAdminPrivilege = currentUser.hasPrivilege('PRIV_VIEW_ALL_SPRINT_SNAPSHOTS');
    if (hasAdminPrivilege) {
        return true;
    }
    const hasTeamPrivilege = currentUser.hasPrivilege('PRIV_VIEW_TEAM_SPRINT_SNAPSHOTS');
    const isMember = currentUser.isTeamMember(team.id) || currentUser.isTeamLeader(team.id);
    return !!(hasTeamPrivilege && isMember);
}

function SprintOverview({sprint, overview, currentUser}) {
    const {t} = useTranslation();
    const {team = {}} = overview;
    const canForceCreateNewSnapshot = usePrivilege('PRIV_FORCE_NEW_SPRINT_SNAPSHOT');
    const canForceFinishSnapshot = usePrivilege('PRIV_FORCE_FINISH_SPRINT');

    const canViewSnapshots = useMemo(
        () => canUserViewSnapshots(currentUser, team),
        [currentUser, team]);

    const createSnapshot = () => {
        sprintSnapshotService.forceCreateNewSnapshot(sprint.id)
            .then(() => window.location.reload());
    };

    const finishSnapshot = () => {
        sprintSnapshotService.forceFinishSprint(sprint.id)
            .then(() => window.location.reload());
    };

    return (
        <SimpleCard title="title.sprintOverview">
            <Row>
                <Col md={6}>
                    <Row as="dl">
                        <Col sm={5} as="dt">{t('label.sprintName')}</Col>
                        <Col sm={7} as="dd">{sprint.name}</Col>

                        <Col sm={5} as="dt">{t('label.sprintStatus')}</Col>
                        <Col sm={7}
                             as="dd">{sprint.archived ? t('label.archived') : t('label.ongoing')}</Col>

                        <Col sm={5} as="dt">{t('label.startDate')}</Col>
                        <Col sm={7} as="dd">{formatDate(sprint.startDate)}</Col>

                        <Col sm={5} as="dt">{t('label.endDate')}</Col>
                        <Col sm={7} as="dd">{formatDate(sprint.endDate)}</Col>
                    </Row>
                </Col>
                <Col md={6}>
                    <Row as="dl">
                        <Col sm={5} as="dt">{t('label.targetStoryPoints')}</Col>
                        <Col sm={7} as="dd">{overview.targetStoryPoints}</Col>

                        <Col sm={5} as="dt">{t('label.completedStoryPoints')}</Col>
                        <Col sm={7} as="dd">{overview.completedStoryPoints}</Col>

                        <Col sm={5} as="dt">{t('label.successRate')}</Col>
                        <Col sm={7} as="dd">% {overview.storyPointsPercentage}</Col>
                    </Row>
                </Col>
            </Row>

            <Row>
                <Col>
                    {canViewSnapshots && (
                        <TooltipIconButton icon="list" text={t('button.snapshots')}
                                           title="tooltip.viewDetailedSprintSnapshots"
                                           url={`/secure/sprints/${sprint.id}/snapshots`}/>
                    )}

                    {canViewSnapshots && canForceCreateNewSnapshot ? <>&nbsp;</> : ''}

                    {canForceCreateNewSnapshot && (
                        <TooltipIconButton icon="plus" text={t('button.createSnapshot')}
                                           title="tooltip.forceCreateNewSprintSnapshot"
                                           onClick={() => createSnapshot()}/>
                    )}
                    {canViewSnapshots && canForceCreateNewSnapshot && canForceFinishSnapshot ? <>&nbsp;</> : ''}

                    {canForceFinishSnapshot && !sprint.archived && (
                        <TooltipIconButton icon="plus" text={t('button.finishSprint')}
                                           title="tooltip.forceFinishSprint"
                                           onClick={() => finishSnapshot()}/>
                    )}
                </Col>
            </Row>
        </SimpleCard>
    );
}

export default SprintOverview;
