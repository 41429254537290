import axios from "axios";

const teamService = {};

teamService.getAllTeams = () => {
    return axios.get('/api/teams');
};

teamService.createTeam = (data) => {
    return axios.post('/api/teams', data);
};

teamService.getTeam = (teamId) => {
    return axios.get(`/api/teams/${teamId}`);
};

teamService.removeDeveloperFromTeam = (teamId, developerId) => {
    return axios.delete(`/api/teams/${teamId}/developers/${developerId}`);
};

teamService.addDeveloperToTeam = (teamId, developerId) => {
    return axios.put(`/api/teams/${teamId}/developers/${developerId}`);
};

teamService.makeDeveloperTeamLeader = (teamId, developerId) => {
    return axios.post(`/api/teams/${teamId}/leaders/${developerId}`);
};

export default teamService;
