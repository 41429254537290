import axios from "axios";

export default (function () {

    function getSprintComments(sprintId, page, max) {
        return axios.get(`/api/sprints/${sprintId}/comments?page=${page}&max=${max}`);
    }

    function getReferableDevelopers(sprintId) {
        return axios.get(`/api/sprints/${sprintId}/comments/developers`);
    }

    function addSprintComment(sprintId, data) {
        return axios.post(`/api/sprints/${sprintId}/comments`, data);
    }

    function deleteSprintComment(sprintId, commentId) {
        return axios.delete(`/api/sprints/${sprintId}/comments/${commentId}`);
    }

    return {
        getSprintComments,
        addSprintComment,
        deleteSprintComment,
        getReferableDevelopers,
    };
}());
