import axios from "axios";
import {getTrueValuedKeys} from "@utils/object-utils";

const bonusService = {};

bonusService.calculateBonus = (form) => {
    const sprintIds = getTrueValuedKeys(form.sprintIds);
    const {assessmentPercentage, storyPointsPercentage, totalBonusAmount} = form;
    const data = {sprintIds, assessmentPercentage, storyPointsPercentage, totalBonusAmount};
    return axios.post('/api/bonuses/calculate', data);
};

export default bonusService;
