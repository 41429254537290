import {Table} from "react-bootstrap";
import React, {useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import TableHolder from "@components/TableHolder/TableHolder";
import {formatDate, formatDateTime} from "@utils/date-time.utils";
import SimpleCard from "@components/SimpleCard/SimpleCard";
import Breadcrumb from "@components/Breadcrumb/Breadcrumb";
import resolveData from "@components/HOC/resolveData";
import sprintSnapshotService from "@services/sprint-snapshot.service";
import secureRoute from "@components/HOC/secureRoute";
import useCurrentUser from "@/hooks/useCurrentUser";
import {DeleteButton} from "@components/Button";

function SprintSnapshotHeaderRow() {
    const {t} = useTranslation();
    return (
        <tr>
            <th className="nowrap">{t('label.time')}</th>
            <th className="nowrap">{t('label.totalIssues')}</th>
            <th className="nowrap">{t('label.completedIssues')}</th>
            <th className="nowrap">{t('label.targetStoryPoints')}</th>
            <th className="nowrap">{t('label.completedStoryPoints')}</th>
            <th className="nowrap">{t('label.completedStories')}</th>
            <th className="nowrap">{t('label.completedTasks')}</th>
            <th className="nowrap">{t('label.completedImprovements')}</th>
            <th className="nowrap">{t('label.completedDocuments')}</th>
            <th className="nowrap">{t('label.completedFeatures')}</th>
            <th className="nowrap">{t('label.sprintName')}</th>
            <th className="nowrap">{t('label.isArchived')}</th>
            <th className="nowrap">{t('label.startDate')}</th>
            <th className="nowrap">{t('label.endDate')}</th>
        </tr>
    );
}

function SprintSnapshotRow({snapshot, canDeleteSnapshot, onSnapshotDeleted}) {
    const {t} = useTranslation();

    const deleteSnapshot = async () => {
        await sprintSnapshotService.deleteSprintSnapshotsById(snapshot.id);
        onSnapshotDeleted(snapshot);
    };

    return (
        <tr>
            <td className="nowrap">
                {!!canDeleteSnapshot && (
                    <DeleteButton className="me-2" size="sm" onClick={deleteSnapshot}/>
                )}
                {formatDateTime(snapshot.snapshotTimestamp)}
            </td>
            <td>{snapshot.totalIssues}</td>
            <td>{snapshot.completedIssues}</td>
            <td>{snapshot.totalStoryPoints}</td>
            <td>{snapshot.completedStoryPoints}</td>
            <td>{snapshot.completedStories}</td>
            <td>{snapshot.completedTasks}</td>
            <td>{snapshot.completedImprovements}</td>
            <td>{snapshot.completedDocuments}</td>
            <td>{snapshot.completedFeatures}</td>
            <td className="nowrap">{snapshot.sprintName}</td>
            <td>{snapshot.archived ? t('label.yes') : t('label.no')}</td>
            <td className="nowrap">{formatDate(snapshot.startDate)}</td>
            <td className="nowrap">{formatDate(snapshot.endDate)}</td>
        </tr>
    );
}

function useDeletePrivilege() {
    const currentUser = useCurrentUser();
    return useMemo(() => {
        if (!currentUser) {
            return false;
        }
        return !!currentUser.hasPrivilege('PRIV_DELETE_SPRINT_SNAPSHOTS');
    }, [currentUser]);
}

function SprintSnapshots(props) {
    const canDeleteSnapshot = useDeletePrivilege();
    const {snapshots: initialSnapshots = [], sprint, board} = props;
    const [snapshots, setSnapshots] = useState(initialSnapshots);

    const deleteSnapshot = (snapshotToRemove) => {
        const newSnapshots = snapshots.filter(snapshotInList => snapshotInList.id !== snapshotToRemove.id);
        setSnapshots(newSnapshots);
    };

    return (
        <>
            <Breadcrumb title="title.sprintSnapshots"
                        items={[
                            {title: 'menu.boards', path: '/secure/boards'},
                            {title: 'title.boardDetail', titleParams: board, path: `/secure/boards/${board.id}`},
                            {title: 'title.sprintDetail', titleParams: sprint, path: `/secure/sprints/${sprint.id}`},
                        ]}/>

            <SimpleCard title="title.sprintSnapshots">
                <TableHolder>
                    <Table striped bordered>
                        <thead>
                        <SprintSnapshotHeaderRow/>
                        </thead>
                        <tbody>
                        {snapshots.map(snapshot => (
                            <SprintSnapshotRow snapshot={snapshot}
                                               key={snapshot.id}
                                               canDeleteSnapshot={canDeleteSnapshot}
                                               onSnapshotDeleted={deleteSnapshot}/>
                        ))}
                        </tbody>
                    </Table>
                </TableHolder>
            </SimpleCard>
        </>
    );
}

export default secureRoute(resolveData(SprintSnapshots, ({match = {}}) => {
    const {sprintId} = match.params;
    const $sprintSnapshots = sprintSnapshotService.getSprintSnapshotsBySprintId(sprintId);
    return {
        board: $sprintSnapshots.then(result => result.board),
        sprint: $sprintSnapshots.then(result => result.sprint),
        snapshots: $sprintSnapshots.then(result => result.snapshots),
    };
}));
