import React from "react";
import {Form} from "react-bootstrap";
import {withTranslation} from "react-i18next";

class FormInvalidFeedback extends React.Component {
    render() {
        let {errors, touched, t} = this.props;

        if (!errors) {
            return null;
        }
        if (!errors.code) {
            if (errors.message) {
                errors = errors.message;
            }

            if (!errors.code) {
                return null;
            }
        }

        const style = touched === undefined ? {} : (touched ? {display: 'block'} : {display: 'none'});
        return (
            <Form.Control.Feedback type="invalid" style={style}>
                {t(errors.code, errors)}
            </Form.Control.Feedback>
        );
    }
}

export default withTranslation()(FormInvalidFeedback);
