import axios from "axios";

const developerTargetService = {};

developerTargetService.getDevelopersQuartersById = (developerId) => {
    return axios.get(`/api/developers/targets/quarters/${developerId}`);
};

developerTargetService.addTargetToDeveloper = (developerId, target, quarter) => {
    return axios.post(`/api/developers/targets/quarters`, {developerId, target, quarter});
};

developerTargetService.searchDeveloperTargets = (selectedTeam, selectedDeveloper, selectedQuarter, targetValue) => {
    return axios.get(`/api/developers/targets/search`,{
        params: {
            selectedTeam: selectedTeam,
            selectedDeveloper: selectedDeveloper,
            selectedQuarter: selectedQuarter,
            targetValue : targetValue
        }
    });
};

export default developerTargetService;
