import Breadcrumb from "@components/Breadcrumb/Breadcrumb";
import SimpleCard from "@components/SimpleCard/SimpleCard";
import TableHolder from "@components/TableHolder/TableHolder";
import {Table} from "react-bootstrap";
import {TooltipIconButton} from "@components/Button";
import secureRoute from "@components/HOC/secureRoute";
import resolveData from "@components/HOC/resolveData";
import sprintAssessmentService from "@services/sprint-assessment.service";
import {useTranslation} from "react-i18next";
import {FormProvider, useFieldArray, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {arrayMinElements, required} from "@utils/validations";
import React, {useState} from "react";
import SelectFormGroup from "@components/Form/SelectFormGroup/SelectFormGroup";
import SubmitButton from "@components/Button/SubmitButton/SubmitButton";
import ButtonBar from "@components/ButtonBar/ButtonBar";
import InputTextFormGroup from "@components/Form/InputTextFromGroup/InputTextFormGroup";
import toastService from "@services/toast.service";
import {useHistory} from "react-router-dom";

const validationSchema = Yup.object().shape({
    categoryIdToStay: Yup.number()
        .typeError(required)
        .required(required),
    categoriesToRemove: Yup.array(
        Yup.object().shape({
            id: Yup.number()
                .typeError(required)
                .required(required),
        })
    ).min(1, arrayMinElements),
    newCategoryName: Yup.string()
        .required(required)
});

const defaultValues = {
    categoryIdToStay: null,
    categoriesToRemove: [],
    newCategoryName: ''
};

function CategoryList(props) {
    const {t} = useTranslation();
    const {categories = [], title, onAddClicked, onRemoveClicked} = props;

    return (
        <SimpleCard title={title}>
            <TableHolder>
                <Table striped bordered>
                    <thead>
                    <tr>
                        <th className="table-actions"/>
                        <th className="table-actions">{t('label.categoryId')}</th>
                        <th>{t('label.categoryName')}</th>
                        <th>{t('label.categorySortOrder')}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {categories.map((category, index) => (
                        <tr key={index}>
                            <td className="table-actions">
                                {!!onAddClicked && (
                                    <TooltipIconButton icon="plus" onClick={() => onAddClicked(index)}/>
                                )}
                                {!!onRemoveClicked && (
                                    <TooltipIconButton icon="trash" onClick={() => onRemoveClicked(index)}/>
                                )}
                            </td>
                            <td className="table-actions">{category.id}</td>
                            <td>{category.name}</td>
                            <td>{category.sortOrder}</td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            </TableHolder>
        </SimpleCard>
    );
}

function AssessmentCategoryMerge(props) {
    const {t} = useTranslation();
    const {categories} = props;
    const [availableCategories, setAvailableCategories] = useState(categories);
    const history = useHistory();

    const categoryMergeForm = useForm({
        mode: 'all',
        resolver: yupResolver(validationSchema),
        defaultValues
    });

    const {
        register,
        control,
        handleSubmit,
        formState: {errors, isValid, isValidating, isSubmitting}
    } = categoryMergeForm;

    const {
        fields: categoriesToRemove,
        remove: removeFromCategoriesToRemove,
        replace: setCategoriesToRemove
    } = useFieldArray({
        control,
        name: "categoriesToRemove",
        keyName: 'htmlFieldId'
    });

    const addCategory = (index) => {
        const [categoryToAdd] = availableCategories.splice(index, 1);
        setAvailableCategories([...availableCategories]);
        setCategoriesToRemove([...categoriesToRemove, categoryToAdd]
            .sort(sprintAssessmentService.COMPARE_BY_SORT_ORDER));
    };

    const removeCategory = (index) => {
        const categoryToRemove = categoriesToRemove[index];
        removeFromCategoriesToRemove(index);
        setAvailableCategories([...availableCategories, categoryToRemove]
            .sort(sprintAssessmentService.COMPARE_BY_SORT_ORDER));
    };

    const onSubmit = (data) => {
        const {categoryIdToStay, categoriesToRemove, newCategoryName} = data;
        const categoryIdsToRemove = categoriesToRemove
            .map(category => category.id)
            .filter(id => id !== categoryIdToStay);
        const request = {categoryIdToStay, categoryIdsToRemove, newCategoryName};
        return sprintAssessmentService.mergeCategories(request)
            .then(() => {
                toastService.success('msg.categoriesMerged');
                history.push('/secure/assessment-categories');
            });
    };

    return (
        <>
            <Breadcrumb title="title.mergeAssessmentCategories"/>

            <SimpleCard>
                <FormProvider {...categoryMergeForm}>
                    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                        <InputTextFormGroup register={register}
                                            fieldName="newCategoryName"
                                            label={t('label.newCategoryNameAfterMerge')}
                                            description={t('msg.newCategoryNameAfterMerge')}
                                            errors={errors}/>

                        <SelectFormGroup register={register}
                                         fieldName="categoryIdToStay"
                                         label={t('label.categoryToStay')}
                                         errors={errors}
                                         description={t('msg.categoryToStay')}
                                         elements={categoriesToRemove}
                                         bindLabel="name"
                                         bindValue="id">
                        </SelectFormGroup>

                        <CategoryList onRemoveClicked={(index) => removeCategory(index)}
                                      categories={categoriesToRemove}
                                      title="title.categoriesToMerge"/>

                        <ButtonBar>
                            <SubmitButton text={'button.mergeCategories'}
                                          isValid={isValid}
                                          isValidating={isValidating}
                                          isSubmitting={isSubmitting}/>
                        </ButtonBar>
                    </form>
                </FormProvider>

                <hr/>

                <CategoryList onAddClicked={(index) => addCategory(index)}
                              categories={availableCategories}
                              title="title.categoriesAvailableForMerge"/>
            </SimpleCard>
        </>
    );
}

export default secureRoute(resolveData(AssessmentCategoryMerge, () => {
    return {
        categories: sprintAssessmentService.getCategories()
    };
}));
