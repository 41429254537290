import axios from "axios";

const importService = {};

importService.importYoutrackUsers = () => {
    return axios.post(`/api/youtrack-users/import`);
};

importService.importSprintAssessmentForm = (data) => {
    const formData = new FormData();
    Object.entries(data).forEach(([key, value]) => formData.set(key, value));
    return axios.post('/api/sprint-assessments/import', formData);
};

export default importService;
