import React from "react";
import {Col, Row, Toast, ToastContainer} from "react-bootstrap";
import {connect} from "react-redux";
import {hideToast, removeToast} from "@store";
import {withTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class ToastScene extends React.Component {

    handleOnClose(toast) {
        if (!toast.visible) {
            return;
        }
        this.props.hideToast(toast.id);

        setTimeout(() => {
            this.props.removeToast(toast.id);
        }, 2000);
    }

    renderToast(toast) {
        const t = this.props.t;

        return (
            <Toast key={toast.id} bg={toast.type || 'light'} show={toast.visible} animation={true}
                   onClose={() => this.handleOnClose(toast)} delay={5000} autohide>
                <button type="button" className="btn-close-white btn-close position-absolute end-0 me-1 mt-1"
                        onClick={() => this.handleOnClose(toast)}/>
                <Toast.Body>
                    <Row className="text-white">
                        <Col sm="auto" className="pe-1">
                            <FontAwesomeIcon className="fs-1" icon="exclamation-triangle"/>
                        </Col>
                        <Col className="ps-1 fs-6">
                            {t(toast.message, toast.messageArgs)}
                        </Col>
                    </Row>
                </Toast.Body>
            </Toast>
        );
    }

    renderToasts() {
        const toasts = this.props.toasts || [];
        return toasts.map((toast) => this.renderToast(toast));
    }

    render() {
        return (
            <ToastContainer position="top-end" className="position-fixed">
                {this.renderToasts()}
            </ToastContainer>
        );
    }
}

function mapStateToProps(state) {
    const {toasts} = state.toasts;
    return {toasts};
}

const mapDispatchToProps = (dispatch) => {
    return {
        removeToast: (toastId) => dispatch(removeToast({id: toastId})),
        hideToast: (toastId) => dispatch(hideToast({id: toastId})),
    }
}

const ToastSceneWithTranslate = withTranslation()(ToastScene);
export default connect(mapStateToProps, mapDispatchToProps)(ToastSceneWithTranslate);
