import axios from "axios";

const sprintService = {};

sprintService.getSprintsByBoardId = (boardId) => {
    return axios.get(`/api/boards/${boardId}/sprints`);
};

sprintService.getDetailedSprintOverview = (sprintId) => {
    return axios.get(`/api/sprints/${sprintId}`);
};

sprintService.getSprintSummaryById = (sprintId) => {
    return axios.get(`/api/sprints/${sprintId}/summary`);
};

sprintService.getSprintDeveloperDetail = (sprintId, developerId) => {
    return axios.get(`/api/sprints/${sprintId}/developers/${developerId}`);
};

export default sprintService;
