import {configureStore} from '@reduxjs/toolkit'
import {currentUserReducer, toastReducer} from "@store";

const store = configureStore({
    reducer: {
        currentUser: currentUserReducer,
        toasts: toastReducer
    },
});

export default store;
