import React from "react";
import {Breadcrumb as BsBreadcrumb, Card} from "react-bootstrap";
import {withTranslation} from "react-i18next";
import {Link} from "react-router-dom";

class Breadcrumb extends React.Component {

    prepareItem(t, item, key) {
        return (
            <BsBreadcrumb.Item key={key} linkAs={Link} linkProps={{to: item.path}}>
                {t(item.title, item.titleParams || {})}
            </BsBreadcrumb.Item>
        );
    }

    prepareItems(t, items) {
        const domArray = [];
        for (let i = 0; i < items.length; i++) {
            domArray.push(this.prepareItem(t, items[i], i));
        }
        return domArray;
    }

    render() {
        const t = this.props.t;

        const title = this.props.title;
        const titleParams = this.props.titleParams || {};
        const items = this.props.items || [];

        return (
            <Card text="dark" bg="light" className="mb-3 rounded-0">
                <Card.Body>
                    <h2 className="card-title">
                        <span>{t(title, titleParams)}</span>
                    </h2>

                    <BsBreadcrumb className="mb-0" listProps={{className: 'mb-0'}}>
                        {this.prepareItem(t, {path: '/secure/home', title: 'menu.home'}, 'home')}
                        {this.prepareItems(t, items)}

                        <BsBreadcrumb.Item active={true}>
                            {t(title, titleParams)}
                        </BsBreadcrumb.Item>
                    </BsBreadcrumb>
                </Card.Body>
            </Card>
        );
    }
}

export default withTranslation()(Breadcrumb);
