import axios from "axios";

export default (function () {

    function getLatestActiveUserSessions(limit) {
        return axios.get(`/api/user-sessions/active?limit=${limit}`);
    }

    return {
        getLatestActiveUserSessions
    };
}());
