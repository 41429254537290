import React, {useEffect, useState} from "react";
import Breadcrumb from "@components/Breadcrumb/Breadcrumb";
import secureRoute from "@components/HOC/secureRoute";
import resolveData from "@components/HOC/resolveData";
import remountOnLocationChange from "@components/HOC/remountOnLocationChange";
import {Alert, Col, Form, Row} from "react-bootstrap";
import FormInvalidFeedback from "@components/Form/FormInvalidFeedback/FormInvalidFeedback";
import {useTranslation} from "react-i18next";
import {withFormik} from "formik";
import * as Yup from "yup";
import {atLeastOneCheckbox, required} from "@utils/validations";
import ButtonBar from "@components/ButtonBar/ButtonBar";
import Select from 'react-select';
import {IconButton} from "@components/Button";
import TeamSprintResults from "@views/Metrics/TeamMetrics/TeamSprintResults/TeamSprintResults";
import teamTargetService from "@services/team-target.service";
import teamMetricService from "@services/team-metric.service";
import teamService from "@services/team.service";
import {sortBy} from "@utils/array-utils";

function TeamMetricDetail(props) {
    const {t} = useTranslation();
    const [sprints, setSprints] = useState([]);
    const [selectedSprints, setSelectedSprints] = useState([]);
    const [selectedTeam, setSelectedTeam] = useState(null);
    const [showResult, setShowResult] = useState(false);
    const [teamMetrics, setTeamMetrics] = useState([]);
    const [alertMessage, setAlertMessage] = useState(null);
    const [quarters, setQuarters] = useState([]);
    const [selectedQuarter, setSelectedQuarter] = useState(null);

    const {
        teams,
        values,
        touched,
        errors,
        handleBlur
    } = props;

    let devTeams = teams.map((team) => ({label: team.name, value: team.id}));

    useEffect(() => {
        setSprints([]);
        if (!selectedTeam) {
            return;
        }
        teamMetricService.getSprintsOfTeamByTeamId(selectedTeam)
            .then(sprints => {
                let devSprints = sprints.map(sprint => ({label: sprint.name, value: sprint.id}))
                setSprints(devSprints);
            });
    }, [selectedTeam]);

    useEffect(() => {
        setQuarters([]);
        if (!selectedTeam) {
            return;
        }
        setSelectedQuarter(() => null);
        values.target = "";
        teamTargetService.getTeamMetricQuartersByTeamId(selectedTeam)
            .then(quarters => {
                let devQuarters = quarters
                    .filter(q => q !== null)
                    .map(quarter => {
                    let tokens = quarter.split(':');
                    let quarterLabel = tokens[0] + tokens[1].split('-')[2];
                    return ({label: quarterLabel, value: quarter});
                });
                devQuarters.sort(sortBy('label', false, (q) => q));
                let availableQuarters = [{label: t('msg.pleaseSelect'), value: ''}, ...devQuarters];
                setQuarters(availableQuarters);
            });
    }, [selectedTeam]);

    const handleSprintsChange = (sprints) => {
        let sprintIds = sprints.map(sprint => (sprint.value));
        setSelectedSprints(sprintIds);
    };

    const handleSubmit = () => {
        if (!selectedTeam) {
            return;
        }
        if (selectedSprints && selectedSprints.length > 0 && selectedQuarter) {
            setAlertMessage(t('msg.selectASprintOrQuarter'));
            return;
        }
        if (selectedQuarter) {
            return teamMetricService.calculateSprintsMetricsDetailOfTheTeamByQuarter(selectedTeam, selectedQuarter)
                .then(result => setTeamMetrics(result.teamMetrics))
                .then(() => setShowResult(true))
                .then(() => setAlertMessage(""))
                .catch(errors => {
                    return Promise.reject(errors);
                });
        }
        if (selectedSprints) {
            selectedSprints.map(id => id === false);
            return teamMetricService.calculateSprintsMetricsDetailOfTheTeamBySprints(selectedTeam, selectedSprints)
                .then(result => setTeamMetrics(result.teamMetrics))
                .then(() => setShowResult(true))
                .then(() => setAlertMessage(""))
                .catch(errors => {
                    return Promise.reject(errors);
                });
        }
    }

    return (
        <>
            <Breadcrumb title="title.teamMetricDetail"
                        items={[
                            {title: 'menu.metrics', path: '/secure/team/metrics'}
                        ]}/>

            <form autoComplete="off">
                {alertMessage && alertMessage !== '' &&
                <Alert variant="warning">
                    {alertMessage}
                </Alert>
                }
                <Row>
                    <Col md={3}>
                        <Form.Group className="mb-3" controlId="teamId">
                            <Form.Label>{t('label.team')}</Form.Label>
                            <Select
                                id="teamId"
                                name="teamId"
                                className="mt-4 col-md-6 col-offset-6"
                                defaultMenuIsOpen={false}
                                defaultValue={{label: t('msg.pleaseSelect'), value: ''}}
                                onBlur={handleBlur}
                                onChange={team => setSelectedTeam(team.value)}
                                options={devTeams}
                                isInvalid={errors.teamId && touched.teamId}
                            />
                            {selectedTeam === '' &&
                            <Form.Text>{t('msg.selectAScrumTeam')}</Form.Text>
                            }
                            <FormInvalidFeedback errors={errors.teamId}/>
                        </Form.Group>
                    </Col>

                    <Col md={3}>
                        <Form.Group className="mb-3" controlId="quarters">
                            <Form.Label>{t('label.quarters')}</Form.Label>
                            <Select
                                id="quarters"
                                name="quarters"
                                className="mt-4 col-md-12 col-offset-6"
                                options={quarters}
                                onBlur={handleBlur}
                                onChange={(quarter) => setSelectedQuarter(quarter.value)}
                                defaultValue={{label: t('msg.pleaseSelect'), value: ''}}
                                closeMenuOnSelect={true}
                            />
                        </Form.Group>
                    </Col>

                    <Col md={6}>
                        <Form.Group className="mb-9" controlId="sprintIds">
                            <Form.Label>{t('label.sprints')}</Form.Label>
                            <Select
                                id="sprintIds"
                                name="sprintIds"
                                className="mt-4 col-md-12 col-offset-4"
                                isMulti
                                options={sprints}
                                onBlur={handleBlur}
                                onChange={handleSprintsChange}
                                closeMenuOnSelect={false}
                            />
                            {selectedSprints && selectedSprints.length <= 0 &&
                            <Form.Text>{t('msg.selectASprint')}</Form.Text>
                            }
                            <FormInvalidFeedback errors={errors.sprintIds}/>
                        </Form.Group>
                    </Col>
                </Row>
                <ButtonBar>
                    <IconButton icon="fa fa-line-chart" text={t('button.createMyGraph')} onClick={handleSubmit}
                                disabled={(!selectedTeam || !selectedQuarter) && (!selectedTeam || selectedSprints.length <= 0)}/>
                </ButtonBar>
            </form>
            {showResult && showResult === true &&
            <TeamSprintResults teamMetrics={teamMetrics} selectedSprints={selectedSprints}/>
            }
        </>
    );
}

const WithFormik = withFormik({
    mapPropsToValues: () => {
        return {
            boardId: '',
            quarter: '',
            sprintIds: []
        };
    },
    validationSchema: props => props.validationSchema,
    displayName: 'TeamMetricDetailForm',
})(TeamMetricDetail);

function TeamWithValidationSchema(props) {
    const validationSchema = Yup.object().shape({
        teamId: Yup.number()
            .required(required),
        quarter: Yup.string(),
        sprintIds: Yup.object()
            .test('sprintIds', null, (obj) => {
                const keys = Object.keys(obj);
                const indexOfFirstTrue = keys.findIndex(key => obj[key]);
                if (indexOfFirstTrue >= 0) {
                    return true;
                }

                return new Yup.ValidationError(
                    atLeastOneCheckbox(),
                    null,
                    'sprintIds'
                );
            })
    });
    return <WithFormik validationSchema={validationSchema} {...props} />
}

const TeamMetricDetailWithResolvedData = resolveData(TeamWithValidationSchema, () => {
    return {
        teams: teamService.getAllTeams()
    }
});

export default secureRoute(remountOnLocationChange(TeamMetricDetailWithResolvedData));
