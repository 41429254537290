import axios from "axios";

const assessmentFormTemplateService = (function () {
    const baseUrl = '/api/assessment-form-templates';

    function getAssessmentFormTemplates() {
        return axios.get(baseUrl);
    }

    function getAssessmentFormTemplateById(templateId) {
        return axios.get(`${baseUrl}/${templateId}`);
    }

    function createAssessmentFormTemplate(data) {
        return axios.post(baseUrl, data);
    }

    function updateAssessmentFormTemplate(templateId, data) {
        return axios.put(`${baseUrl}/${templateId}`, data);
    }

    function deleteAssessmentFormTemplate(templateId) {
        return axios.delete(`${baseUrl}/${templateId}`);
    }

    function isAssessmentFormTemplateNameAvailable(templateId, name) {
        const encodedName = encodeURIComponent(name);
        const encodedTemplateId = templateId || '';
        return axios.get(`${baseUrl}/names/available?templateId=${encodedTemplateId}&name=${encodedName}`);
    }

    function getAssessmentFormTemplatesByBoardId(boardId) {
        return axios.get(`${baseUrl}/boards/${boardId}`);
    }

    function getTeamIdAndNames() {
        return axios.get(`${baseUrl}/teams`);
    }

    function getCategories() {
        return axios.get(`${baseUrl}/categories`);
    }

    return {
        getAssessmentFormTemplates,
        getAssessmentFormTemplateById,
        createAssessmentFormTemplate,
        updateAssessmentFormTemplate,
        deleteAssessmentFormTemplate,
        isAssessmentFormTemplateNameAvailable,
        getAssessmentFormTemplatesByBoardId,
        getTeamIdAndNames,
        getCategories,
    };
}());

export default assessmentFormTemplateService;
