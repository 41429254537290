import {useTranslation} from "react-i18next";
import secureRoute from "@components/HOC/secureRoute";
import resolveData from "@components/HOC/resolveData";
import Breadcrumb from "@components/Breadcrumb/Breadcrumb";
import sprintAssessmentService from "@services/sprint-assessment.service";
import {withFormik} from "formik";
import toastService from "@services/toast.service";
import * as Yup from "yup";
import {checkAssessmentCategoryNameExistence, maxLength, required} from "@utils/validations";
import React from "react";
import BackendErrors from "@components/BackendErrors/BackendErrors";
import {Button, Col, Form, Row} from "react-bootstrap";
import {FormInvalidFeedback} from "@components/Form";
import ButtonBar from "@components/ButtonBar/ButtonBar";
import SimpleCard from "@components/SimpleCard/SimpleCard";

function AssessmentCategoryEdit(props) {
    const {t} = useTranslation();
    const {
        isNew,
        title,
        values,
        touched,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        isValidating
    } = props;

    const submitText = isNew ? 'button.createAssessmentCategory' : 'button.updateAssessmentCategory';
    const submitDisabled = isSubmitting || isValidating;

    return (
        <>
            <Breadcrumb title={title}/>

            <BackendErrors error={errors.backendErrors}/>

            <form onSubmit={handleSubmit} autoComplete="off">
                <SimpleCard>
                    <Row>
                        <Col md={6}>
                            <Form.Group className="mb-3" controlId="name">
                                <Form.Label>{t('label.categoryName')}</Form.Label>
                                <Form.Control name="name" value={values.name} maxLength={255}
                                              onChange={handleChange} onBlur={handleBlur}
                                              isInvalid={errors.name && touched.name}/>
                                <FormInvalidFeedback errors={errors.name}/>
                            </Form.Group>
                        </Col>
                    </Row>
                </SimpleCard>

                <ButtonBar>
                    <Button variant="primary" type="submit" disabled={submitDisabled}>{t(submitText)}</Button>
                </ButtonBar>
            </form>
        </>
    );
}

const WithFormik = withFormik({
    mapPropsToValues: (props) => {
        const {category = {}} = props;
        return {...category};
    },

    validationSchema: props => props.validationSchema,

    handleSubmit: (values, {props, setErrors}) => {
        const {categoryId} = props;
        const createOrUpdatePromise = categoryId ?
            sprintAssessmentService.updateCategory(categoryId, values) :
            sprintAssessmentService.createCategory(values);
        const successMsg = categoryId ? 'msg.assessmentCategoryUpdated' : 'msg.assessmentCategoryCreated';

        return createOrUpdatePromise
            .then(() => toastService.success(successMsg))
            .then(() => props.history.push('/secure/assessment-categories'))
            .catch(errors => {
                setErrors({backendErrors: errors});
                return Promise.reject(errors);
            });
    },

    displayName: 'AssessmentCategoryEditForm',
})(AssessmentCategoryEdit);

function WithValidationSchema(props) {
    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .required(required)
            .max(255, maxLength)
            .test('name', {code: 'error.nameUsed'}, checkAssessmentCategoryNameExistence(props.categoryId, 500)),
    });
    return <WithFormik validationSchema={validationSchema} {...props} />
}

export default secureRoute(resolveData(WithValidationSchema, (props) => {
    const {assessmentCategoryId: categoryId} = props.match.params;
    const isNew = !categoryId;
    const title = isNew ? 'title.createAssessmentCategory' : 'title.editAssessmentCategory';
    const resolveData = {title, isNew, categoryId};
    if (categoryId) {
        resolveData.category = sprintAssessmentService.getCategory(categoryId);
    }
    return resolveData;
}));
