import secureRoute from "@components/HOC/secureRoute";
import {Redirect, Route, Switch, useRouteMatch} from "react-router-dom";
import Home from "@views/Home/Home";
import DeveloperMetricDetail from "@views/Metrics/DeveloperMetrics/DeveloperMetricDetail/DeveloperMetricDetail";
import DeveloperTargetDetail from "@views/Metrics/DeveloperTargets/DeveloperTargetDetail/DeveloperTargetDetail";
import TeamMetricDetail from "@views/Metrics/TeamMetrics/TeamMetricDetail/TeamMetricDetail";
import TeamTargetDetail from "@views/Metrics/TeamTargets/TeamTargetDetail/TeamTargetDetail";
import ChangePasswordPage from "@views/Users/ChangePassword/ChangePasswordPage";
import TeamList from "@views/Teams/TeamList";
import CreateTeam from "@views/Teams/CreateTeam";
import TeamDetail from "@views/Teams/TeamDetail";
import BoardList from "@views/Boards/BoardList/BoardList";
import BoardDetail from "@views/Boards/BoardDetail/BoardDetail";
import SprintDetail from "@views/Sprints/SprintDetail/SprintDetail";
import SprintSnapshots from "@views/Sprints/SprintSnapshots/SprintSnapshots";
import SprintDeveloperDetail from "@views/Sprints/SprintDeveloperDetail/SprintDeveloperDetail";
import UserList from "@views/Users/UserList/UserList";
import UserEdit from "@views/Users/UserEdit/UserEdit";
import UserDetail from "@views/Users/UserDetail/UserDetail";
import YoutrackUsers from "@views/Imports/YoutrackUsers/YoutrackUsers";
import SprintAssessmentImport from "@views/Imports/SprintAssessments/SprintAssessmentImport";
import DeveloperAliases from "@views/DeveloperAliases/DeveloperAliases";
import AssessmentCategoryList
    from "@views/Assessments/AssessmentCategories/AssessmentCategoryList/AssessmentCategoryList";
import AssessmentCategoryMerge
    from "@views/Assessments/AssessmentCategories/AssessmentCategoryMerge/AssessmentCategoryMerge";
import AssessmentCategoryEdit
    from "@views/Assessments/AssessmentCategories/AssessmentCategoryEdit/AssessmentCategoryEdit";
import CalculateBonus from "@views/Bonus/CalculateBonus/CalculateBonus";
import AssessmentFormTemplateList
    from "@views/Assessments/AssessmentFormTemplates/AssessmentFormTemplateList/AssessmentFormTemplateList";
import AssessmentFormTemplateEdit
    from "@views/Assessments/AssessmentFormTemplates/AssessmentFormTemplateEdit/AssessmentFormTemplateEdit";
import AssessmentFormCreate from "@views/Assessments/AssessmentFormCreate/AssessmentFormCreate";
import AssessmentFormFill from "@views/Assessments/AssessmentFormFill/AssessmentFormFill";
import NotFound from "@views/Common/NotFound/NotFound";
import React from "react";
import KudosDashboard from "@views/Kudos/KudosDashboard";

// All route urls will be prefixed by /secure (current routeMatch.path)
const secureRoutes = [
    {
        path: '/home',
        exact: true,
        component: Home
    },
    {
        path: '/change-password',
        exact: true,
        component: ChangePasswordPage
    },
    {
        path: '/developer/metrics',
        exact: true,
        component: DeveloperMetricDetail
    },
    {
        path: '/developer/targets',
        exact: true,
        component: DeveloperTargetDetail
    },
    {
        path: '/team/metrics',
        exact: true,
        component: TeamMetricDetail
    },
    {
        path: '/team/targets',
        exact: true,
        component: TeamTargetDetail
    },
    {
        path: '/teams',
        exact: true,
        component: TeamList
    },
    {
        path: '/teams/create',
        exact: true,
        component: CreateTeam
    },
    {
        path: '/teams/:teamId',
        exact: true,
        component: TeamDetail
    },
    {
        path: '/boards',
        exact: true,
        component: BoardList
    },
    {
        path: '/boards/:boardId',
        exact: true,
        component: BoardDetail
    },
    {
        path: '/sprints/:sprintId',
        exact: true,
        component: SprintDetail
    },
    {
        path: '/sprints/:sprintId/snapshots',
        exact: true,
        component: SprintSnapshots
    },
    {
        path: '/sprints/:sprintId/developers/:developerId',
        exact: true,
        component: SprintDeveloperDetail
    },
    {
        path: '/users',
        exact: true,
        component: UserList
    },
    {
        path: '/users/create',
        exact: true,
        component: UserEdit
    },
    {
        path: '/users/:userId/edit',
        exact: true,
        component: UserEdit
    },
    {
        path: '/users/:userId',
        exact: true,
        component: UserDetail
    },
    {
        path: '/imports/youtrack-users',
        exact: true,
        component: YoutrackUsers
    },
    {
        path: '/imports/sprint-assessments',
        exact: true,
        component: SprintAssessmentImport
    },
    {
        path: '/developer-aliases',
        exact: true,
        component: DeveloperAliases
    },
    {
        path: '/assessment-categories',
        exact: true,
        component: AssessmentCategoryList
    },
    {
        path: '/assessment-categories/merge',
        exact: true,
        component: AssessmentCategoryMerge
    },
    {
        path: '/assessment-categories/create',
        exact: true,
        component: AssessmentCategoryEdit
    },
    {
        path: '/assessment-categories/:assessmentCategoryId/edit',
        exact: true,
        component: AssessmentCategoryEdit
    },
    {
        path: '/bonuses',
        exact: true,
        component: CalculateBonus
    },
    {
        path: '/assessment-form-templates',
        exact: true,
        component: AssessmentFormTemplateList
    },
    {
        path: '/assessment-form-templates/create',
        exact: true,
        component: AssessmentFormTemplateEdit
    },
    {
        path: '/assessment-form-templates/:assessmentTemplateId/edit',
        exact: true,
        component: AssessmentFormTemplateEdit
    },
    {
        path: '/sprint-assessments/create',
        exact: true,
        component: AssessmentFormCreate
    },
    {
        path: '/sprint-assessments/:sprintId/evaluate',
        exact: true,
        component: AssessmentFormFill
    },
    {
        path: '/kudos',
        exact: true,
        component: KudosDashboard
    }
];

function SecureParent() {
    const {path} = useRouteMatch();

    return (
        <Switch>
            <Redirect exact={true} from={path} to={`${path}/home`}/>
            {secureRoutes.map(secureRoute => (
                <Route key={secureRoute.path}
                       exact={secureRoute.exact}
                       path={`${path}${secureRoute.path}`}
                       component={secureRoute.component}/>
            ))}
            <Route path='*' exact component={NotFound}/>
        </Switch>
    );
}

export default secureRoute(SecureParent);
