import React from "react";
import {withTranslation} from "react-i18next";
import {withFormik} from "formik";
import {Button, Form} from "react-bootstrap";
import teamService from "@services/team.service";
import * as Yup from 'yup';
import {maxLength, required} from "@utils/validations";
import {FormInvalidFeedback} from "@components/Form";
import toastService from "@services/toast.service";
import secureRoute from "@components/HOC/secureRoute";
import Breadcrumb from "@components/Breadcrumb/Breadcrumb";
import SimpleCard from "@components/SimpleCard/SimpleCard";
import boardService from "@services/board.service";
import resolveData from "@components/HOC/resolveData";

class CreateTeamForm extends React.Component {
    render() {
        const {
            boards,
            t,
            values,
            touched,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
        } = this.props;

        return (
            <>
                <Breadcrumb title="title.createNewTeam" items={[{title: 'menu.teams', path: '/secure/teams'}]}/>

                <SimpleCard>
                    <form onSubmit={handleSubmit}>
                        <Form.Group className="mb-3" controlId="teamName">
                            <Form.Label>{t('label.teamName')}</Form.Label>
                            <Form.Control name="name" value={values.name} maxLength={255}
                                          onChange={handleChange} onBlur={handleBlur}
                                          isInvalid={errors.name && touched.name}/>
                            <FormInvalidFeedback errors={errors.name}/>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="teamDescription">
                            <Form.Label>{t('label.teamDescription')}</Form.Label>
                            <Form.Control as="textarea" name="description" value={values.description} maxLength={1024}
                                          onChange={handleChange} onBlur={handleBlur}
                                          isInvalid={errors.description && touched.description}/>
                            <FormInvalidFeedback errors={errors.description}/>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="boardId">
                            <Form.Label>{t('label.board')}</Form.Label>
                            <Form.Select name="boardId" value={values.boardId}
                                         onChange={handleChange} onBlur={handleBlur}
                                         isInvalid={errors.boardId && touched.boardId}>
                                <option value="">{t('msg.pleaseSelect')}</option>
                                {boards.map((board, index) => (
                                    <option key={index} value={board.id}>{board.name}</option>
                                ))}
                            </Form.Select>
                            <Form.Text>{t('msg.selectAScrumBoard')}</Form.Text>
                            <FormInvalidFeedback errors={errors.boardId}/>
                        </Form.Group>

                        <Button variant="primary" type="submit"
                                disabled={isSubmitting}>{t('button.createTeam')}</Button>
                    </form>
                </SimpleCard>
            </>
        );
    }
}

const CreateTeamFormWithTranslate = withTranslation()(CreateTeamForm);

const CreateTeamFormWithFormik = withFormik({
    mapPropsToValues: () => ({
        name: '',
        description: '',
        boardId: ''
    }),

    validationSchema: Yup.object().shape({
        name: Yup.string()
            .required(required)
            .max(255, maxLength),
        description: Yup.string()
            .required(required)
            .max(1024, maxLength),
        boardId: Yup.number()
            .required(required)
    }),

    handleSubmit: (values, {setSubmitting, props}) => {
        teamService.createTeam(values)
            .then(() => toastService.success('msg.teamCreated'))
            .then(() => props.history.push('/secure/teams'))
            .finally(() => setSubmitting(false));
    },

    displayName: 'CreateTeamForm',
})(CreateTeamFormWithTranslate);

export default secureRoute(resolveData(CreateTeamFormWithFormik, () => {
    return {
        boards: boardService.getAllBoardsWithoutTeams()
    }
}));
