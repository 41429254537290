import {useTranslation} from "react-i18next";
import secureRoute from "@components/HOC/secureRoute";
import Breadcrumb from "@components/Breadcrumb/Breadcrumb";
import SimpleCard from "@components/SimpleCard/SimpleCard";
import TableHolder from "@components/TableHolder/TableHolder";
import {Alert, Button, Table} from "react-bootstrap";
import {DeleteButton, EditButton} from "@components/Button";
import React, {useCallback, useEffect, useState} from "react";
import ButtonBar from "@components/ButtonBar/ButtonBar";
import {Link} from "react-router-dom";
import Loading from "@components/Loading/Loading";
import assessmentFormTemplateService from "@services/assessment-form-template.service";

function AssessmentFormTemplateList(props) {
    const {t} = useTranslation();
    const {history, currentUser} = props;
    const canEdit = currentUser.hasAnyPrivilege(['PRIV_EDIT_ASSESSMENT_FORM_TEMPLATE', 'PRIV_EDIT_TEAM_ASSESSMENT_FORM_TEMPLATE']);
    const [{data: assessmentFormTemplates = [], isFetching}, setData] = useState({data: [], isFetching: false});
    const showTable = !!assessmentFormTemplates.length && !isFetching;
    const noElements = !assessmentFormTemplates.length && !isFetching;

    const reload = useCallback(() => {
        setData({data: [], isFetching: true});
        assessmentFormTemplateService.getAssessmentFormTemplates()
            .then((result) => setData({data: result, isFetching: false}));
    }, []);

    useEffect(() => {
        reload();
    }, [reload]);

    const handleEdit = (template) => {
        history.push(`/secure/assessment-form-templates/${template.id}/edit`);
    };

    const handleDelete = (template) => {
        assessmentFormTemplateService.deleteAssessmentFormTemplate(template.id)
            .then(() => reload());
    };

    return (
        <>
            <Breadcrumb title="title.assessmentFormTemplates"/>

            <SimpleCard>
                {noElements && (
                    <Alert className="mb-0" variant="info">{t('msg.noAssessmentFormTemplate')}</Alert>
                )}
                {showTable && (
                    <TableHolder>
                        <Table striped bordered>
                            <thead>
                            <tr>
                                {canEdit && (
                                    <th className="table-actions"/>
                                )}
                                <th className="table-actions">{t('label.templateId')}</th>
                                <th>{t('label.templateName')}</th>
                                <th>{t('label.teamName')}</th>
                            </tr>
                            </thead>
                            <tbody>
                            {assessmentFormTemplates.map((template, index) => (
                                <tr key={index}>
                                    {canEdit && (
                                        <td className="table-actions">
                                            <EditButton title="tooltip.editAssessmentFormTemplate"
                                                        onClick={() => handleEdit(template)}/>
                                            &nbsp;
                                            <DeleteButton title="tooltip.deleteAssessmentFormTemplate"
                                                          onClick={() => handleDelete(template)}/>
                                        </td>
                                    )}
                                    <td className="table-actions">{template.id}</td>
                                    <td>{template.name}</td>
                                    <td>{template.teamName}</td>
                                </tr>
                            ))}
                            </tbody>
                        </Table>
                    </TableHolder>
                )}

                {isFetching && <Loading/>}
            </SimpleCard>

            {canEdit && (
                <ButtonBar>
                    <Button variant="primary" as={Link}
                            to="/secure/assessment-form-templates/create">{t('button.createAssessmentFormTemplate')}</Button>
                </ButtonBar>
            )}
        </>
    );
}

export default secureRoute(AssessmentFormTemplateList);
