import React from "react";
import userService from "@services/user.service";
import Loading from "@components/Loading/Loading";
import {Redirect, withRouter} from "react-router-dom";
import {setCurrentUser} from "@store";
import {connect} from "react-redux";

export default function secureRoute(WrappedComponent) {
    class SecureRoute extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                currentUser: null,
                isLoading: true
            };
            this.mounted = false;
        }

        setStateIfMounted(values) {
            if (this.mounted) {
                this.setState({...values})
            }
        }

        componentDidMount() {
            this.mounted = true;
            this.loadCurrentUser();
        }

        componentDidUpdate(prevProps, prevState, snapshot) {
            if (prevProps.currentUser && !this.props.currentUser) {
                if (this.state.currentUser) {
                    this.setStateIfMounted({currentUser: null});
                }
            }
        }

        componentWillUnmount() {
            this.mounted = false;
        }

        loadCurrentUser() {
            userService.getCurrentUser()
                .then(user => {
                    this.setStateIfMounted({isLoading: false, currentUser: user})
                    this.props.setCurrentUser(user);
                })
                .catch(() => this.setStateIfMounted({isLoading: false}));
        }

        render() {
            if (this.state.isLoading) {
                return <Loading/>;
            }

            if (!this.state.currentUser) {
                const {pathname} = this.props.location;
                return <Redirect to={{pathname: "/login", state: {from: pathname}}}/>;
            }

            const props = {...this.props, currentUser: this.state.currentUser};
            return <WrappedComponent {...props}/>;
        }
    }

    const SecureRouteWithRouter = withRouter(SecureRoute);

    function mapStateToProps(state) {
        const {user} = state.currentUser
        return {currentUser: user};
    }

    const mapDispatchToProps = (dispatch) => {
        return {
            setCurrentUser: (user) => dispatch(setCurrentUser({user: Object.assign({}, user)}))
        }
    }

    return connect(mapStateToProps, mapDispatchToProps)(SecureRouteWithRouter);
};
