import secureRoute from "@components/HOC/secureRoute";
import WaitingAssessmentForms from "@views/Home/WaitingAssessmentForms/WaitingAssessmentForms";
import {Col, Row} from "react-bootstrap";
import IncompleteAssessmentForms from "@views/Home/IncompleteAssessmentForms/IncompleteAssessmentForms";
import ActiveUserSessions from "@views/Home/ActiveUserSessions/ActiveUserSessions";

function Home() {
    return (
        <>
            <Row>
                <Col md={6}>
                    <WaitingAssessmentForms/>

                    <ActiveUserSessions/>
                </Col>
                <Col md={6}>
                    <IncompleteAssessmentForms/>
                </Col>
            </Row>
        </>
    );
}

export default secureRoute(Home);
