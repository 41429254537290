import React from "react";
import {Card, Col, Row} from "react-bootstrap";
import userService from "../../services/user.service";
import {useHistory} from "react-router-dom";
import {useTranslation} from 'react-i18next';
import useQuery from "@/hooks/useQuery";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {maxLength, minLength, required} from "@utils/validations";
import BackendErrors from "@components/BackendErrors/BackendErrors";
import InputTextFormGroup from "@components/Form/InputTextFromGroup/InputTextFormGroup";
import SubmitButton from "@components/Button/SubmitButton/SubmitButton";
import toastService from "@services/toast.service";

const validationSchema = Yup.object().shape({
    username: Yup.string()
        .required(required),
    currentPassword: Yup.string()
        .required(required),
    newPassword: Yup.string()
        .required(required)
        .min(8, minLength)
        .max(255, maxLength),
    newPasswordAgain: Yup.string()
        .required(required)
        .min(8, minLength)
        .max(255, maxLength)
        .oneOf([Yup.ref('newPassword'), null], {code: 'error.bothPasswordsMustBeSame'}),
});

function CredentialsExpired() {
    const queryStringParams = useQuery();
    const {t} = useTranslation();
    const history = useHistory();

    const defaultValues = {
        username: queryStringParams.get('username') || '',
        currentPassword: '',
        newPassword: '',
        newPasswordAgain: ''
    };

    const {
        register,
        handleSubmit,
        setError,
        clearErrors,
        formState: {errors, isValid, isValidating}
    } = useForm({
        mode: 'onChange',
        resolver: yupResolver(validationSchema),
        defaultValues: defaultValues
    });

    const onSubmit = (data) => {
        clearErrors();
        return userService.changePasswordWithoutLogin(data)
            .then(() => {
                toastService.success('msg.passwordChanged');
                history.push('/secure/home');
            })
            .catch(errors => {
                setError('backendErrors', errors);
                return errors;
            });
    };

    return (
        <Row>
            <Col md={{span: 6, offset: 3}}>
                <Card>
                    <Card.Header>
                        <h3>{t('title.changePassword')}</h3>
                    </Card.Header>
                    <Card.Body>
                        <BackendErrors error={errors.backendErrors}/>

                        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                            <InputTextFormGroup register={register}
                                                fieldName="username"
                                                label={t('label.username')}
                                                readOnly={true}
                                                errors={errors}/>

                            <InputTextFormGroup register={register}
                                                fieldName="currentPassword"
                                                label={t('label.currentPassword')}
                                                type="password"
                                                errors={errors}/>

                            <InputTextFormGroup register={register}
                                                fieldName="newPassword"
                                                label={t('label.newPassword')}
                                                type="password"
                                                errors={errors}/>

                            <InputTextFormGroup register={register}
                                                fieldName="newPasswordAgain"
                                                label={t('label.newPasswordAgain')}
                                                type="password"
                                                errors={errors}/>

                            <SubmitButton text="button.changePassword" isValid={isValid} isValidating={isValidating}/>
                        </form>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );
}

export default CredentialsExpired;
