import {useForm} from "react-hook-form";
import * as Yup from "yup";
import {required} from "@utils/validations";
import BackendErrors from "@components/BackendErrors/BackendErrors";
import {Alert, Col, Row} from "react-bootstrap";
import SimpleCard from "@components/SimpleCard/SimpleCard";
import InputTextFormGroup from "@components/Form/InputTextFromGroup/InputTextFormGroup";
import SubmitButton from "@components/Button/SubmitButton/SubmitButton";
import React, {useState} from "react";
import toastService from "@services/toast.service";
import userService from "@services/user.service";
import {useTranslation} from "react-i18next";
import {yupResolver} from "@hookform/resolvers/yup";

const validationSchema = Yup.object().shape({
    username: Yup.string().required(required)
});

const successMessage = 'msg.forgotPasswordSentSuccessfully';

function ForgotPassword() {
    const {t} = useTranslation();
    const [sent, setSent] = useState(false);
    const {
        register,
        handleSubmit,
        setError,
        clearErrors,
        formState: {errors, isValid, isValidating, isSubmitting}
    } = useForm({
        mode: 'all',
        resolver: yupResolver(validationSchema),
        defaultValues: {username: ''}
    });

    const onSubmit = (data) => {
        clearErrors();
        return userService.sendForgotPasswordMail(data)
            .then(() => {
                toastService.success(successMessage);
                setSent(true);
            })
            .catch(errors => {
                setError('backendErrors', errors);
                return Promise.reject(errors);
            });
    };

    return <>
        <BackendErrors error={errors.backendErrors}/>

        {!sent && (
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                <Row>
                    <Col md={6} className="offset-3">
                        <SimpleCard>
                            <h2 className="mb-3">{t('label.forgotPassword')}</h2>

                            <InputTextFormGroup register={register}
                                                fieldName="username"
                                                label={t('label.username')}
                                                errors={errors}/>

                            <SubmitButton text="button.sendForgotPasswordEmail"
                                          isValid={isValid}
                                          isSubmitting={isSubmitting}
                                          isValidating={isValidating}/>
                        </SimpleCard>
                    </Col>
                </Row>
            </form>
        )}

        {!!sent && (
            <Row>
                <Col md={6} className="offset-3">
                    <SimpleCard>
                        <h2 className="mb-3">{t('label.forgotPassword')}</h2>
                        <Alert variant="success">{t(successMessage)}</Alert>
                    </SimpleCard>
                </Col>
            </Row>
        )}
    </>;
}

export default ForgotPassword;
