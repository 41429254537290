//https://stackoverflow.com/questions/979256/sorting-an-array-of-objects-by-property-values
const sortBy = (field, reverse, primer) => {
    const key = primer ?
        function (x) {
            return primer(x[field])
        } :
        function (x) {
            return x[field]
        };
    reverse = !reverse ? 1 : -1;

    return function (a, b) {
        return a = key(a), b = key(b), reverse * ((a > b) - (b > a));
    }
}

const splitArrayIntoColumns = (array, cols) => {
    return [...Array(cols).keys()]
        .map(columnNumber => array.filter((_, i) => i % cols === columnNumber));
};

export {
    sortBy,
    splitArrayIntoColumns,
}
