import secureRoute from "@components/HOC/secureRoute";
import resolveData from "@components/HOC/resolveData";
import sprintAssessmentService from "@services/sprint-assessment.service";
import SimpleCard from "@components/SimpleCard/SimpleCard";
import remountOnLocationChange from "@components/HOC/remountOnLocationChange";
import SprintSummaryView from "@views/Sprints/SprintSummaryView/SprintSummaryView";
import React, {useMemo} from "react";
import Breadcrumb from "@components/Breadcrumb/Breadcrumb";
import AssessmentIcon from "@components/AssessmentIcon/AssessmentIcon";
import {Button} from "react-bootstrap";
import ButtonBar from "@components/ButtonBar/ButtonBar";
import {useTranslation} from "react-i18next";
import {FormProvider, useFieldArray, useForm, useFormContext} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {countBy, get, property} from "lodash";
import toastService from "@services/toast.service";
import {useHistory} from "react-router-dom";
import Markdown from "@components/Markdown/Markdown";

function VoteTypesTableHeader({voteTypes}) {
    return (
        <thead>
        <tr>
            <th/>
            {voteTypes.map((voteType, index) => (
                <th className="text-center" key={index}>
                    <AssessmentIcon voteType={voteType}/>
                </th>
            ))}
        </tr>
        </thead>
    );
}

function DeveloperTableRow({categoryIndex, developerIndex, voteTypes}) {
    const {register, getValues} = useFormContext();
    const value = getValues(`categoryVoteGroup[${categoryIndex}].developerVoteList[${developerIndex}]`);
    const fieldName = `categoryVoteGroup[${categoryIndex}].developerVoteList[${developerIndex}].voteTypeId`;

    return (
        <tr>
            <td><b>{value.developerName}</b></td>

            {voteTypes.map((voteType, voteTypeIndex) => (
                <td className="text-center" key={voteTypeIndex}>
                    <div className="form-check d-inline-block">
                        <input {...register(fieldName)}
                               type="radio"
                               name={fieldName}
                               className="form-check-input"
                               value={voteType.id}/>
                    </div>
                </td>
            ))}
        </tr>
    );
}

function CategoryView({categoryIndex, voteTypes, showCategoryNames}) {
    const fieldName = `categoryVoteGroup[${categoryIndex}]`;
    const {t} = useTranslation();
    const {control, getValues, formState: {errors}} = useFormContext();
    const {
        fields: developerVoteListFields,
    } = useFieldArray({
        control,
        name: `${fieldName}.developerVoteList`,
        keyName: 'htmlFieldId'
    });

    const value = getValues(fieldName);
    const error = get(errors, fieldName, null);
    const title = showCategoryNames ? value.categoryName : t('label.category') + ' ' + (categoryIndex + 1);

    return (
        <SimpleCard title={title}>
            <div>
                <Markdown>{value.categoryDescription}</Markdown>
            </div>

            <table className={`table table-bordered table-sm ${error ? 'border-danger' : ''}`}>
                <VoteTypesTableHeader voteTypes={voteTypes}/>

                <tbody>
                {developerVoteListFields.map((developerVote, index) => (
                    <DeveloperTableRow categoryIndex={categoryIndex}
                                       developerIndex={index}
                                       key={index}
                                       voteTypes={voteTypes}/>
                ))}
                </tbody>
            </table>
        </SimpleCard>
    );
}

function createValidationSchema(sprintAssessmentForm) {

    const oncePerCategory = (categoryVoteGroup) => {
        if (!categoryVoteGroup || !categoryVoteGroup.developerVoteList) {
            return true;
        }
        const voteTypeIdUsageCount = countBy(categoryVoteGroup.developerVoteList, property('voteTypeId'));
        for (let voteType of sprintAssessmentForm.voteTypes) {
            if (voteType.oncePerCategory && voteTypeIdUsageCount[voteType.id] && voteTypeIdUsageCount[voteType.id] > 1) {
                return new Yup.ValidationError('error.voteTypeOnlyOncePerCategory',
                    {
                        code: 'error.voteTypeOnlyOncePerCategory',
                        voteTypeName: voteType.name,
                    }
                );
            }
        }
        return true;
    };

    return Yup.object().shape({
        categoryVoteGroup: Yup.array(
            Yup.object().shape({
                categoryId: Yup.number(),
                developerVoteList: Yup.array(
                    Yup.object().shape({
                        developerId: Yup.number(),
                        voteTypeId: Yup.number()
                    })
                )
            }).test('developerVoteList', 'wrong', oncePerCategory)
        )
    });
}

function createDefaultValues(assessmentForm) {
    return {
        categoryVoteGroup: assessmentForm.categories.map(category => {
            return {
                categoryId: category.categoryId,
                categoryName: category.categoryName,
                categoryDescription: category.description,
                developerVoteList: assessmentForm.developers.map(developer => {
                    return {
                        developerId: developer.id,
                        developerName: developer.fullName,
                        voteTypeId: null
                    };
                })
            };
        })
    };
}

function AssessmentFormFill({assessmentForm: sprintAssessmentForm}) {
    const {t} = useTranslation();
    const defaultValues = useMemo(() => createDefaultValues(sprintAssessmentForm), [sprintAssessmentForm]);
    const validationSchema = useMemo(() => createValidationSchema(sprintAssessmentForm), [sprintAssessmentForm]);
    const history = useHistory();

    const assessmentForm = useForm({
        mode: 'onChange',
        resolver: yupResolver(validationSchema),
        defaultValues: defaultValues
    });

    const {
        control,
        handleSubmit,
        formState: {isValid, isValidating, isSubmitting}
    } = assessmentForm;

    const {
        fields: categoryVoteGroupFields,
    } = useFieldArray({
        control,
        name: "categoryVoteGroup",
        keyName: 'htmlFieldId'
    });

    const {sprint, voteTypes = [], id: assessmentFormId} = sprintAssessmentForm;

    const onSubmit = (data) => {
        return sprintAssessmentService.submitSprintAssessmentEvaluation(assessmentFormId, data)
            .then(() => {
                toastService.success('msg.assessmentFormEvaluationSubmitted');
                history.push('/secure/home');
            });
    };

    return (
        <FormProvider {...assessmentForm}>
            <Breadcrumb title="title.sprintAssessmentForm"
                        items={[
                            {title: 'title.sprintDetail', path: `/secure/sprints/${sprint.id}`, titleParams: sprint}
                        ]}/>

            <SprintSummaryView sprint={sprint}/>

            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                {categoryVoteGroupFields.map((categoryVoteGroupField, index) => (
                    <CategoryView categoryIndex={index}
                                  voteTypes={voteTypes}
                                  showCategoryNames={sprintAssessmentForm.showCategoryNames}
                                  key={index}/>
                ))}

                <ButtonBar>
                    <Button variant="primary" type="submit"
                            disabled={!isValid || isValidating || isSubmitting}>{t('button.submitAssessmentFormFill')}</Button>
                </ButtonBar>
            </form>
        </FormProvider>
    );
}

export default secureRoute(remountOnLocationChange(resolveData(AssessmentFormFill, (props) => {
    const {sprintId} = props.match.params;
    return {
        assessmentForm: sprintAssessmentService.getAssessmentFormBySprintId(sprintId)
    };
})));
