import {useTranslation} from "react-i18next";
import SimpleCard from "@components/SimpleCard/SimpleCard";
import {Bar} from "@nivo/bar";
import React, {Fragment} from "react";
import {line} from "d3-shape";

function DeveloperSprintResults(props) {
    const {t} = useTranslation();
    const styles = {
        fontFamily: "sans-serif",
        textAlign: "center"
    };
    let sortedDevMetrics = props.developerMetrics.map(metric => (
        {
            id: metric.sprint.id,
            sprint: metric.sprint.name
                .substr(0, metric.sprint.name.length > 10 ? 10 : metric.sprint.name.length),
            targets: metric.developerStats?.totalStoryPoints || 0,
            actuals: metric.developerStats?.completedStoryPoints || 0,
            assessment: metric.assessmentRatio || 0,
            quarterTarget: metric.quarterTarget || 0.1
        })).sort((sprintFirst, sprintSecond) => {
        return parseFloat(sprintFirst.id) - parseFloat(sprintSecond.id);
    });


    const axisBottom = {
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: t('label.sprints'),
        legendPosition: "middle",
        legendOffset: 32
    };

    const axisLeft = {
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: t('label.targetStoryPoints') + "/" + t('label.actualStoryPoints'),
        legendPosition: "middle",
        legendOffset: -40
    };

    const theme = {
        background: "#000000",
        axis: {
            fontSize: "14px",
            tickColor: "#eee",
            ticks: {
                line: {
                    stroke: "#555555"
                },
                text: {
                    fill: "#ffffff"
                }
            },
            legend: {
                text: {
                    fill: "#aaaaaa"
                }
            }
        },
        grid: {
            line: {
                stroke: "#555555"
            }
        }
    };

    const colorBy = ({id}) => {
        switch (id) {
            case "targets":
                return "#FF0000";
            case "actuals":
                return "#9FE2BF";
            case "assessment":
                return "#DAF7A6";
            default:
                return "#DAF7A6";
        }
    }

    const tooltipBy = ({id, value, color}) => {
        switch (id) {
            case "targets":
                return (<div
                    style={{
                        padding: 12,
                        color,
                        background: '#222222',
                    }}
                >
                    <span>{t('label.targetStoryPoints')} : <strong>{value} </strong></span>
                </div>);
            case "actuals":
                return (<div
                    style={{
                        padding: 12,
                        color,
                        background: '#222222',
                    }}
                >
                    <span>{t('label.actualStoryPoints')} : <strong>{value} </strong></span>
                </div>);
            case "assessment":
                return (<div
                    style={{
                        padding: 12,
                        color,
                        background: '#222222',
                    }}
                >
                    <span>{t('label.ratioOfTeamCollaboration')} : <strong>{value} % </strong></span>
                </div>);
            case "quarterTarget":
                return (<div
                    style={{
                        padding: 12,
                        color,
                        background: '#222222',
                    }}
                >
                    <span>{t('label.quarterTarget')} : <strong>{value} </strong></span>
                </div>);
            default:
                return "";
        }
    }

    const legends = [
        {
            dataFrom: "keys",
            anchor: "bottom-right",
            direction: "column",
            justify: false,
            translateX: 120,
            translateY: 0,
            itemsSpacing: 2,
            itemWidth: 100,
            itemHeight: 20,
            itemDirection: "left-to-right",
            itemOpacity: 0.85,
            itemTextColor: "#ffffff",
            symbolSize: 20,
            effects: [
                {
                    on: "hover",
                    style: {
                        itemOpacity: 1
                    }
                }
            ]
        }
    ];

    /*
    Line start
     */
    const lineColor = "rgba(200, 30, 15, 1)";

    const Line = ({bars, xScale, yScale}) => {
        let filteredBars = bars && bars
            .filter(bar => bar.data.id === "targets");
        const lineGenerator = line()
            .x(bar => xScale(bar.data.indexValue) + bar.width / 2)
            .y(bar => yScale(bar.data.data.quarterTarget));

        return (
            <Fragment>
                <path
                    d={lineGenerator(filteredBars)}
                    fill="none"
                    stroke={lineColor}
                    style={{pointerEvents: "none"}}
                />
                {
                    filteredBars.map(bar => {
                        let idValueColor = {
                            "id": "quarterTarget",
                            "value": bar.data.data.quarterTarget,
                            "color": "#ffffff"
                        };
                        return (
                            <circle
                                key={bar.key}
                                cx={xScale(bar.data.indexValue) + bar.width / 2}
                                cy={yScale(bar.data.data.quarterTarget)}
                                r={8}
                                fill="white"
                                pointerEvents="auto"
                                stroke={lineColor}
                                tooltip={tooltipBy(idValueColor)}
                                style={{pointerEvents: "auto"}}
                            />
                        );
                    })
                }
            </Fragment>
        );
    };

    /*
    Line end
     */


    return (
        <SimpleCard title="title.developerStats">

            <div style={styles}>
                <Bar
                    width={1200}
                    height={400}
                    margin={{top: 60, right: 120, bottom: 60, left: 80}}
                    data={sortedDevMetrics}
                    keys={["targets", "actuals", "assessment"]}
                    indexBy="sprint"
                    labelTextColor="inherit:darker(2.4)"
                    labelSkipWidth={12}
                    labelSkipHeight={12}
                    enableGridX={false}
                    axisBottom={axisBottom}
                    groupMode="grouped"
                    axisLeft={axisLeft}
                    colorBy={colorBy}
                    colors={{scheme: 'paired'}}
                    theme={theme}
                    legends={legends}
                    tooltip={tooltipBy}
                    layers={["grid", "axes", "bars", Line, "markers", "legends"]}
                />
            </div>
            <hr/>
        </SimpleCard>
    );
}

export default DeveloperSprintResults;
