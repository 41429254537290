import {createSlice} from '@reduxjs/toolkit'

const currentUserSlice = createSlice({
    name: 'currentUser',
    initialState: {
        user: null
    },
    reducers: {
        setCurrentUser: (state, action) => {
            state.user = action.payload.user;
        },
        resetCurrentUser: (state) => {
            state.user = null;
        }
    },
});

const {actions, reducer} = currentUserSlice;

export const {setCurrentUser, resetCurrentUser} = actions;

export default reducer;
