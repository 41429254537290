import resolveData from "@components/HOC/resolveData";
import sprintAssessmentService from "@services/sprint-assessment.service";
import {useTranslation} from "react-i18next";
import {Card, ListGroup, ListGroupItem} from "react-bootstrap";
import {formatDate} from "@utils/date-time.utils";
import {TooltipIconButton} from "@components/Button";
import {noop} from "lodash";
import {useHistory} from "react-router-dom";
import withPrivilege from "@components/HOC/withPrivilege";

function WaitingAssessmentFormListItem({waitingAssessmentForm, handleFillClick = noop}) {
    const {t} = useTranslation();
    return (
        <ListGroupItem>
            <div className="d-flex small">
                <div className="flex-shrink-1 pe-2 pt-2">
                    <TooltipIconButton icon="pencil-ruler" title="tooltip.fillAssessmentForm"
                                       onClick={handleFillClick}/>
                </div>
                <div className="flex-fill">
                    <div>
                        <b>{t('label.sprint')}:</b>
                        <b className="text-primary">{waitingAssessmentForm.sprintName}</b>
                    </div>
                    <div>
                        <b>{t('label.sprintStartDate')}:</b>
                        {formatDate(waitingAssessmentForm.sprintStartDate)}
                    </div>
                    <div>
                        <b>{t('label.sprintEndDate')}:</b>
                        {formatDate(waitingAssessmentForm.sprintEndDate)}
                    </div>
                </div>
            </div>
        </ListGroupItem>
    );
}

function WaitingAssessmentForms(props) {
    const {t} = useTranslation();
    const history = useHistory();

    const {waitingAssessmentForms = []} = props;

    const handleFillClick = (assessmentForm) => {
        const {sprintId} = assessmentForm;
        history.push(`/secure/sprint-assessments/${sprintId}/evaluate`);
    };

    return (
        <Card className="rounded-0">
            <Card.Header>
                <h5>{t('title.waitingAssessmentForms')}</h5>
            </Card.Header>
            {!waitingAssessmentForms.length && (
                <Card.Body>{t('msg.noWaitingAssessmentForms')}</Card.Body>
            )}

            {!!waitingAssessmentForms.length && (
                <ListGroup variant="flush">
                    {waitingAssessmentForms.map((waitingAssessmentForm, index) => (
                        <WaitingAssessmentFormListItem waitingAssessmentForm={waitingAssessmentForm}
                                                       handleFillClick={() => handleFillClick(waitingAssessmentForm)}
                                                       key={index}/>
                    ))}
                </ListGroup>
            )}
        </Card>
    );
}

export default withPrivilege(resolveData(WaitingAssessmentForms, () => {
    return {
        waitingAssessmentForms: sprintAssessmentService.getWaitingAssessmentForms(),
    };
}), ['PRIV_REPLY_ASSESSMENT_FORM']);
