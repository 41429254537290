import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";

function NotFound() {
    const {t} = useTranslation();
    const history = useHistory();
    return (
        <div className="h-100 p-5 text-white bg-danger rounded-0 mb-3">
            <h2>{t('title.notFound')}</h2>
            <p>{t('msg.notFound')}</p>
            <button onClick={() => history.push('/secure/home')}
                    className="btn btn-outline-light btn-primary"
                    type="button">
                {t('button.goHome')}
            </button>
        </div>
    );
}

export default NotFound;
