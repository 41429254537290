import {useTranslation} from "react-i18next";
import {Button} from "react-bootstrap";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import secureRoute from "@components/HOC/secureRoute";

function canSendAssessmentFormToTeam(currentUser, team) {
    if (!team || !team.id) {
        return false;
    }
    if (currentUser.hasPrivilege('PRIV_SEND_ASSESSMENT_FORM_TO_ANY_TEAM')) {
        return true;
    }
    if (currentUser.hasPrivilege('PRIV_SEND_ASSESSMENT_FORM_TO_TEAM')) {
        return currentUser.isTeamMember(team.id) || currentUser.isTeamLeader(team.id);
    }
    return false;
}

function AssessmentDoesNotExist(props) {
    const {t} = useTranslation();
    const {sprint, board, currentUser, team} = props;

    const canUpload = currentUser.hasPrivilege('PRIV_IMPORTS');
    const canCreate = canSendAssessmentFormToTeam(currentUser, team);

    const uploadUrl = `/secure/imports/sprint-assessments?sprintId=${sprint.id}&boardId=${board.id}`;
    const createUrl = `/secure/sprint-assessments/create?sprintId=${sprint.id}&boardId=${board.id}`;

    return (
        <>
            <p>{t('msg.assessmentDoesNotExist')}</p>

            {canUpload && (
                <p>
                    <Button as={Link} to={uploadUrl} variant="primary">
                        <FontAwesomeIcon className="me-2" icon="file-upload"/>
                        {t('button.clickToUpload')}
                    </Button>
                </p>
            )}

            {canCreate && (
                <p>
                    <Button as={Link} to={createUrl} variant="primary">
                        <FontAwesomeIcon className="me-2" icon="file-upload"/>
                        {t('button.clickToSendAssessmentForm')}
                    </Button>
                </p>
            )}
        </>
    );
}

export default secureRoute(AssessmentDoesNotExist);
