import CommentList from "@components/Comment/CommentList";
import Pagination from "@components/Pagination/Pagination";
import {noop} from "lodash";
import {useTranslation} from "react-i18next";

function PaginatedCommentList({comments, page, onPageChange = noop, onDeleteClicked = noop}) {
    const {content = [], total = 0, max = 10} = comments;
    const {t} = useTranslation();

    return (
        <>
            {!content.length && (
                <div className="alert alert-info">
                    {t('msg.noComments')}
                </div>
            )}

            <CommentList comments={content} onDeleteClicked={onDeleteClicked}/>

            <Pagination page={page}
                        max={max}
                        total={total}
                        maxPages={5}
                        onPageClick={clickedPage => onPageChange(clickedPage)}/>
        </>
    );
}

export default PaginatedCommentList;
