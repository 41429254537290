import ReactMarkdown from "react-markdown";
import React from "react";

function Blockquote({node, ...props}) {
    const style = {
        paddingLeft: 10,
        borderLeft: '2px solid #999'
    };
    return (
        <blockquote className="blockquote" style={style} {...props}/>
    );
}

function Code({node, inline, ...props}) {
    const style = inline ? {backgroundColor: '#eef', padding: '2px'} : {};
    return (
        <code style={style} {...props} />
    );
}

function Pre({node, ...props}) {
    const style = {
        backgroundColor: '#eef',
        padding: '10px',
    };
    return (
        <pre style={style} {...props} />
    );
}

export function Markdown({children, ...props}) {
    const components = {
        blockquote: Blockquote,
        code: Code,
        pre: Pre,
    };

    return (
        <ReactMarkdown {...props} components={components}>{children}</ReactMarkdown>
    );
}

export default Markdown;
