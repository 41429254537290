import axios from "axios";

const developerMetricService = {};

developerMetricService.calculateSprintsMetricsDetailOfTheDeveloperBySprints = (teamId, sprintIds, developerId) => {
    const data = {teamId, sprintIds, developerId};
    return axios.post('/api/developers/metrics/sprints', data);
};

developerMetricService.calculateSprintsMetricsDetailOfTheDeveloperByQuarter = (teamId, quarter, developerId) => {
    const data = {teamId, quarter, developerId};
    return axios.post('/api/developers/metrics/quarters', data);
};

developerMetricService.getSprintDeveloperDetailByTeam = (teamId) => {
    return axios.get(`/api/developers/metrics/teams/${teamId}/sprints`);
};

developerMetricService.getMetricDevelopersQuartersById = (developerId) => {
    return axios.get(`/api/developers/metrics/quarters/${developerId}`);
};

export default developerMetricService;
