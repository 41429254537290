import {Form} from "react-bootstrap";
import SubmitButton from "@components/Button/SubmitButton/SubmitButton";
import {useForm} from "react-hook-form";
import {noop} from "lodash";
import * as Yup from "yup";
import {maxLength, minLength, required} from "@utils/validations";
import {yupResolver} from "@hookform/resolvers/yup";
import {useTranslation} from "react-i18next";
import {useMemo} from "react";
import useCurrentUser from "@/hooks/useCurrentUser";
import InputTextareaFormGroup from "@components/Form/InputTextareaFormGroup/InputTextareaFormGroup";

const defaultValues = {
    developerId: null,
    text: '',
};

const validationSchema = Yup.object().shape({
    developerId: Yup.number()
        .typeError(required)
        .required(required),
    text: Yup.string()
        .required(required)
        .max(2000, maxLength)
        .min(20, minLength)
});

function useFilterCurrentUserFromDevelopers(developers) {
    const currentUser = useCurrentUser();
    return useMemo(() => {
        if (!currentUser || !currentUser.developerId) {
            return developers;
        }
        return developers.filter(developer => developer.id !== currentUser.developerId);
    }, [currentUser, developers]);
}

function KudosCreateForm({developers = [], onSubmit = noop}) {
    const {t} = useTranslation();
    const selectableDevelopers = useFilterCurrentUserFromDevelopers(developers);

    const {
        register,
        handleSubmit,
        reset,
        formState: {errors, isValid, isValidating, isSubmitting}
    } = useForm({
        mode: 'all',
        resolver: yupResolver(validationSchema),
        defaultValues,
    });

    const submit = function (data) {
        return Promise.resolve(onSubmit(data))
            .then(() => reset(defaultValues));
    };

    return (
        <form onSubmit={handleSubmit(submit)}>
            <div className="card rounded-0">
                <div className="card-header">
                    <h5>{t('title.createKudos')}</h5>
                </div>

                <div className="card-body">
                    <Form.Group>
                        <Form.Label>{t('label.developerToGiveKudos')}:</Form.Label>
                        <select {...register('developerId')} className="form-select">
                            <option/>
                            {selectableDevelopers.map(developer => (
                                <option value={developer.id} key={developer.id}>{developer.name}</option>
                            ))}
                        </select>
                    </Form.Group>

                    <InputTextareaFormGroup fieldName="text" rows={3}
                                            errors={errors}
                                            register={register}
                                            maxLength={2000}
                                            label={t('label.kudosText')}/>

                    <Form.Group className="mt-2">
                        <SubmitButton text="button.send"
                                      isValid={isValid}
                                      isValidating={isValidating}
                                      isSubmitting={isSubmitting}/>
                    </Form.Group>
                </div>
            </div>
        </form>
    );
}

export default KudosCreateForm;
