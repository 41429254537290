import axios from "axios";

const boardService = {};

boardService.getAllBoards = () => {
    return axios.get('/api/boards');
};

boardService.getBoard = (boardId) => {
    return axios.get(`/api/boards/${boardId}`);
};

boardService.getAllBoardsWithoutTeams = () => {
    return axios.get(`/api/boards/without-teams`);
};

export default boardService;
