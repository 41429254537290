import BackendErrors from "@components/BackendErrors/BackendErrors";
import {Button, Card, Form, ListGroup, ListGroupItem} from "react-bootstrap";
import {FormInvalidFeedback} from "@components/Form";
import ButtonBar from "@components/ButtonBar/ButtonBar";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import sprintService from "@services/sprint.service";
import {withFormik} from "formik";
import bonusService from "@services/bonus.service";
import * as Yup from "yup";
import {atLeastOneCheckbox, required} from "@utils/validations";
import secureRoute from "@components/HOC/secureRoute";
import resolveData from "@components/HOC/resolveData";
import boardService from "@services/board.service";
import {noop} from "bootstrap/js/src/util";

function BonusCalculatorForm(props) {
    const {t} = useTranslation();
    const [sprints, setSprints] = useState([]);
    const {
        boards,
        values,
        touched,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        isValidating,
        setFieldValue,
    } = props;

    useEffect(() => {
        setSprints([]);
        if (!values.boardId) {
            return;
        }
        sprintService.getSprintsByBoardId(values.boardId)
            .then(sprints => {
                const sprintIdsObject = {};
                sprints.forEach(sprint => sprintIdsObject[sprint.id] = false);
                setFieldValue('sprintIds', sprintIdsObject)
                setSprints(sprints || []);
            });
    }, [values.boardId, setFieldValue, setSprints]);

    return <>
        <BackendErrors error={errors.backendErrors}/>

        <form onSubmit={handleSubmit} autoComplete="off">
            <Form.Group className="mb-3" controlId="boardId">
                <Form.Label>{t('label.board')}</Form.Label>
                <Form.Select name="boardId" value={values.boardId}
                             onChange={handleChange} onBlur={handleBlur}
                             isInvalid={errors.boardId && touched.boardId}>
                    <option value="">{t('msg.pleaseSelect')}</option>
                    {boards.map((board, index) => (
                        <option key={index} value={board.id}>{board.name}</option>
                    ))}
                </Form.Select>
                <Form.Text>{t('msg.selectAScrumBoard')}</Form.Text>
                <FormInvalidFeedback errors={errors.boardId}/>
            </Form.Group>

            {!!values.boardId && (
                <>
                    <Card className="mb-3">
                        <Card.Header>{t('title.sprints')}</Card.Header>

                        <ListGroup variant="flush">
                            {sprints.map((sprint, index) => (
                                <ListGroupItem key={sprint.id}>
                                    <Form.Check>
                                        <Form.Check.Input type="checkbox" name={`sprintIds[${sprint.id}]`}
                                                          id={`sprint-check-${index}`}
                                                          className="me-1"
                                                          checked={values.sprintIds[sprint.id]}
                                                          value={true}
                                                          onChange={handleChange}
                                                          onBlur={handleBlur}
                                                          isInvalid={errors.sprintIds && touched.sprintIds}/>
                                        <Form.Check.Label htmlFor={`sprint-check-${index}`}>
                                            {sprint.name}
                                        </Form.Check.Label>
                                    </Form.Check>
                                </ListGroupItem>
                            ))}
                            {errors.sprint && touched.sprint && (
                                <ListGroupItem>
                                    <FormInvalidFeedback touched={touched.sprint} errors={errors.sprint}/>
                                </ListGroupItem>
                            )}
                        </ListGroup>
                    </Card>

                    <Form.Group className="mb-3" controlId="totalBonusAmount">
                        <Form.Label>{t('label.totalBonusAmount')}</Form.Label>
                        <Form.Control type="number" name="totalBonusAmount" value={values.totalBonusAmount}
                                      maxLength={255}
                                      onChange={handleChange} onBlur={handleBlur}
                                      isInvalid={errors.totalBonusAmount && touched.totalBonusAmount}/>
                        <Form.Text>{t('msg.totalBonusAmount')}</Form.Text>
                        <FormInvalidFeedback errors={errors.totalBonusAmount}/>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="storyPointsPercentage">
                        <Form.Label>{t('label.storyPointsPercentage')}</Form.Label>
                        <Form.Control type="number" name="storyPointsPercentage"
                                      value={values.storyPointsPercentage} maxLength={255}
                                      onChange={handleChange} onBlur={handleBlur}
                                      isInvalid={errors.storyPointsPercentage && touched.storyPointsPercentage}/>
                        <Form.Text>{t('msg.storyPointsPercentage')}</Form.Text>
                        <FormInvalidFeedback errors={errors.storyPointsPercentage}/>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="assessmentPercentage">
                        <Form.Label>{t('label.assessmentPercentage')}</Form.Label>
                        <Form.Control type="number" name="assessmentPercentage" value={values.assessmentPercentage}
                                      maxLength={255}
                                      onChange={handleChange} onBlur={handleBlur}
                                      isInvalid={errors.assessmentPercentage && touched.assessmentPercentage}/>
                        <Form.Text>{t('msg.assessmentPercentage')}</Form.Text>
                        <FormInvalidFeedback errors={errors.assessmentPercentage}/>
                    </Form.Group>

                    <ButtonBar>
                        <Button variant="primary" type="submit"
                                disabled={isSubmitting || isValidating}>{t('button.calculate')}</Button>
                    </ButtonBar>
                </>
            )}
        </form>
    </>;
}

const WithFormik = withFormik({
    mapPropsToValues: () => {
        return {
            boardId: '',
            sprintIds: {},
            totalBonusAmount: '',
            storyPointsPercentage: '75',
            assessmentPercentage: '25'
        };
    },

    validationSchema: props => props.validationSchema,

    handleSubmit: (values, {props, setErrors}) => {
        const {setBonusResult = noop} = props;
        return bonusService.calculateBonus(values)
            .then(result => setBonusResult(result))
            .catch(errors => {
                setErrors({backendErrors: errors});
                return Promise.reject(errors);
            });
    },

    displayName: 'CalculateBonusForm',
})(BonusCalculatorForm);

function WithValidationSchema(props) {
    const validationSchema = Yup.object().shape({
        boardId: Yup.number()
            .required(required),
        sprintIds: Yup.object()
            .required(required)
            .test('sprintIds', null, (obj) => {
                const keys = Object.keys(obj);
                const indexOfFirstTrue = keys.findIndex(key => obj[key]);
                if (indexOfFirstTrue >= 0) {
                    return true;
                }

                return new Yup.ValidationError(
                    atLeastOneCheckbox(),
                    null,
                    'sprintIds'
                );
            }),
        totalBonusAmount: Yup.number()
            .required(required),
        storyPointsPercentage: Yup.number()
            .required(required),
        assessmentPercentage: Yup.number()
            .required(required),
    });
    return <WithFormik validationSchema={validationSchema} {...props} />
}

export default secureRoute(resolveData(WithValidationSchema, () => {
    return {
        boards: boardService.getAllBoards()
    }
}));
