import React from "react";
import {TooltipIconButton} from "@components/Button";

function DeleteButton({url, onClick, size, title, variant = 'danger', icon = 'trash', disabled = false, ...props}) {
    return <TooltipIconButton url={url}
                              onClick={onClick}
                              size={size}
                              variant={variant}
                              icon={icon}
                              title={title}
                              disabled={disabled}
                              {...props}/>;
}

export default DeleteButton;
