import React from "react";
import {Alert} from "react-bootstrap";

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {hasError: false};
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return {hasError: true};
    }

    componentDidCatch(error, errorInfo) {
        console.log(error);
        console.log(errorInfo);
        this.setState({hasError: true});
    }

    render() {
        if (this.state.hasError) {
            return <Alert variant="danger">Something went wrong.</Alert>;
        }
        return this.props.children;
    }
}

export default ErrorBoundary;
