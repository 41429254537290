import userService from "@services/user.service";
import sprintAssessmentService from "@services/sprint-assessment.service";
import moment from "moment";
import assessmentFormTemplateService from "@services/assessment-form-template.service";
import * as Yup from "yup";

export const required = function () {
    return {
        code: 'error.required'
    };
}

export const maxLength = function (params) {
    return {
        code: 'error.maxLength',
        max: params.max
    }
};

export const minLength = function (params) {
    return {
        code: 'error.minLength',
        min: params.min
    }
};

export const atLeastOneCheckbox = function () {
    return {
        code: 'error.atLeastOneShouldBeChecked'
    }
};

export const arrayMinElements = function (params) {
    return {
        code: 'error.arrayMinElements',
        min: params.min
    }
};

const cancellableDebouncedAsyncValidator = (asyncValidatorFn, debounceTime) => {
    let timer = null;
    let lastResolve = null;

    const setupTimer = (argv) => {
        if (lastResolve) {
            lastResolve = null;
        }

        if (timer != null) {
            clearTimeout(timer);
            timer = null;
        }

        return new Promise((resolve, reject) => {
            lastResolve = resolve;

            timer = setTimeout(() => {
                timer = null;
                asyncValidatorFn.apply(this, argv)
                    .then((data) => {
                        if (lastResolve !== resolve) {
                            return;
                        }
                        lastResolve = null;
                        resolve(data);
                    })
                    .catch(reject);
            }, debounceTime);
        });
    };

    return function () {
        return setupTimer(arguments);
    };
};

const cacheable = (fn, cacheKeyFn) => {
    const cache = {};

    function CacheEntry(value, expires) {
        this.value = value;
        this.expires = expires;
    }

    return function () {
        const cacheKey = cacheKeyFn.apply(this, arguments);
        const cacheEntry = cache[cacheKey];
        if (cacheEntry && cacheEntry.expires > moment().valueOf()) {
            return Promise.resolve(cacheEntry.value);
        }
        return fn.apply(this, arguments)
            .then(result => {
                cache[cacheKey] = new CacheEntry(result, moment().valueOf() + 10000);
                return Promise.resolve(result);
            });
    };
};

export const checkEmailExistence = function (userId, debounceTime) {
    const validator = (email) => {
        if (!email) {
            return Promise.resolve(true);
        }
        return userService.isEmailAvailable(email, userId)
            .then(result => Promise.resolve(result.result));
    };

    return cancellableDebouncedAsyncValidator(validator, debounceTime);
};

export const checkAssessmentCategoryNameExistence = function (categoryId, debounceTime) {
    const validator = (name) => {
        if (!name) {
            return Promise.resolve(true);
        }
        return sprintAssessmentService.isAssessmentCategoryNameAvailable(categoryId, name)
            .then(result => Promise.resolve(result.result));
    };

    const cacheableValidator = cacheable(validator, name => name);
    return cancellableDebouncedAsyncValidator(cacheableValidator, debounceTime);
};

export const checkAssessmentFormTemplateNameExistence = function (templateId, debounceTime) {
    const validator = (name) => {
        if (!name) {
            return Promise.resolve(true);
        }
        return assessmentFormTemplateService.isAssessmentFormTemplateNameAvailable(templateId, name)
            .then(result => Promise.resolve(result.result));
    };

    const cacheableValidator = cacheable(validator, name => name);
    return cancellableDebouncedAsyncValidator(cacheableValidator, debounceTime);
};


export const validJson = (fieldName) => (value) => {
    if (!value) {
        return true;
    }
    try {
        JSON.parse(value);
        return true;
    } catch (e) {
        return new Yup.ValidationError(
            {code: 'error.invalidJson'},
            null,
            fieldName
        );
    }
};
