import {withTranslation} from "react-i18next";

export default withTranslation()((props) => {
    const {t} = props;
    return (
        <div>
            <hr/>
            {t('msg.footerTxt')}
        </div>
    );
});
