import KudosSearchForm from "@views/Kudos/KudosSearchForm";
import Breadcrumb from "@components/Breadcrumb/Breadcrumb";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import KudosCreateForm from "@views/Kudos/KudosCreateForm";
import resolveData from "@components/HOC/resolveData";
import KudosScene from "@views/Kudos/KudosScene";
import kudosService from "@services/kudos.service";
import moment from "moment";
import toastService from "@services/toast.service";
import useCurrentUser from "@/hooks/useCurrentUser";

const defaultSearchValues = {
    authorUserId: '',
    relatedDeveloperId: '',
    period: moment().format('YYYY-MM'),
};

function useKudosCreatePrivilege() {
    const currentUser = useCurrentUser();
    return useMemo(() => currentUser && currentUser.hasPrivilege('PRIV_ADD_KUDOS'), [currentUser]);
}

function KudosDashboard({users, developers}) {
    const currentUser = useCurrentUser();
    const [kudosItems, setKudosItems] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchQuery, setSearchQuery] = useState(defaultSearchValues);
    const canCreateKudos = useKudosCreatePrivilege();

    const reloadKudos = useCallback((searchQuery) => {
        setLoading(true);
        kudosService.getKudosBySearch(searchQuery)
            .then(kudosList => {
                kudosList.forEach(kudos => kudos.canBeDeleted = kudosService.isAuthorizedToDelete(currentUser, kudos));
                setKudosItems(kudosList);
            })
            .finally(() => setLoading(false));
    }, [currentUser]);

    useEffect(() => reloadKudos(searchQuery), [reloadKudos, searchQuery]);

    const submitKudos = (data) => {
        return kudosService.createKudos(data)
            .then(() => {
                toastService.success('msg.kudosCreated');
                setSearchQuery({...defaultSearchValues});
            });
    };

    const deleteKudos = (kudosItem) => {
        kudosService.deleteKudos(kudosItem.id)
            .then(() => {
                toastService.success('msg.kudosDeleted');
                setSearchQuery({...searchQuery});
            });
    };

    return (
        <>
            <Breadcrumb title="menu.kudos"/>
            <KudosSearchForm users={users}
                             developers={developers}
                             defaultValues={defaultSearchValues}
                             currentValues={searchQuery}
                             onSubmit={newSearchQuery => setSearchQuery({...newSearchQuery})}/>
            <KudosScene kudos={kudosItems} loading={loading}
                        onDeleteClick={kudosItem => deleteKudos(kudosItem)}/>
            {canCreateKudos && <>
                <hr/>
                <KudosCreateForm developers={developers} onSubmit={submitKudos}/>
            </>}
        </>
    );
}

export default resolveData(KudosDashboard, () => {
    const $people = kudosService.getPeople();
    return {
        users: $people.then(data => data.users),
        developers: $people.then(data => data.developers),
    };
});
