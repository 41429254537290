import Comment from "@components/Comment/Comment";
import {noop} from "lodash";

function CommentList({comments, onDeleteClicked = noop}) {
    return (
        <div>
            {comments.map(comment => (
                <Comment comment={comment}
                         key={comment.id}
                         onDeleteClicked={onDeleteClicked}/>
            ))}
        </div>
    );
}

export default CommentList;
