import axios from "axios";
import teamTargetService from "./team-target.service";

const teamMetricService = {};

teamMetricService.calculateSprintsMetricsDetailOfTheTeamBySprints = (teamId, sprintIds) => {
    const data = {teamId, sprintIds};
    return axios.post('/api/teams/metrics/sprints', data);
};

teamMetricService.calculateSprintsMetricsDetailOfTheTeamByQuarter = (teamId, quarter) => {
    const data = {teamId, quarter};
    return axios.post('/api/teams/metrics/quarters', data);
};

teamTargetService.getTeamMetricQuartersByTeamId = (teamId) => {
    return axios.get(`/api/teams/metrics/quarters/${teamId}`);
};

teamMetricService.getSprintsOfTeamByTeamId = (teamId) => {
    return axios.get(`/api/teams/metrics/teams/${teamId}/sprints`);
};

export default teamMetricService;
