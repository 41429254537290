import React, {useMemo, useState} from "react";
import {ellipsis} from "@utils/string-utils";
import {useTranslation} from "react-i18next";

function EllipsisTextWithContinueReading({text = '', maxLength = 150}) {
    const shortText = useMemo(() => ellipsis(text, maxLength), [text, maxLength]);
    const isShortened = shortText !== text;
    const [showShortened, setShowShortened] = useState(true);
    const {t} = useTranslation();

    const toggleShortening = () => {
        setShowShortened(!showShortened);
    };

    return (
        <>
            {showShortened ? shortText : text}
            {isShortened && (
                <>
                    &nbsp;
                    <button className="btn btn-link link-primary p-0" onClick={toggleShortening}>
                        {t(showShortened ? 'label.continueReading' : 'label.showLess')}
                    </button>
                </>
            )}
        </>
    );
}

export default EllipsisTextWithContinueReading;
