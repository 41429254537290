import {Card} from "react-bootstrap";

function ButtonBar({children}) {
    return (
        <Card text="dark" bg="light" className="mb-3 rounded-0">
            <Card.Body className="text-end">
                {children}
            </Card.Body>
        </Card>
    );
}

export default ButtonBar;
