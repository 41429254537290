import axios from "axios";
import {resetCurrentUser, store} from "@store";
import userService from "@services/user.service";
import toastService from "@services/toast.service";

const GLOBAL_ERROR = 'error.global';

const buildError = (code = GLOBAL_ERROR, isAuthError = false) => {
    return {code, isAuthError};
};

const convertArgumentsArray = (array) => {
    array = array || [];
    const result = {};
    array.forEach((value, index) => result['arg' + index] = value);
    return result;
};

const convertErrorArguments = (data) => {
    data.globalErrorArguments = data.globalErrorArguments || {};
    for (let globalErrorArgumentsKey in data.globalErrorArguments) {
        data.globalErrorArguments[globalErrorArgumentsKey] = convertArgumentsArray(
            data.globalErrorArguments[globalErrorArgumentsKey]);
    }
    data.arguments = convertArgumentsArray(data.arguments);
    data.fieldErrors = data.fieldErrors || [];
    data.fieldErrors.forEach(fieldError => convertArgumentsArray(fieldError.arguments));
};

const getErrorCode = (error) => {
    let response = error.response;
    if (!response) {
        return buildError();
    }

    if (response.status === 401) {
        return buildError('error.authenticationRequired', true);
    }

    if (response && response.data && response.data.code) {
        convertErrorArguments(response.data);
        return {...response.data, isAuthError: false};
    }

    return buildError();
}

const displayToastAndGetErrorCode = (error) => {
    const errorCode = getErrorCode(error);
    toastService.danger(errorCode.code);
    return errorCode;
}

const responseDataUnWrapperInterceptor = (response) => Promise.resolve(response.data);

const errorHandlerInterceptor = (error) => {
    const errorCode = displayToastAndGetErrorCode(error);
    if (errorCode.isAuthError) {
        userService.currentUser = null;
        store.dispatch(resetCurrentUser());
    }
    return Promise.reject(errorCode);
};

const setupInterceptors = () => {
    axios.interceptors.response.use(responseDataUnWrapperInterceptor, errorHandlerInterceptor);
};

export default setupInterceptors;
