import {Button, Modal} from "react-bootstrap";
import {noop} from "lodash";
import {Trans, useTranslation} from "react-i18next";

function ConfirmDialog({
                           show = true,
                           title = 'title.areYouSure',
                           titleParams = {},
                           message = 'title.areYouSure',
                           messageParams = {},
                           handleConfirm = noop,
                           handleCancel = noop
                       }) {
    const {t} = useTranslation();

    return (
        <>
            <Modal
                show={show}
                onHide={handleCancel}
                backdrop="static"
                size="lg"
                keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>{t(title, titleParams)}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Trans i18nKey={message}
                           values={messageParams}
                           components={{b: <b/>, br: <br/>}}/>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCancel}>
                        {t("button.no")}
                    </Button>
                    <Button variant="primary" onClick={handleConfirm}>
                        {t("button.yes")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ConfirmDialog;
