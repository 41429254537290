import React from "react";
import IconButton from "@components/Button/IconButton/IconButton";
import TooltipWrapper from "@components/Button/TooltipWrapper/TooltipWrapper";

function TooltipIconButton({url, onClick, size, title, variant, text, disabled, icon, ...props}) {
    const button = <IconButton url={url}
                               onClick={onClick}
                               size={size}
                               variant={variant}
                               text={text}
                               disabled={disabled}
                               icon={icon}
                               {...props}/>;

    if (title) {
        return <TooltipWrapper title={title}>{button}</TooltipWrapper>;
    }

    return button;
}

export default TooltipIconButton;
