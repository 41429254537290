import withPrivilege from "@components/HOC/withPrivilege";
import {useTranslation} from "react-i18next";
import {useEffect, useMemo, useState} from "react";
import Loading from "@components/Loading/Loading";
import userSessionService from "@services/user-session.service";
import {Card, ListGroup, ListGroupItem} from "react-bootstrap";
import useCurrentUser from "@/hooks/useCurrentUser";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import TimeAgo from "@components/TimeAgo/TimeAgo";

function UserFullNameWithLink({userId, fullName}) {
    const currentUser = useCurrentUser();
    const asLink = useMemo(() => {
        return currentUser && currentUser.hasPrivilege('PRIV_USER_MANAGEMENT');
    }, [currentUser]);

    if (!asLink) {
        return fullName;
    }

    return <Link to={`/secure/users/${userId}`}>{fullName}</Link>
}

function ActiveUserListItem({user: {userId, fullName, lastActionTimestamp}}) {
    return (
        <ListGroupItem className="d-flex">
            <div className="flex-grow-1">
                <UserFullNameWithLink fullName={fullName} userId={userId}/>
            </div>
            <div className="flex-shrink-1">
                <TimeAgo date={lastActionTimestamp}/>
            </div>
        </ListGroupItem>
    );
}

function ActiveUserSessionsView({activeUsers}) {
    return (
        <ListGroup variant="flush">
            {activeUsers.map((user) => (
                <ActiveUserListItem key={user.userId} user={user}/>
            ))}
        </ListGroup>
    );
}

function ActiveUserSessionsWrapper() {
    const {t} = useTranslation();
    const [activeUsers, setActiveUsers] = useState(null);

    const reload = () => {
        setActiveUsers(null);
        userSessionService.getLatestActiveUserSessions(10)
            .then(result => setActiveUsers(result));
    };

    useEffect(() => reload(), []);

    return (
        <Card className="rounded-0 mt-4">
            <Card.Header className="d-flex">
                <h5 className="flex-grow-1">{t('title.activeUsers')}</h5>
                <a className="flex-shrink-1 text-primary" onClick={() => reload()}>
                    <FontAwesomeIcon icon="refresh"/>
                </a>
            </Card.Header>
            {!activeUsers && (
                <Card.Body>
                    <Loading/>
                </Card.Body>
            )}

            {!!activeUsers && (
                <ActiveUserSessionsView activeUsers={activeUsers}/>
            )}
        </Card>
    );
}

export default withPrivilege(ActiveUserSessionsWrapper, ['PRIV_VIEW_ACTIVE_USER_SESSIONS']);
