import resolveData from "@components/HOC/resolveData";
import sprintAssessmentService from "@services/sprint-assessment.service";
import {useTranslation} from "react-i18next";
import AssessmentDoesNotExist from "@views/Sprints/AssessmentDoesNotExist/AssessmentDoesNotExist";
import SimpleCard from "@components/SimpleCard/SimpleCard";
import React from "react";
import TableHolder from "@components/TableHolder/TableHolder";
import {Table} from "react-bootstrap";
import AssessmentIconList from "@components/AssessmentIconList/AssessmentIconList";

function SprintDeveloperAssessmentResults(props) {
    const {t} = useTranslation();
    const {assessment, board, sprint} = props;
    const {
        voteTypes = [],
        categoryVotes = [],
        voteTypeCountMap = {},
        teamRate = 0
    } = assessment;
    const title = 'title.developerAssessment';

    if (categoryVotes.length <= 0 || voteTypes.length <= 0) {
        return (
            <SimpleCard title={title}>
                <AssessmentDoesNotExist sprint={sprint} board={board}/>
            </SimpleCard>
        );
    }

    return (
        <SimpleCard title={title}>
            <TableHolder>
                <Table striped>
                    <thead>
                    <tr>
                        <th/>
                        <th>{t('label.ratioOfTeamCollaboration')}</th>
                        <th>{t('label.votes')}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {categoryVotes.map(({category, teamRate, voteTypeCountMap}) => (
                        <tr key={category.id}>
                            <td>{category.name}</td>
                            <td>% {teamRate}</td>
                            <td className="nowrap">
                                <AssessmentIconList voteTypes={voteTypes}
                                                    voteTypeCountMap={voteTypeCountMap}/>
                            </td>
                        </tr>
                    ))}
                    <tr>
                        <td>{t('label.total')}</td>
                        <td>% {teamRate}</td>
                        <td>
                            <AssessmentIconList voteTypes={voteTypes}
                                                voteTypeCountMap={voteTypeCountMap}
                                                newLineForEachType/>
                        </td>
                    </tr>
                    </tbody>
                </Table>
            </TableHolder>
        </SimpleCard>
    );
}

export default resolveData(SprintDeveloperAssessmentResults, (props) => {
    const {sprint, developer} = props;

    return {
        assessment: sprintAssessmentService.getDeveloperSprintAssessmentSummary(sprint.id, developer.id)
    }
});
