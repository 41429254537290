import React, {useEffect, useState} from "react";
import Breadcrumb from "@components/Breadcrumb/Breadcrumb";
import secureRoute from "@components/HOC/secureRoute";
import resolveData from "@components/HOC/resolveData";
import remountOnLocationChange from "@components/HOC/remountOnLocationChange";
import {Alert, Card, Col, Form, Row} from "react-bootstrap";
import FormInvalidFeedback from "@components/Form/FormInvalidFeedback/FormInvalidFeedback";
import {useTranslation} from "react-i18next";
import {withFormik} from "formik";
import * as Yup from "yup";
import {required} from "@utils/validations";
import Select from 'react-select';
import {IconButton} from "@components/Button";
import teamService from "@services/team.service";
import developerService from "@services/developer.service";
import {StyledEngineProvider} from '@mui/material/styles';
import SimpleCard from "@components/SimpleCard/SimpleCard";
import DeveloperQuarterTargets from "@views/Metrics/DeveloperTargets/DeveloperQuarterTargets/DeveloperQuarterTargets"
import developerTargetService from "@services/developer-target.service";
import {sortBy} from "@utils/array-utils";

function DeveloperTargetDetail(props) {
    const {t} = useTranslation();
    const [developers, setDevelopers] = useState([]);
    const [selectedDeveloper, setSelectedDeveloper] = useState(null);
    const [quarters, setQuarters] = useState([]);
    const [selectedQuarter, setSelectedQuarter] = useState(null);
    const [selectedTeam, setSelectedTeam] = useState(null);
    const [alertMessage, setAlertMessage] = useState(null);
    const [developerTargets, setDeveloperTargets] = useState([]);

    const {
        teams,
        values,
        handleChange,
        touched,
        errors,
        handleBlur,
    } = props;

    let devTeams = teams.map((team) => ({label: team.name, value: team.id}));

    useEffect(() => {
        setDevelopers([]);
        if (!selectedTeam) {
            return;
        }
        developerService.getTeamDevelopersByTeamId(selectedTeam)
            .then(developers => {
                let devs = developers.map(dev => ({label: dev.fullName, value: dev.id}))
                let sortedDevs = [{label: t('msg.pleaseSelect'), value: ''}, ...devs];
                setDevelopers(sortedDevs);
            });
    }, [selectedTeam]);

    useEffect(() => {
        setQuarters(() => []);
        if (!selectedDeveloper) {
            return;
        }
        developerTargetService.getDevelopersQuartersById(selectedDeveloper)
            .then(quarters => {
                let availableQuarters = quarters
                    .filter(q => q !== null)
                    .map(quarter => {
                    let tokens = quarter.split(':');
                    let quarterLabel = tokens[0] + tokens[1].split('-')[2];
                    return ({label: quarterLabel, value: quarter});
                })
                availableQuarters.sort(sortBy('label', false, (q) => q));
                let devQuarters = [{label: t('msg.pleaseSelect'), value: ''}, ...availableQuarters];
                setQuarters(devQuarters);
            });
    }, [selectedDeveloper]);

    const handleSearch = () => {
        if (selectedTeam && selectedDeveloper && selectedQuarter && values.target) {
            setAlertMessage(t('msg.selectACriteria'));
            return;
        }

        let targetValue = values.target && values.target > 0 ? values.target : null;
        developerTargetService.searchDeveloperTargets(selectedTeam, selectedDeveloper, selectedQuarter, targetValue)
            .then(allDeveloperTargets => {
                let targets = allDeveloperTargets.map((target) => ({
                    id: target.id,
                    developer: target.developerName,
                    quarter: target.quarter,
                    target: target.target,
                    createDate: target.createDate,
                    updateDate: target.updateDate,
                    creator: target.creatorFullname,
                    updater: target.updaterFullname
                }));
                setDeveloperTargets(targets);
            })
            .catch(errors => {
                return Promise.reject(errors);
            });
    }

    const handleSubmit = () => {
        if (!selectedTeam) {
            setAlertMessage(t('msg.selectATeam'));
            return;
        }
        if (!selectedDeveloper) {
            setAlertMessage(t('msg.selectADeveloper'));
            return;
        }
        if (!selectedQuarter) {
            setAlertMessage(t('msg.selectAQuarter'));
            return;
        }
        if (!values.target && values.target <= 0) {
            setAlertMessage(t('msg.target'));
            return;
        }

        return developerTargetService.addTargetToDeveloper(selectedDeveloper, values.target, selectedQuarter)
            .then((target) => {
                let newTarget = {
                    id: target.id,
                    developer: target.developerName,
                    quarter: target.quarter,
                    target: target.target,
                    createDate: target.createDate,
                    updateDate: target.updateDate,
                    creator: target.creatorFullname,
                    updater: target.updaterFullname
                };
                setDeveloperTargets([...developerTargets, newTarget]);
                const newQuarterList = quarters.filter((q) => q.value !== selectedQuarter);
                newQuarterList.sort(sortBy('label', false, (q) => q));
                setQuarters(newQuarterList);
                values.target = "";
            })
            .catch(errors => {
                return Promise.reject(errors);
            });
    }

    return (
        <>
            <Breadcrumb title="title.developerMetricTargets"
                        items={[
                            {title: 'menu.developerTargets', path: '/secure/developer/targets'}
                        ]}/>

            <SimpleCard>
                <form autoComplete="off">
                    {alertMessage && alertMessage !== '' &&
                    <Alert variant="warning">
                        {alertMessage}
                    </Alert>
                    }
                    <Row>
                        <Col md={3}>
                            <Form.Group className="mb-3" controlId="teamId">
                                <Form.Label>{t('label.team')}</Form.Label>
                                <Select
                                    id="teamId"
                                    name="teamId"
                                    className="mt-4 col-md-12 col-offset-4"
                                    defaultMenuIsOpen={false}
                                    defaultValue={{label: t('msg.pleaseSelect'), value: ''}}
                                    onBlur={handleBlur}
                                    onChange={team => setSelectedTeam(team.value)}
                                    options={devTeams}
                                    isInvalid={errors.teamId && touched.teamId}
                                />
                                {!selectedTeam &&
                                <Form.Text>{t('msg.selectATeam')}</Form.Text>
                                }
                                <FormInvalidFeedback errors={errors.teamId}/>
                            </Form.Group>
                        </Col>

                        <Col md={3}>
                            <Form.Group className="mb-9" controlId="developerId">
                                <Form.Label>{t('label.developers')}</Form.Label>
                                <Select
                                    id="developerId"
                                    name="developerId"
                                    className="mt-4 col-md-9 col-offset-4"
                                    options={developers}
                                    onBlur={handleBlur}
                                    onChange={(developer) => setSelectedDeveloper(developer.value)}
                                    defaultValue={{label: t('msg.pleaseSelect'), value: ''}}
                                    closeMenuOnSelect={true}
                                    isInvalid={errors.developerId && touched.developerId}
                                />
                                {!selectedDeveloper &&
                                <Form.Text>{t('msg.selectADeveloper')}</Form.Text>
                                }
                                <FormInvalidFeedback errors={errors.developerIds}/>
                            </Form.Group>
                        </Col>
                        <Col md={2}>
                            <Form.Group className="mb-9" controlId="quarters">
                                <Form.Label>{t('label.quarters')}</Form.Label>
                                <Select
                                    id="quarters"
                                    name="quarters"
                                    className="mt-4 col-md-12 col-offset-4"
                                    options={quarters}
                                    onBlur={handleBlur}
                                    onChange={(quarter) => setSelectedQuarter(quarter.value)}
                                    defaultValue={{label: t('msg.pleaseSelect'), value: ''}}
                                    closeMenuOnSelect={true}
                                />
                                {!selectedQuarter &&
                                <Form.Text>{t('msg.selectAQuarter')}</Form.Text>
                                }
                                <FormInvalidFeedback errors={errors.quarters}/>
                            </Form.Group>
                        </Col>
                        <Col md={2}>
                            <Form.Group className="mb-9" controlId="target">
                                <Form.Label>{t('label.targetStoryPoints')}</Form.Label>
                                <Form.Control type="number" min="0" name="target"
                                              className="mt-4 col-md-6 col-offset-4"
                                              maxLength={2} value={values.target}
                                              onChange={handleChange} onBlur={handleBlur}
                                              isInvalid={errors.target && touched.target}/>
                                {!values.target &&
                                <Form.Text>{t('msg.target')}</Form.Text>
                                }
                            </Form.Group>
                        </Col>
                        <Col md={2}>
                            <Form.Group className="mb-9">
                                <Form.Label></Form.Label>
                                <div className="mt-4 col-md-6 col-offset-4">
                                    <IconButton icon="fas fa-plus" text={t('button.add')} size="lg"
                                                onClick={handleSubmit}
                                                disabled={(!selectedTeam || !selectedDeveloper || !selectedQuarter || !values.target || values.target <= 0)}/>
                                </div>
                            </Form.Group>
                        </Col>
                        <Col md={2}>
                            <Form.Group className="mb-9">
                                <Form.Label></Form.Label>
                                <div className="mt-4 col-md-6 col-offset-4">
                                    <IconButton icon="fas fa-search" text={t('button.search')} size="lg"
                                                onClick={handleSearch}
                                                disabled={(!selectedTeam && !selectedDeveloper && !selectedQuarter && !values.target)}/>
                                </div>
                            </Form.Group>
                        </Col>
                    </Row>
                </form>
            </SimpleCard>

            <div>
                <Card border={'primary'} className="mt-3 rounded-0">
                    <Card.Header>
                        <h3>{t('title.developerMetricTargets')}</h3>
                    </Card.Header>
                    <Card.Body>
                        <StyledEngineProvider injectFirst>
                            <DeveloperQuarterTargets developerTargets={developerTargets}/>
                        </StyledEngineProvider>
                    </Card.Body>
                </Card>
            </div>
        </>
    );
}

const WithFormik = withFormik({
    mapPropsToValues: () => {
        return {
            teamId: '',
            developerId: '',
            quarters: '',
            target: ''
        };
    },
    validationSchema: props => props.validationSchema,
    displayName: 'DeveloperTargetDetailForm',
})(DeveloperTargetDetail);

function WithValidationSchema(props) {
    const validationSchema = Yup.object().shape({
        teamId: Yup.number()
            .required(required),
        developerId: Yup.number()
            .required(required),
        quarters: Yup.string()
            .required(required),
        target: Yup.number()
            .required(required)
    });
    return <WithFormik validationSchema={validationSchema} {...props} />
}

const DeveloperTargetDetailWithResolvedData = resolveData(WithValidationSchema, () => {
    return {
        teams: teamService.getAllTeams()
    }
});

export default secureRoute(remountOnLocationChange(DeveloperTargetDetailWithResolvedData));
