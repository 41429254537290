export function getTrueValuedKeys(obj) {
    return Object.keys(obj).reduce((a, c) => {
        if (obj[c]) {
            a.push(c);
        }
        return a;
    }, []);
}

export function formatNumber(num, minimumIntegerDigits, maximumFractionDigits) {
    num = num || 0;
    minimumIntegerDigits = minimumIntegerDigits || 0;
    maximumFractionDigits = maximumFractionDigits || 2;
    return num.toLocaleString(undefined, {maximumFractionDigits, minimumIntegerDigits});
}
