import React, {useState} from "react";
import Breadcrumb from "@components/Breadcrumb/Breadcrumb";
import teamService from "@services/team.service";
import {Card, Col, ListGroup, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {DeleteButton, TooltipIconButton} from "@components/Button";
import toastService from "@services/toast.service";
import AddDeveloperToTeamForm from "./AddDeveloperToTeamForm";
import secureRoute from "@components/HOC/secureRoute";
import resolveData from "@components/HOC/resolveData";
import remountOnLocationChange from "@components/HOC/remountOnLocationChange";
import developerService from "@services/developer.service";

function TeamDetail({team: teamInProps, currentUser}) {
    const [team, setTeam] = useState(teamInProps);
    const canEdit = currentUser.hasPrivilege('PRIV_EDIT_TEAMS');

    const setDevelopers = (developers) => {
        developers = (developers || []).sort(developerService.COMPARE_BY_NAME);
        setTeam({...team, developers});
    };

    const removeDeveloperFromTeam = (developer) => {
        teamService.removeDeveloperFromTeam(team.id, developer.id)
            .then(developer => {
                removeDeveloperFromTeamInState(developer);
                return developer;
            })
            .then(developer => toastService.success('msg.developerRemovedFromTeam', developer));
    };

    const removeDeveloperFromTeamInState = (developer) => {
        const newDevelopers = team.developers.filter(teamDeveloper => {
            return teamDeveloper.id !== developer.id;
        });
        setDevelopers(newDevelopers);
    };

    const makeTeamLeader = (developer) => {
        teamService.makeDeveloperTeamLeader(team.id, developer.id)
            .then(() => setTeam({...team, leaderDeveloperId: developer.id}));
    };

    const addDeveloperToTeamInState = (developer) => {
        const newDevelopers = [...team.developers, developer];
        setDevelopers(newDevelopers);
    };

    return (
        <>
            <Breadcrumb title="title.teamDetail" titleParams={team}
                        items={[{title: 'menu.teams', path: '/secure/teams'}]}/>

            <TeamGeneralInfo team={team}/>

            <TeamDeveloperList team={team}
                               onMakeTeamLeader={(developer) => makeTeamLeader(developer)}
                               onDeveloperRemoved={(developer) => removeDeveloperFromTeam(developer)}/>

            {canEdit && (
                <AddDeveloperToTeamForm
                    team={team}
                    onDeveloperAdded={(developer) => addDeveloperToTeamInState(developer)}/>
            )}
        </>
    );
}

function TeamGeneralInfo({team}) {
    const {t} = useTranslation();
    return (
        <Card className="mb-3">
            <Card.Body>
                <Row as="dl">
                    <Col as="dt" sm={3}>{t('label.teamId')}</Col>
                    <Col as="dd" sm={9}>{team.id}</Col>
                </Row>
                <Row as="dl">
                    <Col as="dt" sm={3}>{t('label.teamName')}</Col>
                    <Col as="dd" sm={9}>{team.name}</Col>
                </Row>
                <Row as="dl">
                    <Col as="dt" sm={3}>{t('label.teamDescription')}</Col>
                    <Col as="dd" sm={9}>{team.description}</Col>
                </Row>
                <Row as="dl">
                    <Col as="dt" sm={3}>{t('label.boardName')}</Col>
                    <Col as="dd" sm={9}>{team.boardName}</Col>
                </Row>
            </Card.Body>
        </Card>
    );
}

function TeamDeveloperList({team, onDeveloperRemoved, onMakeTeamLeader}) {
    const {t} = useTranslation();
    return (
        <Card className="mb-3">
            <Card.Header>
                <h4>{t('title.teamDevelopers')}</h4>
            </Card.Header>
            <ListGroup variant="flush">
                {team.developers.map((developer, index) =>
                    <ListGroup.Item key={index}>
                        <div className="d-flex">
                            <div className="flex-shrink-1">
                                <DeleteButton title="tooltip.deleteDeveloperFromTeam"
                                              disabled={team.leaderDeveloperId === developer.id}
                                              onClick={() => onDeveloperRemoved(developer)}/>
                            </div>
                            <div className="flex-grow-1">
                                <span className="ms-1">{developer.fullName}</span>
                            </div>
                            <div className="flex-shrink-1">
                                {team.leaderDeveloperId === developer.id && (
                                    <TooltipIconButton variant="success" title="tooltip.developerIsTeamLeader"
                                                       icon="chess-queen"/>
                                )}

                                {team.leaderDeveloperId !== developer.id && (
                                    <TooltipIconButton title="tooltip.makeTeamLeader" icon="turn-up"
                                                       onClick={() => onMakeTeamLeader(developer)}/>
                                )}
                            </div>
                        </div>
                    </ListGroup.Item>
                )}

                {!team.developers.length && (
                    <ListGroup.Item className="bg-warning">
                        {t('msg.noDeveloperInTeam')}
                    </ListGroup.Item>
                )}
            </ListGroup>
        </Card>
    );
}

const TeamDetailWithData = resolveData(TeamDetail, props => {
    const teamId = props.match.params.teamId;
    return {
        team: teamService.getTeam(teamId).then(team => {
            team.developers = (team.developers || []).sort(developerService.COMPARE_BY_NAME);
            return team;
        })
    };
});

export default secureRoute(remountOnLocationChange(TeamDetailWithData));
