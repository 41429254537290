/**
 * Removes null, undefined or empty string values from a UrlSearchParams instance.
 *
 * @param urlSearchParams {URLSearchParams}
 */
export function clearEmptyUrlSearchParamValues(urlSearchParams) {
    const entries = [...urlSearchParams.entries()];
    for (let [key, value] of entries) {
        if (value === null || value === undefined || value === '') {
            urlSearchParams.delete(key);
        }
    }
}

export function buildQueryString(object = {}) {
    const urlSearchParams = new URLSearchParams(object);
    clearEmptyUrlSearchParamValues(urlSearchParams);
    const stringQueryParams = urlSearchParams.toString();
    return stringQueryParams ? `?${stringQueryParams}` : '';
}
