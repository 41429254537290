import axios from "axios";

const sprintAssessmentService = {};

sprintAssessmentService.COMPARE_BY_SORT_ORDER = (a, b) => {
    return (a.sortOrder || 0) - (b.sortOrder || 0);
};

sprintAssessmentService.getSprintAssessmentSummary = (sprintId) => {
    return axios.get(`/api/sprints/${sprintId}/sprint-assessment`);
};

sprintAssessmentService.getDeveloperSprintAssessmentSummary = (sprintId, developerId) => {
    return axios.get(`/api/sprints/${sprintId}/developers/${developerId}/sprint-assessment`);
};

sprintAssessmentService.getCategories = () => {
    return axios.get(`/api/assessment-categories`);
};

sprintAssessmentService.getCategory = (categoryId) => {
    return axios.get(`/api/assessment-categories/${categoryId}`);
};

sprintAssessmentService.isAssessmentCategoryNameAvailable = (categoryId, name) => {
    const encodedName = encodeURIComponent(name);
    const encodedCategoryId = categoryId || '';
    return axios.get(`/api/assessment-categories/names/available?categoryId=${encodedCategoryId}&name=${encodedName}`);
};

sprintAssessmentService.createCategory = (data) => {
    return axios.post(`/api/assessment-categories`, data);
};

sprintAssessmentService.updateCategory = (categoryId, data) => {
    return axios.put(`/api/assessment-categories/${categoryId}`, data);
};

sprintAssessmentService.moveCategory = (categoryId, direction) => {
    return axios.post(`/api/assessment-categories/${categoryId}/move/${direction}`);
};

sprintAssessmentService.mergeCategories = (data) => {
    return axios.post(`/api/assessment-categories/merge`, data);
};

sprintAssessmentService.sendAssessmentFormToDevelopers = (assessmentForm) => {
    const {templateId, sprintId, developers = []} = assessmentForm;
    const developerIds = developers.map(developer => developer.id);
    const postBody = {developerIds};
    return axios.post(`/api/assessment-forms/${templateId}/sprints/${sprintId}/send`, postBody);
};

sprintAssessmentService.getWaitingAssessmentForms = () => {
    return axios.get(`/api/assessment-forms/waiting`);
};

sprintAssessmentService.getIncompleteAssessmentForms = () => {
    return axios.get(`/api/assessment-forms/incomplete`);
};

sprintAssessmentService.getAssessmentFormBySprintId = (sprintId) => {
    return axios.get(`/api/assessment-forms/${sprintId}`);
};

sprintAssessmentService.submitSprintAssessmentEvaluation = (assessmentFormId, data) => {
    const {categoryVoteGroup = []} = data;
    const votes = categoryVoteGroup.flatMap(categoryVotes => {
        const {categoryId, developerVoteList = []} = categoryVotes;
        return developerVoteList.filter(developerVote => developerVote.voteTypeId).map(developerVote => {
            return {
                voteTypeId: developerVote.voteTypeId,
                developerId: developerVote.developerId,
                categoryId
            };
        });
    });
    return axios.post(`/api/assessment-forms/${assessmentFormId}`, {votes});
};

sprintAssessmentService.closeAssessmentForm = (assessmentFormId) => {
    return axios.put(`/api/assessment-forms/${assessmentFormId}/close`);
};

export default sprintAssessmentService;
