import React from "react";

function TableHolder({children}) {
    return (
        <div className="table-holder">
            {children}
        </div>
    );
}

export default TableHolder;
