import {Card} from "react-bootstrap";
import {useTranslation} from "react-i18next";

function SimpleCard({title = null, children}) {
    const {t} = useTranslation();
    return (
        <Card className="mb-3 rounded-0">
            {title && (<Card.Header>
                <h5>{t(title)}</h5>
            </Card.Header>)}
            <Card.Body>
                {children}
            </Card.Body>
        </Card>
    );
}

export default SimpleCard;
