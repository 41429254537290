import {useTranslation} from "react-i18next";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import React from "react";

let tooltipId = 0;

const TooltipWrapper = ({title, children}) => {
    const {t} = useTranslation();
    const id = ++tooltipId;
    const tooltip = <Tooltip id={`tooltip-${id}`}>{t(title)}</Tooltip>;
    // noinspection RequiredAttributes
    return (
        <OverlayTrigger
            key={id}
            placement="top"
            delay={0}
            overlay={tooltip}>
            {children}
        </OverlayTrigger>
    );
};

export default TooltipWrapper;
