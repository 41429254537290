import {Alert, Button, Card, Table} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import TableHolder from "@components/TableHolder/TableHolder";
import {noop} from "bootstrap/js/src/util";
import {formatNumber} from "@utils/object-utils";

function BonusResult(props) {
    const {t} = useTranslation();
    const {bonusResult: results, onClear = noop} = props;

    return <>
        <Card border={'primary'}>
            <Card.Header className="d-flex">
                <h3 className="flex-fill">{t('title.bonusCalculationParameters')}</h3>
                <Button onClick={() => onClear()}>{t('button.clear')}</Button>
            </Card.Header>
            <Card.Body>
                <dl className="row">
                    <dt className="col-sm-3">{t('title.evaluatedSprints')}</dt>
                    <dd className="col-sm-9">
                        {results.sprints.map((sprint, index) => <>
                            <Link to={`/secure/sprints/${sprint.id}`}>{sprint.name}</Link>
                            {index < results.sprints.length - 1 && <br/>}
                        </>)}
                    </dd>

                    <dt className="col-sm-3">{t('label.totalBonusAmount')}</dt>
                    <dd className="col-sm-9">{formatNumber(results.totalBonusAmount, 1, 2)}</dd>

                    <dt className="col-sm-3">{t('label.storyPointsPercentage')}</dt>
                    <dd className="col-sm-9">{results.storyPointsPercentage} %</dd>

                    <dt className="col-sm-3">{t('label.assessmentPercentage')}</dt>
                    <dd className="col-sm-9">{results.assessmentPercentage} %</dd>
                </dl>
            </Card.Body>
        </Card>

        {results.warnings.map((warning, index) => (
            <Alert key={index} variant={'warning'} className="mt-3">{warning}</Alert>
        ))}

        <Card border={'primary'} className="mt-3">
            <Card.Header>
                <h3>{t('title.developerBonuses')}</h3>
            </Card.Header>
            <Card.Body>
                <TableHolder>
                    <Table bordered striped size="sm">
                        <thead>
                        <tr>
                            <th className="nowrap">{t('label.developer')}</th>
                            <th className="nowrap">{t('label.totalBonusAmount')}</th>
                            <th className="nowrap">{t('label.storyPointTeamPercentage')}</th>
                            <th className="nowrap">{t('label.storyPointBonusAmount')}</th>
                            <th className="nowrap">{t('label.assessmentTeamPercentage')}</th>
                            <th className="nowrap">{t('label.assessmentBonusAmount')}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {results.bonusList.map((bonus, index) => <tr key={index}>
                            <td className="nowrap">{bonus.developer.fullName}</td>
                            <td className="text-end">{formatNumber(bonus.totalBonusAmount, 1, 2)} TL</td>
                            <td className="text-end">{formatNumber(bonus.storyPointTeamPercentage, 1, 1)} %</td>
                            <td className="text-end">{formatNumber(bonus.storyPointBonusAmount, 1, 2)} TL</td>
                            <td className="text-end">{formatNumber(bonus.assessmentTeamPercentage, 1, 1)} %</td>
                            <td className="text-end">{formatNumber(bonus.assessmentBonusAmount, 1, 2)} TL</td>
                        </tr>)}
                        </tbody>
                    </Table>
                </TableHolder>
            </Card.Body>
        </Card>
    </>;
}

export default BonusResult;
