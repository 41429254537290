import React from "react";
import {TooltipIconButton} from "@components/Button";

function DetailButton({url, onClick, size, title, disabled = false, variant = 'primary', icon = 'file-alt'}) {
    return <TooltipIconButton url={url}
                              onClick={onClick}
                              size={size}
                              variant={variant}
                              icon={icon}
                              disabled={disabled}
                              title={title}/>;
}

export default DetailButton;
