import React from "react";
import {connect} from "react-redux";
import {AdminUser} from "@models/AdminUser.model";

// Hides a wrapped component if logged-in user does not have a specified privilege.
export default function withPrivilege(WrappedComponent, privileges) {
    class PrivilegeChecker extends React.Component {
        render() {
            const {isVisible, ...otherProps} = this.props;
            if (!isVisible) {
                return null;
            }
            return <WrappedComponent {...otherProps} />
        }
    }

    function mapStateToProps(state) {
        const {user} = state.currentUser;
        const isVisible = user ? new AdminUser(user).hasAnyPrivilege(privileges) : false;
        return {isVisible};
    }

    return connect(mapStateToProps)(PrivilegeChecker);
}
