import {Alert} from "react-bootstrap";
import {useTranslation} from "react-i18next";

const errorDetailMap = {
    'error.passwordAtLeastThreeRules': [
        'msg.pwdRuleContainDigit',
        'msg.pwdRuleUppercase',
        'msg.pwdRuleLowercase',
        'msg.pwdRuleNonAlpha'
    ]
};

function ErrorDetails({errorCode = ''}) {
    const {t} = useTranslation();
    const {[errorCode]: detailList = []} = errorDetailMap;
    if (!detailList.length) {
        return null;
    }
    return (
        <ul className="mb-0">
            {detailList.map((msg, index) => (
                <li key={index}>{t(msg)}</li>
            ))}
        </ul>
    );
}

function ErrorListItem({code, args}) {
    const {t} = useTranslation();
    return <li>
        {t(code, args)}
        <ErrorDetails errorCode={code}/>
    </li>;
}

function FieldErrors({fieldErrors = []}) {
    if (!fieldErrors.length) {
        return null;
    }
    return fieldErrors.map((fieldError, index) => (
        <ErrorListItem key={index} code={fieldError.code} args={fieldError.arguments}/>
    ));
}

function GlobalErrors({globalErrors = [], globalErrorArguments = {}}) {
    if (!globalErrors.length) {
        return null;
    }

    const getArgs = (code) => globalErrorArguments[code] || {};

    return globalErrors.map((globalError, index) => (
        <ErrorListItem key={index} code={globalError} args={getArgs(globalError)}/>
    ));
}

function BackendErrors(props) {
    const {error} = props;

    if (!error) {
        return null;
    }

    const {fieldErrors = [], globalErrors = [], globalErrorArguments = {}} = error;
    const showGlobalErrorCode = !fieldErrors.length && !globalErrors.length && error.code;

    return (
        <Alert variant="danger">
            <ul className="mb-0">
                {showGlobalErrorCode && (
                    <ErrorListItem code={error.code} args={error.arguments}/>
                )}

                <GlobalErrors globalErrors={globalErrors} globalErrorArguments={globalErrorArguments}/>
                <FieldErrors fieldErrors={fieldErrors}/>
            </ul>
        </Alert>
    );
}

export default BackendErrors;
