import React from "react";
import {TooltipIconButton} from "@components/Button";

function EditButton({url, onClick, size, title, variant = 'primary', icon = 'pencil-alt'}) {
    return <TooltipIconButton url={url}
                              onClick={onClick}
                              size={size}
                              variant={variant}
                              icon={icon}
                              title={title}/>;
}

export default EditButton;
