import {useTranslation} from "react-i18next";
import secureRoute from "@components/HOC/secureRoute";
import resolveData from "@components/HOC/resolveData";
import Breadcrumb from "@components/Breadcrumb/Breadcrumb";
import SimpleCard from "@components/SimpleCard/SimpleCard";
import TableHolder from "@components/TableHolder/TableHolder";
import {Button, Table} from "react-bootstrap";
import {EditButton, TooltipIconButton} from "@components/Button";
import sprintAssessmentService from "@services/sprint-assessment.service";
import React, {useState} from "react";
import ButtonBar from "@components/ButtonBar/ButtonBar";
import {Link} from "react-router-dom";

function AssessmentCategoryList(props) {
    const {t} = useTranslation();
    const {categories: initialCategories = [], history} = props;
    const [categories, setCategories] = useState(initialCategories);

    const handleEdit = (category) => {
        history.push(`/secure/assessment-categories/${category.id}/edit`);
    };

    const handleMove = (category, direction) => {
        sprintAssessmentService.moveCategory(category.id, direction)
            .then(() => sprintAssessmentService.getCategories())
            .then(newCategories => setCategories(newCategories));
    };

    return (
        <>
            <Breadcrumb title="title.assessmentCategories"/>

            <SimpleCard>
                <TableHolder>
                    <Table striped bordered>
                        <thead>
                        <tr>
                            <th className="table-actions"/>
                            <th className="table-actions">{t('label.categoryId')}</th>
                            <th>{t('label.categoryName')}</th>
                            <th>{t('label.categorySortOrder')}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {categories.map((category, index) => (
                            <tr key={index}>
                                <td className="table-actions">
                                    <EditButton title="tooltip.editAssessmentCategory"
                                                onClick={() => handleEdit(category)}/>
                                    &nbsp;
                                    <TooltipIconButton title="tooltip.moveUpAssessmentCategory"
                                                       icon="chevron-up"
                                                       onClick={() => handleMove(category, 'up')}/>
                                    &nbsp;
                                    <TooltipIconButton title="tooltip.moveDownAssessmentCategory"
                                                       icon="chevron-down"
                                                       onClick={() => handleMove(category, 'down')}/>
                                </td>
                                <td className="table-actions">{category.id}</td>
                                <td>{category.name}</td>
                                <td>{category.sortOrder}</td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                </TableHolder>

                <ButtonBar>
                    <Button variant="primary" as={Link} to="/secure/assessment-categories/merge">
                        {t('button.mergeCategories')}
                    </Button>
                </ButtonBar>
            </SimpleCard>
        </>
    );
}

export default secureRoute(resolveData(AssessmentCategoryList, () => {
    return {
        categories: sprintAssessmentService.getCategories()
    };
}));
