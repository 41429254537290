import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import translationLoader from "./i18n";
import {store} from '@store'
import {Provider} from 'react-redux'
import '@utils/fontawesome';
import ToastScene from "@components/ToastScene/ToastScene";
import setupInterceptors from "@utils/axios-interceptors";

setupInterceptors();

function startApp() {
    ReactDOM.render(
        <React.StrictMode>
            <Provider store={store}>
                <App/>
                <ToastScene/>
            </Provider>
        </React.StrictMode>,
        document.getElementById('root')
    );
}

translationLoader
    .then(() => startApp());

