import React from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import Login from "@views/Login/Login";
import NotFound from "@views/Common/NotFound/NotFound";
import CredentialsExpired from "@views/CredentialsExpired/CredentialsExpired";
import ForgotPassword from "@views/ForgotPassword/ForgotPassword";
import ResetPassword from "@views/ForgotPassword/ResetPassword";
import SecureParent from "@views/SecureParent/SecureParent";

class RouterSwitch extends React.Component {
    render() {
        return (
            <Switch>
                <Redirect exact={true} from="/" to="/secure/home"/>
                <Route path="/secure" component={SecureParent}/>
                <Route exact path="/login" component={Login}/>
                <Route exact path="/forgot-password" component={ForgotPassword}/>
                <Route exact path="/reset-password" component={ResetPassword}/>
                <Route exact path="/credentials-expired" component={CredentialsExpired}/>
                <Route path='*' exact component={NotFound}/>
            </Switch>
        );
    }
}

export default RouterSwitch;
