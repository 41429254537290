import {Col, Row} from "react-bootstrap";
import {formatDate} from "@utils/date-time.utils";
import SimpleCard from "@components/SimpleCard/SimpleCard";
import React from "react";
import {useTranslation} from "react-i18next";

function SprintSummaryView({sprint}) {
    const {t} = useTranslation();

    return (
        <SimpleCard title="title.sprintOverview">
            <Row>
                <Col md={6}>
                    <Row as="dl" className="mb-0">
                        <Col sm={5} as="dt">{t('label.sprintName')}</Col>
                        <Col sm={7} as="dd">{sprint.name}</Col>
                        <Col sm={5} as="dt">{t('label.startDate')}</Col>
                        <Col sm={7} as="dd">{formatDate(sprint.startDate)}</Col>
                    </Row>
                </Col>
                <Col md={6}>
                    <Row as="dl" className="mb-0">
                        <Col sm={5} as="dt">{t('label.sprintStatus')}</Col>
                        <Col sm={7}
                             as="dd">{sprint.archived ? t('label.archived') : t('label.ongoing')}</Col>
                        <Col sm={5} as="dt">{t('label.endDate')}</Col>
                        <Col sm={7} as="dd">{formatDate(sprint.endDate)}</Col>
                    </Row>
                </Col>
            </Row>
        </SimpleCard>
    );
}

export default SprintSummaryView;
