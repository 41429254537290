import React from 'react';
import {CircularProgress, Typography} from '@mui/material';
import MUIDataTable from "mui-datatables";
import {useTranslation} from "react-i18next";
import {formatDateTimeWithSeconds} from "../../../../utils/date-time.utils";

//TODO TURGAY : convert jsx to function and cont. improvement next task.
//don't remove comment out codes, they should be developed
function DeveloperQuarterTargets(props) {
    const {t} = useTranslation();
    let state = {
        page: 0,
        rowsPerPage: 20,
        sortOrder: {},
        data: props.developerTargets ? props.developerTargets : [['Loading Data...']],
        count: props.developerTargets ? props.developerTargets.length : 0,
        isLoading: false
    };

    const getSrcData = () => {
        return props.developerTargets;
    };

    const sort = (page, sortOrder) => {
        state.isLoading = true;
        xhrRequest('', page, sortOrder).then(res => {
            state.data = res.data;
            state.page = res.page;
            state.sortOrder = state.sortOrder;
            state.isLoading = false;
            state.count = res.total;
        });
    };

    // mock async function
    const xhrRequest = (url, page, sortOrder = {}) => {
        return new Promise((resolve, reject) => {
            // mock page data
            let fullData = getSrcData();
            const total = fullData.length; // mock record count from server - normally this would be a number attached to the return data

            let sortField = sortOrder.name;
            let sortDir = sortOrder.direction;

            if (sortField) {
                fullData = fullData.sort((a, b) => {
                    if (a[sortField] < b[sortField]) {
                        return 1 * (sortDir === 'asc' ? -1 : 1);
                    } else if (a[sortField] > b[sortField]) {
                        return -1 * (sortDir === 'asc' ? -1 : 1);
                    } else {
                        return 0;
                    }
                });
            }

            const srcData = fullData.slice(page * state.rowsPerPage, (page + 1) * state.rowsPerPage);
            let data = srcData;

            setTimeout(() => {
                resolve({
                    data,
                    total,
                    page,
                });
            }, 500);
        });
    };

    const changePage = (page, sortOrder) => {
        state.isLoading = true;
        xhrRequest(`/?page=${page}`, page, sortOrder).then(res => {
            state.isLoading = false;
            state.page = res.page;
            state.sortOrder = state.sortOrder;
            state.data = res.data;
            state.count = res.total;
        });
    }

    const options = {
        filter: true,
        filterType: 'dropdown',
        responsive: 'vertical',
        serverSide: false,
        count: state.count,
        rowsPerPage: state.rowsPerPage,
        rowsPerPageOptions: [20, 50, 100],
        sortOrder: state.sortOrder,
        onTableChange: (action, tableState) => {
            console.log(action, tableState);

            // a developer could react to change on an action basis or
            // examine the state as a whole and do whatever they want
            switch (action) {
                case 'changePage':
                    changePage(tableState.page, tableState.sortOrder);
                    break;
                case 'sort':
                    sort(tableState.page, tableState.sortOrder);
                    break;
                default:
                    console.log('action not handled.');
            }
        },
    };

    const columns = [
        {
            id: 'id',
            name: 'id',
            numeric: false,
            disablePadding: true,
            label: t('label.id'),
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    // Here you can render a more complex display.
                    // You're given access to tableMeta, which has
                    // the rowData (as well as the original object data).
                    // See the console for a detailed look at this object.
                    //call custom function like data formatting
                    //console.log('customBodyRender');
                    //console.dir(tableMeta);
                    return value;
                },
            },
        },
        {
            id: 'developer',
            name: 'developer',
            numeric: true,
            disablePadding: false,
            label: t('label.developer'),
        },
        {
            id: 'quarter',
            name: 'quarter',
            numeric: true,
            disablePadding: false,
            label: t('label.quarter'),
        },
        {
            id: 'target',
            name: 'target',
            numeric: true,
            disablePadding: false,
            label: t('label.targetStoryPoints')
        },
        {
            id: 'createDate',
            name: 'createDate',
            numeric: true,
            disablePadding: false,
            label: t('label.createDate'),
            options: {
                customBodyRender: (value) => {
                    return formatDateTimeWithSeconds(value);
                },
            }
        },
        {
            id: 'creator',
            name: 'creator',
            numeric: true,
            disablePadding: false,
            label: t('label.creator')
        },
        {
            id: 'updateDate',
            name: 'updateDate',
            numeric: true,
            disablePadding: false,
            label: t('label.updateDate'),
            options: {
                customBodyRender: (value) => {
                    return formatDateTimeWithSeconds(value);
                },
            }
        },
        {
            id: 'updater',
            name: 'updater',
            numeric: true,
            disablePadding: false,
            label: t('label.updater')
        },
    ];

    return (
        <div>
            <MUIDataTable
                titless={
                    <Typography variant="h6">
                        {state.isLoading &&
                        <CircularProgress size={24} style={{marginLeft: 15, position: 'relative', top: 4}}/>}
                    </Typography>
                }
                data={state.data}
                columns={columns}
                options={options}
            />
        </div>
    );
}

export default DeveloperQuarterTargets;
