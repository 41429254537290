import {Col, Row, Spinner} from "react-bootstrap";

function Loading(){
    return (
        <Row>
            <Col className="mt-3 mb-3 align-content-center text-center">
                <Spinner animation="border" variant="primary" />
            </Col>
        </Row>
    );
}

export default Loading;
