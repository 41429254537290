import resolveData from "@components/HOC/resolveData";
import developerService from "@services/developer.service";
import {useTranslation} from "react-i18next";
import {Button, Form} from "react-bootstrap";
import {FormInvalidFeedback} from "@components/Form";
import React from "react";
import SimpleCard from "@components/SimpleCard/SimpleCard";
import {withFormik} from "formik";
import * as Yup from "yup";
import {required} from "@utils/validations";
import toastService from "@services/toast.service";
import {noop} from "bootstrap/js/src/util";

function AddDeveloperAliasForm(props) {
    const {t} = useTranslation();
    const {allDevelopers: developers} = props;

    const {
        values,
        touched,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
    } = props;

    return (
        <SimpleCard title="title.addDeveloperAlias">
            <form onSubmit={handleSubmit}>
                <Form.Group className="mb-3" controlId="developerId">
                    <Form.Label>{t('label.developer')}</Form.Label>
                    <Form.Select name="developerId" value={values.developerId}
                                 onChange={handleChange} onBlur={handleBlur}
                                 isInvalid={errors.developerId && touched.developerId}>
                        <option value="">{t('msg.pleaseSelect')}</option>
                        {developers.map((developer, index) => (
                            <option key={index} value={developer.id}>{developer.fullName}</option>
                        ))}
                    </Form.Select>
                    <FormInvalidFeedback errors={errors.developerId}/>
                    <Form.Text>{t('msg.selectDeveloperToAdd')}</Form.Text>
                </Form.Group>

                <Form.Group className="mb-3" controlId="alias">
                    <Form.Label>{t('label.alias')}</Form.Label>
                    <Form.Control name="alias" value={values.alias} maxLength={255}
                                  onChange={handleChange} onBlur={handleBlur}
                                  isInvalid={errors.alias && touched.alias}/>
                    <FormInvalidFeedback errors={errors.alias}/>
                    <Form.Text>{t('msg.enterAlias')}</Form.Text>
                </Form.Group>

                <Button variant="primary" type="submit" disabled={isSubmitting}>{t('button.add')}</Button>
            </form>
        </SimpleCard>
    );
}

const AddDeveloperAliasFormWithFormik = withFormik({
    mapPropsToValues: () => ({
        developerId: '',
        alias: ''
    }),

    validationSchema: Yup.object().shape({
        developerId: Yup.number().required(required),
        alias: Yup.string().required(required),
    }),

    handleSubmit: (values, {props}) => {
        const callback = props.onDeveloperAliasAdded || noop;
        return developerService.addDeveloperAlias(values.developerId, values.alias)
            .then(result => {
                toastService.success('msg.developerAliasAdded');
                values.developerId = '';
                values.alias = '';
                callback();
                return result;
            });
    },

    displayName: 'AddDeveloperAliasForm',
})(AddDeveloperAliasForm);

export default resolveData(AddDeveloperAliasFormWithFormik, () => {
    return {
        allDevelopers: developerService.getAllDevelopers()
    };
});
